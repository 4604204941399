import { api, errorHandler, queryString } from "./index";
import { push } from "react-router-redux";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import {
  API_ERROR,
  FETCH_ALL_SESSIONS,
  FETCH_ALL_SESSIONS_START,
} from "../constants/ActionTypes";

export function fetchAllSessions() {
  return function (dispatch) {
    dispatch(({
      type: FETCH_ALL_SESSIONS_START,
    }))
    api.post(`/fetch-all-sessions.php`)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_SESSIONS,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function fetchOneSession(session_id) {
  return function (dispatch) {
    api.post(`/fetch-cms-session.php`,
      queryString.stringify({
        session_id: session_id,
      })
    )
      .then((response) => {
        dispatch({
          type: FETCH_ALL_SESSIONS,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditSession(options) {
  return function (dispatch) {
    api.post(`/add-n-edit-session.php`,
      queryString.stringify({
        timestamps_json: options.timestamps_json,
        subcategory_id: options.subcategory_id,
        category_id: options.category_id,
        series_id: options.series_id,
        session_json: options.session_json,
        challenge_id: options.challenge_id,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Saved!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/sessions"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteSession(session_id) {
  return function (dispatch) {
    api.post(`/delete-session.php`,
      queryString.stringify({
        session_id: session_id,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Session Deleted!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/sessions"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function clearSessions() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_SESSIONS,
      payload: null,
    });
  };
}
