import { api, errorHandler, queryString } from "./index";
import { push } from "react-router-redux";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { API_ERROR, FETCH_ALL_CATEGORIES, CLEAR_HIGHLIGHT } from "../constants/ActionTypes";

//= ===============================
// Category actions
//= ===============================

export function fetchAllCategories(isQuickTap = null) {
  return function (dispatch) {
    api.post(`/fetch-all-categories.php`,
      queryString.stringify({
        is_quick_tap: isQuickTap
      })
    )
      .then((response) => {
        dispatch({
          type: FETCH_ALL_CATEGORIES,
          payload: response.data.objects || [],
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function clearCategories() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_CATEGORIES,
      payload: null,
    });
  };
}

export function addEditCategory(options) {
  return function (dispatch) {
    api.post(`/add-n-edit-category.php`,
      queryString.stringify({
        category_json: options.category_json,
        child_objects_json: options.child_objects_json,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Saved!",
          timer: 1000,
        }).then(() => {
          const parsed_category = JSON.parse(options.category_json);
          if (parsed_category.category_id === 32) {
            dispatch(push("/audiobooks"));
          } else {
            dispatch(push("/category"));
          }
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteCategory(category_id) {
  return function (dispatch) {
    api.post(`/delete-category.php`,
      queryString.stringify({
        category_id: category_id,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Category Deleted!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/category"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function sortCategories(category_ids) {
  return function (dispatch) {
    api.post(`/update-category-order.php`,
      queryString.stringify({
        category_ids: category_ids,
      })
    )
      .then(() => {
        dispatch({
          type: CLEAR_HIGHLIGHT,
          payload: null
        });
      })
      .catch((error) => {
        dispatch({
          type: CLEAR_HIGHLIGHT,
          payload: null
        });
        fetchAllCategories()(dispatch);
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function nonExistentCategory() {
  return function (dispatch) {
    Swal.fire({
      icon: "error",
      text: "Category does not exist!",
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: "Return"
    }).then(() => {
      dispatch(push("/category"));
    });
  };
}
