import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderBar from "../HeaderBar/HeaderBar";
import MainContentContainer from "../MainContentContainer/MainContentContainer";
import {
  uploadImage,
  clearMedia,
  uploadAudio,
} from "../../actions/mediaActions";
import SortableSessions from "./SortableSessions/SortableSessions";
import {
  fetchAllChallenges,
  deleteChallenges,
  addEditChallenges,
  clearAllChallenges,
  fetchOneChallenge,
} from "../../actions/challengeActions";
import { arrayMoveImmutable } from "array-move";
import { fetchAllSessions } from "../../actions/sessionActions";

import Select from "react-select";
import "react-select/dist/react-select.css";
import { fetchAuthors } from "./../../actions/authorsActions";

import Swal from "sweetalert2";

class AddEditChallenges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      challenge_title: "",
      challenge_description: "",
      challenge_goal: "",
      challenge_is_active: 1,
      challenge_image: "",
      challenge_image_preview: "",
      challenge_image_uploading: false,
      challenge_description: "",
      challenge_day_type: "",
      total_days: 0,
      challenge_certificate_image: null,
      challenge_certificate_image_preview: "",
      challenge_certificate_image_uploading: false,
      challenge_invite_image: null,
      challenge_invite_image_preview: "",
      challenge_invite_image_uploading: false,
      challenge_reminder_image: null,
      challenge_reminder_image_preview: "",
      challenge_is_dark_mode: 0,
      challenge_author_id: 0,
      challenge_is_free: 0,
      original_challenge_is_free: 0,
      challenge_reminder_image_uploading: false,
      intro_session: {
        session_name: "",
        session_main_url: "",
        session_main_url_preview: "",
        session_main_url_uploading: false,
        session_image: "",
        session_image_preview: "",
        session_image_uploading: false,
        session_length: "",
        session_length_in_sec: "",
        challenge_day: "",
      },
      category: 0,
      sessions: [],
      session_data: [],
      isSetToEdit: false,
      is_new: 0,
    };
  }

  componentDidMount() {
    this.props.fetchAuthors();
    this.props.clearMedia();
    this.props.clearAllChallenges();

    if (this.props.isEdit) {
      this.props.fetchOneChallenge(this.props.selectedId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isEdit && nextProps.challenges && !this.state.isSetToEdit) {
      this.setChallengesForEditing(nextProps);
    }

    if (nextProps.audio) {
      if (this.state.intro_session.session_main_url_uploading) {
        const audio = nextProps.audio;
        this.setState({
          intro_session: {
            ...this.state.intro_session,
            session_main_url_uploading: false,
            session_main_url: audio,
          },
        });
        this.props.clearMedia();
      }
    }

    if (nextProps.image) {
      if (this.state.intro_session.session_image_uploading) {
        this.setState({
          intro_session: {
            ...this.state.intro_session,
            session_image_uploading: false,
            session_image: nextProps.image,
          },
        });
        this.props.clearMedia();
      }

      if (this.state.challenge_image_uploading) {
        this.setState({
          challenge_image: nextProps.image,
          challenge_image_uploading: false,
        });
        this.props.clearMedia();
      }

      if (this.state.challenge_grayscale_image_uploading) {
        this.setState({
          challenge_grayscale_image: nextProps.image,
          challenge_grayscale_image_uploading: false,
        });
        this.props.clearMedia();
      }

      if (this.state.challenge_certificate_image_uploading) {
        this.setState({
          challenge_certificate_image: nextProps.image,
          challenge_certificate_image_uploading: false,
        });
        this.props.clearMedia();
      }

      if (this.state.challenge_reminder_image_uploading) {
        this.setState({
          challenge_reminder_image: nextProps.image,
          challenge_reminder_image_uploading: false,
        });
        this.props.clearMedia();
      }

      if (this.state.challenge_invite_image_uploading) {
        this.setState({
          challenge_invite_image: nextProps.image,
          challenge_invite_image_uploading: false,
        });
        this.props.clearMedia();
      }
    }
  }

  handleBGMediaUpload = (e) => {
    if (!e) {
      return false;
    }
    const file = e.target.files[0];
    if (!file) {
      return false;
    }
    if (this.state.intro_session.session_main_url_preview) {
      window.URL.revokeObjectURL(
        this.state.intro_session.session_main_url_preview
      );
    }
    const objectURL = window.URL.createObjectURL(file);
    const mySound = new Audio([objectURL]);
    mySound.addEventListener(
      "canplaythrough",
      () => {
        URL.revokeObjectURL(objectURL);
        const minutes = Math.floor(mySound.duration / 60);
        const seconds = Math.round(mySound.duration - minutes * 60);
        this.setState({
          intro_session: {
            ...this.state.intro_session,
            session_length: `${minutes}:${seconds < 10 ? "0" + seconds : seconds
              }`,
            session_length_in_sec: Math.round(mySound.duration),
          },
        });
      },
      false
    );
    this.setState({
      intro_session: {
        ...this.state.intro_session,
        session_main_url_preview: window.URL.createObjectURL(file),
        session_main_url_uploading: true,
      },
    });
    this.props.uploadAudio(file);
  };

  setChallengesForEditing = ({ challenges }) => {
    let currentChallenges = challenges[this.props.selectedId];
    const session_data = currentChallenges.sessions;
    console.log("session_data", session_data);
    const sessions = session_data.map((obj, i) => {
      let sessionsObj = {};
      console.log("challenge sessions", obj);
      sessionsObj[obj.session_id] = [obj.challenge_day, obj.session_repetition];
      return sessionsObj;
    });

    console.log(currentChallenges.challenge_is_free);
    this.setState({
      ...currentChallenges,
      original_challenge_is_free: currentChallenges.challenge_is_free,
      session_data: session_data,
      sessions: sessions,
      isSetToEdit: true,
    });
  };

  handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  handleSelectChange = (e) => {
    if (!e) {
      return false;
    }

    const { value, prop } = e;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[prop] = value;
      return state;
    });
  };

  handleIntroFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => {
      const state = { ...prevState };
      state["intro_session"][name] = value;
      return state;
    });
  };
  handleCheckBox = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    console.log("name", name);
    this.setState({
      [name]: value === true ? 1 : 0,
    });
  };

  handleChallengesDay = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const id = name.replace(/\D/g, "");

    const newSessions = this.state.sessions.map((obj) => {
      if (Object.keys(obj)[0] === id) {
        let values = Object.values(obj)[0];
        let newObj = {};
        if (name.includes("day")) {
          values[0] = value;
        } else {
          values[1] = value;
        }
        newObj[id] = values;
        return newObj;
      }
      return obj;
    });

    this.setState({
      sessions: newSessions,
    });
  };

  handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.challenge_image_preview) {
      window.URL.revokeObjectURL(this.state.challenge_image_preview);
    }
    this.setState({
      challenge_image_preview: window.URL.createObjectURL(file),
      challenge_image_uploading: true,
    });
    this.props.uploadImage(file);
  };

  clickParent = (e) => {
    e.target.parentNode.click();
  };

  handleOtherImage = (e) => {
    const name = e.target.name;

    if (name === "challenge_certificate_image") {
      const file = e.target.files[0];
      if (this.state.challenge_certificate_image_preview) {
        window.URL.revokeObjectURL(
          this.state.challenge_certificate_image_preview
        );
      }
      this.setState({
        challenge_certificate_image_preview: window.URL.createObjectURL(file),
        challenge_certificate_image_uploading: true,
      });
      this.props.uploadImage(file);
    }
    if (name === "challenge_invite_image") {
      const file = e.target.files[0];
      if (this.state.challenge_invite_image_preview) {
        window.URL.revokeObjectURL(this.state.challenge_invite_image_preview);
      }
      this.setState({
        challenge_invite_image_preview: window.URL.createObjectURL(file),
        challenge_invite_image_uploading: true,
      });
      this.props.uploadImage(file);
    }
    if (name === "challenge_reminder_image") {
      const file = e.target.files[0];
      if (this.state.challenge_reminder_image_preview) {
        window.URL.revokeObjectURL(this.state.challenge_reminder_image_preview);
      }
      this.setState({
        challenge_reminder_image_preview: window.URL.createObjectURL(file),
        challenge_reminder_image_uploading: true,
      });
      this.props.uploadImage(file);
    }
    if (name === "session_image") {
      const file = e.target.files[0];
      if (this.state.intro_session.session_image_preview) {
        window.URL.revokeObjectURL(
          this.state.intro_session.session_image_preview
        );
      }
      this.setState({
        intro_session: {
          ...this.state.intro_session,
          session_image_preview: window.URL.createObjectURL(file),
          session_image_uploading: true,
        },
      });
      this.props.uploadImage(file);
    }
  };

  renderAuthors = () => {
    if (this.props.authors) {
      return this.props.authors.map((obj) => {
        return {
          label: obj.author_name,
          value: obj.author_id,
          prop: "challenge_author_id",
        };
      });
    }
    return [];
  };

  renderCategoryOptions = () => {
    const { all_categories } = this.props;
    if (!all_categories) return [];

    const options = all_categories.map((obj) => ({
      value: obj.category_id,
      label: obj.category_title,
    }));

    return [{ label: "None", value: 0 }, ...options];
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      sessions: arrayMoveImmutable(this.state.sessions, oldIndex, newIndex),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Saving...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });
    let { category, sessions, ...challenge_json } = this.state;

    console.log("challenge_json", challenge_json);
    const options = {
      challenge_json: JSON.stringify(challenge_json),
      session_ids_json: JSON.stringify(sessions),
      intro_session_json: JSON.stringify(this.state.intro_session),
    };
    this.props.addEditChallenges(options);
  };

  handleDelete = (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "warning",
      text: "Are you sure you want to delete this challenges?",
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Delete Challenges",
    }).then((result) => {
      if (result.value) {
        this.props.deleteChallenges(this.state.challenge_id);
      }
    });
  };

  render() {
    console.log("current state", this.state);
    const { isEdit, all_sessions, challenges } = this.props;
    // console.log("loading", challenges, all_sessions);
    // if (!all_sessions) {
    //   return <h3>Loading...</h3>;
    // }

    return (
      <form
        className="AddEditChallenges"
        onSubmit={this.handleSubmit}
        autoComplete="off"
      >
        <HeaderBar
          header={isEdit ? "Edit Challenges" : "Create New Challenges"}
        >
          <Link
            to="/challenges"
            className="utility-btn-transparent utility-lr-margin-small"
          >
            Cancel
          </Link>
          <button className="utility-btn-primary" type="submit">
            {isEdit ? "Update" : "Create"}
          </button>
        </HeaderBar>
        <MainContentContainer subContent={true}>
          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Challenges Name</p>
              <input
                type="text"
                className="utility-text-input"
                name="challenge_title"
                value={this.state.challenge_title}
                onChange={this.handleFieldChange}
                style={{ width: "500px" }}
                required
              />
            </label>
            <label className="utility-inline-label">
              <p>Status</p>
              <Select
                value={this.state.challenge_is_active}
                onChange={(e) =>
                  this.setState({ challenge_is_active: e.value })
                }
                style={{ width: "150px" }}
                clearable={false}
                searchable={false}
                options={[
                  { value: 1, label: "Active" },
                  { value: 0, label: "Not Active" },
                ]}
              />
            </label>
            <label className="utility-inline-label">
              <p>Duration</p>
              <input
                type="number"
                min={0}
                className="utility-text-input"
                name="total_days"
                value={this.state.total_days}
                onChange={this.handleFieldChange}
                style={{ width: "100px" }}
                required={false}
              />
            </label>
            <label className="utility-inline-label">
              <p>Day Type</p>
              <Select
                value={this.state.challenge_day_type}
                onChange={(e) => this.setState({ challenge_day_type: e.value })}
                style={{ width: "150px" }}
                clearable={false}
                searchable={false}
                options={[
                  { value: "DAY", label: "DAY" },
                  { value: "WEEK", label: "WEEK" },
                ]}
              />
            </label>

            <label className="utility-inline-label">
              <p>Is New</p>
              <input
                name="is_new"
                type="checkbox"
                value={this.state.is_new === 0 ? false : true}
                onChange={this.handleCheckBox}
                checked={this.state.is_new === 0 ? false : true}
                style={{ width: "80px" }}
                clearable={undefined}
                searchable={undefined}
              />
            </label>
            <label className="utility-inline-label">
              <p>Is Free</p>
              <input
                name="challenge_is_free"
                type="checkbox"
                value={this.state.challenge_is_free === 0 ? false : true}
                onChange={this.handleCheckBox}
                checked={this.state.challenge_is_free === 0 ? false : true}
                style={{ width: "80px" }}
                clearable={undefined}
                searchable={undefined}
              />
            </label>
          </div>
          <div>
            <div className="utility-flex-row">
              <div className="utility-inline-label">
                <label className="utility-inline-label">
                  <p>Challenges Goal</p>
                  <input
                    type="text"
                    className="utility-text-input"
                    name="challenge_goal"
                    value={this.state.challenge_goal}
                    onChange={this.handleFieldChange}
                    style={{ width: "500px" }}
                    required
                  />
                </label>
              </div>
              <div className="utility-inline-label">
                <label className="utility-inline-label">
                  <p>Dark Mode</p>
                  <input
                    name="challenge_is_dark_mode"
                    type="checkbox"
                    value={
                      this.state.challenge_is_dark_mode === 0 ? false : true
                    }
                    onChange={this.handleCheckBox}
                    checked={
                      this.state.challenge_is_dark_mode === 0 ? false : true
                    }
                    style={{ width: "80px" }}
                    clearable={undefined}
                    searchable={undefined}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="utility-flex-row">
            <div className="utility-inline-label">
              <p>Image</p>
              <div className="utility-flex-row">
                {(this.state.challenge_image_preview ||
                  this.state.challenge_image) && (
                    <img
                      src={
                        this.state.challenge_image_preview ||
                        this.state.challenge_image
                      }
                      alt="Challenges Main"
                      style={{
                        maxHeight: "124px",
                        maxWidth: "150px",
                        marginRight: "20px",
                      }}
                    />
                  )}
                <label>
                  <input
                    type="file"
                    onChange={this.handleImageUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Image
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div className="utility-flex-row">
            <div className="utility-inline-label">
              <p>Certificate Image</p>
              <div className="utility-flex-row">
                {(this.state.challenge_certificate_image_preview ||
                  this.state.challenge_certificate_image) && (
                    <img
                      src={
                        this.state.challenge_certificate_image_preview ||
                        this.state.challenge_certificate_image
                      }
                      alt="Profile"
                      style={{
                        maxHeight: "124px",
                        maxWidth: "150px",
                        marginRight: "20px",
                      }}
                    />
                  )}
                <label>
                  <input
                    type="file"
                    onChange={this.handleOtherImage}
                    accept="image/*"
                    name="challenge_certificate_image"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Certificate
                  </p>
                </label>
              </div>
            </div>
            <div className="utility-inline-label">
              <p>Reminder Image</p>
              <div className="utility-flex-row">
                {(this.state.challenge_reminder_image_preview ||
                  this.state.challenge_reminder_image) && (
                    <img
                      src={
                        this.state.challenge_reminder_image_preview ||
                        this.state.challenge_reminder_image
                      }
                      alt="Profile"
                      style={{
                        maxHeight: "124px",
                        maxWidth: "150px",
                        marginRight: "20px",
                      }}
                    />
                  )}
                <label>
                  <input
                    type="file"
                    onChange={this.handleOtherImage}
                    accept="image/*"
                    name="challenge_reminder_image"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Reminder
                  </p>
                </label>
              </div>
            </div>
            <div className="utility-inline-label">
              <p>Invitation Image</p>
              <div className="utility-flex-row">
                {(this.state.challenge_invite_image_preview ||
                  this.state.challenge_invite_image) && (
                    <img
                      src={
                        this.state.challenge_invite_image_preview ||
                        this.state.challenge_invite_image
                      }
                      alt="Profile"
                      style={{
                        maxHeight: "124px",
                        maxWidth: "150px",
                        marginRight: "20px",
                      }}
                    />
                  )}
                <label>
                  <input
                    type="file"
                    onChange={this.handleOtherImage}
                    accept="image/*"
                    name="challenge_invite_image"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Invitation
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div className="utility-flex-row">
            <div className="utility-inline-label">
              <label className="utility-inline-label">
                <p>Deep Link URL</p>
                <input
                  type="text"
                  className="utility-text-input"
                  name="challenge_text_url"
                  value={this.state.challenge_text_url || ""}
                  onChange={this.handleFieldChange}
                />
              </label>
            </div>
          </div>
          <div></div>
          <div>
            <div className="utility-flex-row">
              <div className="utility-inline-label">
                <p>Challenges Description</p>
                <textarea
                  className="utility-textarea"
                  name="challenge_description"
                  value={this.state.challenge_description}
                  onChange={this.handleFieldChange}
                />
              </div>
            </div>
          </div>

          <div style={{ marginBottom: "40px" }}>
            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <p>Author</p>
                <Select
                  value={this.state.challenge_author_id}
                  onChange={this.handleSelectChange}
                  style={{ width: "259px" }}
                  clearable={false}
                  searchable={true}
                  options={this.renderAuthors()}
                />
              </label>

              <label className="utility-inline-label">
                <p>Intro Title</p>
                <input
                  type="text"
                  className="utility-text-input"
                  name="session_name"
                  value={this.state.intro_session.session_name}
                  onChange={this.handleIntroFieldChange}
                  style={{ width: "500px" }}
                  required
                />
              </label>
            </div>

            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <p>Intro Session Label</p>
                <input
                  type="text"
                  className="utility-text-input"
                  name="challenge_day"
                  value={this.state.intro_session.challenge_day}
                  onChange={this.handleIntroFieldChange}
                  style={{ width: "300px" }}
                  required
                />
              </label>
            </div>

            <div className="utility-flex-row">
              {(this.state.intro_session.session_image_preview ||
                this.state.intro_session.session_image) && (
                  <img
                    src={
                      this.state.intro_session.session_image_preview ||
                      this.state.intro_session.session_image
                    }
                    alt="Profile"
                    style={{
                      maxHeight: "124px",
                      maxWidth: "150px",
                      marginRight: "20px",
                    }}
                  />
                )}
              <div className="utility-inline-label">
                <label>
                  <input
                    type="file"
                    onChange={this.handleOtherImage}
                    accept="image/*"
                    name="session_image"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Intro Image
                  </p>
                </label>
              </div>
            </div>
            <div className="utility-flex-row">
              <label
                className="utility-inline-label"
                style={{ marginRight: "20px", width: "300px" }}
              >
                <p>Intro Music URL</p>
                <input
                  type="file"
                  onChange={this.handleBGMediaUpload}
                  style={{ display: "none" }}
                  accept={".mp3"}
                />
                <p
                  className="utility-btn-primary"
                  style={{ color: "#fff" }}
                  tabIndex="0"
                  onKeyPress={this.clickParent}
                >
                  Upload Audio
                </p>

                <audio
                  id="intro_session_main_url"
                  src={
                    this.state.intro_session.session_main_url_preview ||
                    this.state.intro_session.session_main_url
                  }
                  controls
                  style={{ width: "300px" }}
                />
              </label>
            </div>
          </div>
          {isEdit && (
            <div className="utility-inline-label">
              <SortableSessions
                items={this.state.sessions}
                state={this.state}
                onSortEnd={this.onSortEnd}
                sessions={this.state.session_data || []}
                handleChallengesDay={this.handleChallengesDay}
              />
            </div>
          )}

          {isEdit && (
            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <button
                className="utility-btn-danger"
                onClick={this.handleDelete}
              >
                Delete Challenges
              </button>
            </div>
          )}
        </MainContentContainer>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    all_sessions: state.sessions.all_sessions,
    challenges: state.challenges.challenges,
    image: state.media.image,
    audio: state.media.audio,
    authors: state.authors.authors,
  };
}

const mapDispatchToProps = {
  fetchAllSessions,
  fetchAllChallenges,
  uploadImage,
  clearMedia,
  uploadAudio,
  addEditChallenges,
  deleteChallenges,
  clearAllChallenges,
  fetchOneChallenge,
  fetchAuthors,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditChallenges);
