import {
    FETCH_ALL_PROMO_BANNERS,
  } from '../constants/ActionTypes'
  
  const INITIAL_STATE = { banners: null };
  
  export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
      case FETCH_ALL_PROMO_BANNERS:
        return { ...state, banners: [...action.payload] };
      default:
        return state;
    }
  }
  