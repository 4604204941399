import {
  FETCH_ALL_TAPPING_POINTS,
  CLEAR_TAPPING_POINTS,
} from '../constants/ActionTypes'

const INITIAL_STATE = { tapping_points: null };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_TAPPING_POINTS:
      return { ...state, tapping_points: [...action.payload] };
    case CLEAR_TAPPING_POINTS:
      return { ...INITIAL_STATE};
    default:
      return state;
  }
}
