import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { Box, Button, Typography, Container } from '@mui/material';
import {
  addOrEditSuggestedSearch,
  deleteSuggesteedSearch,
  fetchAvailableOption,
  fetchSuggestedSearches,
} from "../../actions/suggestedSearchAction";
import SuggestedSearchSortableItem from "./SuggestedSearchSortableItem";
import Swal from "sweetalert2";

const SuggestedSearches = (props) => {
  const [suggestedSearchData, setSuggestedSearchData] = useState([]);
  const [edited, setEdited] = useState(false);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor)
  );

  useEffect(() => {
    props.fetchAvailableOption();
    props.fetchSuggestedSearches();
  }, []);

  useEffect(() => {
    if (Array.isArray(props.suggested_search_data)) {
      setSuggestedSearchData(props.suggested_search_data);
    } else {
      setSuggestedSearchData([]);
    }
  }, [props.suggested_search_data]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = suggestedSearchData.findIndex((item) => item.id === active.id);
      const newIndex = suggestedSearchData.findIndex((item) => item.id === over.id);
      setSuggestedSearchData((prev) => arrayMove(prev, oldIndex, newIndex));
      setEdited(true);
    }
  };

  const validateData = () => {
    for (let i = 0; i < suggestedSearchData.length; i++) {
      const data = suggestedSearchData[i];
      if (!data.object_id || !data.object_type || !data.search_behavior) {
        throw generalErrorHandle("Please fill all missing data for the suggested search.");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateData();
    const data = JSON.stringify(suggestedSearchData);
    props.addOrEditSuggestedSearch(data);
    setEdited(false);
  };

  const handleDelete = (suggested_search_id, index) => {
    if (suggested_search_id) {
      props.deleteSuggesteedSearch(suggested_search_id);
    }
    const updatedData = suggestedSearchData.filter((_, idx) => idx !== index);
    setSuggestedSearchData(updatedData);
  };

  const handleChange = (e, index) => {
    const value = e.value;
    const prop = e.prop;
    let items = [...suggestedSearchData];
    let item = { ...items[index] };
    if (prop === "object_type" && item[prop] !== value) {
      item["object_id"] = "";
    }
    item[prop] = value;
    items[index] = item;
    setEdited(true);
    setSuggestedSearchData(items);
  };

  const addNewSuggestedSearch = () => {
    const hasUnsavedItem = suggestedSearchData.some((item) => item.id === undefined);

    if (!hasUnsavedItem) {
      const data = { object_id: "", object_type: "", search_behavior: "" };
      setSuggestedSearchData((prev) => [...prev, data]);
    } else {
      Swal.fire({ icon: "error", text: "Please save the current new item before adding another." });
    }
  };

  const renderSuggestedSearch = () => {
    if (!props.suggested_search_options || !Array.isArray(suggestedSearchData)) {
      return <Typography variant="body1">Loading...</Typography>;
    }

    if (suggestedSearchData.length === 0) {
      return <Typography variant="body1">No searches available. Add a new one!</Typography>;
    }

    return (
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={suggestedSearchData} strategy={verticalListSortingStrategy}>
          {suggestedSearchData.map((value, index) => (
            <SuggestedSearchSortableItem
              key={`suggestedsearch-${value.id}`}
              data={value}
              data_options={props.suggested_search_options}
              el_index={index}
              handleChange={handleChange}
              handleDelete={handleDelete}
            />
          ))}
        </SortableContext>
      </DndContext>
    );
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h5" gutterBottom>
          Suggested Search
        </Typography>
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button variant="outlined" color="primary" onClick={addNewSuggestedSearch}>
            Add New
          </Button>
        </Box>

        <Box>{renderSuggestedSearch()}</Box>

        <Box display="flex" justifyContent="flex-end" mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ width: "35%" }}
            disabled={!edited}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    suggested_search_options: state.search.suggested_search_options,
    suggested_search_data: state.search.suggested_search_data,
  };
}

const mapDispatchToProps = {
  fetchAvailableOption,
  fetchSuggestedSearches,
  deleteSuggesteedSearch,
  addOrEditSuggestedSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedSearches);
