import { api, errorHandler, queryString } from "./index";
import { API_ERROR, DELETE_ADMIN, DELETE_ADMIN_ERROR, DELETE_ADMIN_SUCCESS, FETCH_ALL_ADMINS, FETCH_ALL_ADMINS_ERROR, FETCH_ALL_ADMINS_SUCCESS, FETCH_ONE_ADMIN, FETCH_ONE_ADMIN_ERROR, FETCH_ONE_ADMIN_SUCCESS, RESET_PASSWORD_ADMIN, RESET_PASSWORD_ADMIN_ERROR, RESET_PASSWORD_ADMIN_SUCCESS, SAVE_ADMIN, SAVE_ADMIN_ERROR, SAVE_ADMIN_SUCCESS, UPDATE_PROFILE_ADMIN, UPDATE_PROFILE_ADMIN_ERROR, UPDATE_PROFILE_ADMIN_SUCCESS } from "../constants/ActionTypes";
import { push } from "react-router-redux";
import Swal from "sweetalert2";

export function fetchAllAdmins() {
    return function (dispatch) {
        dispatch({
            type: FETCH_ALL_ADMINS
        });

        api.post(`/cms/admins/fetch-all-admins.php`)
            .then((response) => {
                dispatch({
                    type: FETCH_ALL_ADMINS_SUCCESS,
                    payload: response.data.objects
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_ALL_ADMINS_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function saveAdmin(data) {
    return function (dispatch) {
        dispatch({
            type: SAVE_ADMIN
        });

        api.post(`/cms/admins/add-n-edit-admin.php`,
            queryString.stringify(data)
        )
            .then((response) => {
                dispatch({
                    type: SAVE_ADMIN_SUCCESS,
                    payload: data
                });
                Swal.fire({
                    icon: "success",
                    title: "Admin user saved!",
                    timer: 2000
                });
                dispatch(push("/admins"));
            })
            .catch((error) => {
                dispatch({
                    type: SAVE_ADMIN_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function deleteAdmin(admin_id) {
    return function (dispatch) {
        dispatch({
            type: DELETE_ADMIN
        });

        api.post(`/cms/admins/delete-admin.php`,
            queryString.stringify({ admin_id })
        )
            .then((response) => {
                dispatch({
                    type: DELETE_ADMIN_SUCCESS,
                    payload: admin_id
                });
                Swal.fire({
                    icon: "success",
                    title: "Admin user Deleted!",
                    timer: 2000
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_ADMIN_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function fetchOneAdmin(admin_id) {
    return function (dispatch) {
        dispatch({
            type: FETCH_ONE_ADMIN
        });

        api.post(`/cms/admins/fetch-one-admin.php`,
            queryString.stringify({ admin_id })
        )
            .then((response) => {
                dispatch({
                    type: FETCH_ONE_ADMIN_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_ONE_ADMIN_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function resetPasswordAdmin(admin_id) {
    return function (dispatch) {
        dispatch({
            type: RESET_PASSWORD_ADMIN
        });

        api.post(`/cms/admins/reset-password-admin.php`,
            queryString.stringify({ admin_id })
        )
            .then((response) => {
                dispatch({
                    type: RESET_PASSWORD_ADMIN_SUCCESS,
                    payload: admin_id
                });
                Swal.fire({
                    icon: "success",
                    title: "Password reseted!",
                    html: "The new password is: <b>" + response.data + "</b>",
                    confirmButtonText: "Ok",
                });
            })
            .catch((error) => {
                dispatch({
                    type: RESET_PASSWORD_ADMIN_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function updateProfile(data) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_PROFILE_ADMIN
        });

        var form = new FormData();
        form.append("admin_name", data.admin_name);
        form.append("admin_email", data.admin_email);
        form.append("admin_image", data.admin_image);
        if (data.profile_image) {
            form.append("profile_image", data?.profile_image);
        }
        if (data.admin_password && data.admin_password.trim() !== '') {
            form.append("admin_password", data.admin_password.trim());
        }

        api.post(`/cms/admins/edit-profile-admin.php`, form)
            .then((response) => {
                dispatch({
                    type: UPDATE_PROFILE_ADMIN_SUCCESS,
                    payload: response.data
                });
                Swal.fire({
                    icon: "success",
                    title: "Profile Updated!",
                    timer: 2000
                });
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_PROFILE_ADMIN_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}
