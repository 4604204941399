import { api, errorHandler, queryString } from "./index";
import {
  API_ERROR,
  FETCH_SEARCH_DEEPLINKS,
} from "../constants/ActionTypes";
import Swal from "sweetalert2";

export function fetchSearchDeeplinksInfo() {
  return function (dispatch) {
    api.post(`/fetch-general-info.php`)
      .then(response => {
        var data = {
          deeplink_ios: response.data.search_deeplink_ios_url ?? "",
          deeplink_android: response.data.search_deeplink_android_url ?? "",
          deeplink_image_url: response.data.search_deeplink_image_url ?? "",
        };
        dispatch({
          type: FETCH_SEARCH_DEEPLINKS,
          payload: data
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function saveSearchDeeplinksInfo(
  deeplink_image_url,
  deeplink_ios_url,
  deeplink_android_url
) {
  return async function (dispatch) {
    try {
      await api.post(`/update-search-deeplinks-info.php`,
        queryString.stringify({
          deeplink_image_url: deeplink_image_url,
          deeplink_ios_url: deeplink_ios_url,
          deeplink_android_url: deeplink_android_url,
        })
      );
    } catch (error) {
      return errorHandler(dispatch, error.response, API_ERROR);
    }
    Swal.fire({
      icon: "success",
      title: "Deeplinks Saved!",
      timer: 1000
    });
  };
}
