import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./AuthNav.css";

function AuthNav({ navRoutes }) {
  const location = useLocation();

  return (
    <nav className="AuthNav" >
      <img src="/img/top-ts-logo.jpg" alt="" />
      <ul>
        {navRoutes.map((route, index) => (
          <li key={index}>
            <NavLink
              to={route.path}
              isActive={() => location.pathname.includes(route.path)}
              activeClassName="active"
            >{route.label}</NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default AuthNav;
