import React, { Component } from 'react';

export default class Error404 extends Component {

  render() {
    return (
      <div className="container notFound" style={{ textAlign: 'center' }}>
        <h1>404 Error</h1>
        <p>We can't seem to find the page you're looking for.</p>
      </div>
    );
  }
}
