import { api, errorHandler, queryString } from "./index";
import { API_ERROR } from "../constants/ActionTypes";

export function addObjectTag(options) {
  return function (dispatch) {
    api.post(`/add-object-tag.php`,
      queryString.stringify({
        object_id: options.object_id,
        object_type: options.object_type,
        tag: options.tag,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Tag added!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/sessions"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function removeObjectTag(options) {
  return function (dispatch) {
    api.post(`/remove-object-tag.php`,
      queryString.stringify({
        object_id: options.object_id,
        object_type: options.object_type,
        tag: options.tag,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Tag removed!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/sessions"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
