import { AppBar, Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LoadingBackdrop from '../../Common/LoadingBackdrop'
import { Cancel, Save } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOneAdmin, saveAdmin } from '../../../actions/adminsActions';
import { ROLE_ADMIN, ROLE_CUSTOMER_SUPPORT, ROLE_SUPER_ADMIN } from '../../../constants/AdminRoles';
import SessionHelper from '../../Helper/SessionHelper';

export default function AdminFormPage() {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [admin, setAdmin] = useState({
        admin_id: 0,
        admin_name: '',
        admin_email: '',
        admin_password: '',
        admin_password_confirmation: '',
        admin_role: 0
    });

    const loading = useSelector(state => state.admins.loading);
    const adminStore = useSelector(state => state.admins.admin);

    const roles = [
        {
            id: ROLE_SUPER_ADMIN,
            name: SessionHelper.roleString(ROLE_SUPER_ADMIN)
        },
        {
            id: ROLE_ADMIN,
            name: SessionHelper.roleString(ROLE_ADMIN)
        },
        {
            id: ROLE_CUSTOMER_SUPPORT,
            name: SessionHelper.roleString(ROLE_CUSTOMER_SUPPORT)
        }
    ];

    useEffect(() => {
        fetchAndSetAdmin();
    }, [adminStore]);

    const handleSave = e => {
        e.preventDefault();
        if (admin.admin_id === 0 && (admin.admin_password !== admin.admin_password_confirmation)) {
            Swal.fire({
                title: 'Password Mismatch',
                text: 'Password and password confirmation do not match',
                type: 'error'
            })
            return;
        }

        dispatch(saveAdmin(admin));
    }

    const handleChange = e => {
        setAdmin({
            ...admin,
            [e.target.name]: e.target.value
        })
    }

    const fetchAndSetAdmin = () => {
        if (!id) return;
        if (adminStore && adminStore.admin_id === parseInt(id)) {
            setAdmin(adminStore);
            return;
        };
        dispatch(fetchOneAdmin(id));
    }

    return (
        <Container sx={{ py: 2 }} component="form" onSubmit={handleSave}>
            <LoadingBackdrop open={loading} />
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {admin.admin_id > 0 ? 'Edit' : 'Create'} Admin user
                    </Typography>
                    <Button
                        color="success"
                        type="submit"
                        startIcon={<Save />}
                        variant="contained"
                        sx={{ marginRight: 2 }}
                        disabled={loading}
                    >
                        Save
                    </Button>
                    <Button
                        color="error"
                        component={Link}
                        to={`/admins`}
                        startIcon={<Cancel />}
                        variant="contained"
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                </Toolbar>
            </AppBar>
            <Box gap={2}>
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                    <Grid item md={4} xs={12} sx={{ marginBottom: 2 }} >
                        <TextField
                            fullWidth
                            label="Name"
                            variant="outlined"
                            name="admin_name"
                            value={admin.admin_name}
                            onChange={handleChange}
                            autoFocus
                            required
                        />
                    </Grid>
                    <Grid item md={5} xs={12} sx={{ marginBottom: 2 }}>
                        <TextField
                            type="email"
                            fullWidth
                            label="Email"
                            variant="outlined"
                            name="admin_email"
                            value={admin.admin_email}
                            onChange={handleChange}
                            required
                            autoComplete='off'
                        />
                    </Grid>
                    <Grid item md={3} xs={12} sx={{ marginBottom: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id="admin-role-label">Role</InputLabel>
                            <Select
                                labelId="admin-role-label"
                                label="Role"
                                name="admin_role"
                                value={parseInt(admin.admin_role)}
                                onChange={handleChange}
                                required
                            >
                                {
                                    roles.map((role, index) => (
                                        <MenuItem key={index} value={role.id}>{role.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        !id && (
                            <>
                                <Grid item xs={6} sx={{ marginBottom: 2 }}>
                                    <TextField
                                        type="password"
                                        fullWidth
                                        label="Password"
                                        variant="outlined"
                                        name="admin_password"
                                        value={admin.admin_password}
                                        onChange={handleChange}
                                        required
                                        autoComplete='off'
                                    />
                                </Grid>
                                <Grid item xs={6} sx={{ marginBottom: 2 }}>
                                    <TextField
                                        type="password"
                                        fullWidth
                                        label="Password Confirmation"
                                        variant="outlined"
                                        name="admin_password_confirmation"
                                        value={admin.admin_password_confirmation}
                                        onChange={handleChange}
                                        required
                                        autoComplete='off'
                                    />
                                </Grid>
                            </>
                        )
                    }
                </Grid>
            </Box>
        </Container>
    )
}
