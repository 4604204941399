import { CLEAR_HIGHLIGHT } from "../constants/ActionTypes";

const INITIAL_STATE = { highlight_index: null };

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLEAR_HIGHLIGHT:
      return { ...state, highlight_index: action.payload };
    default:
      return state;
  }
}
