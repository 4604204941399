import React, { useEffect, useState } from 'react'
import { AppBar, Container, Toolbar, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Avatar } from '@mui/material';
import { Add, Visibility, Delete, Edit } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMediaResource, fetchAllMediaResources } from '../../../actions/mediaResourceActions';
import Swal from 'sweetalert2';
import LoadingBackdrop from '../../Common/LoadingBackdrop';
import MediaResourceModal from '../../MediaResource/MediaResourceModal';
import MediaResourceUpdateForm from '../../MediaResource/MediaResourceUpdateForm';

export default function MediaResource() {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.mediaResources.loading);
    const mediaResources = useSelector(state => state.mediaResources.mediaResources);
    const [openView, setOpenView] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null)
    const [openEdit, setOpenEdit] = useState(false);

    useEffect(() => {
        fetchMediaResources();
    }, []);

    const fetchMediaResources = () => {
        dispatch(fetchAllMediaResources());
    }

    const handleView = (resource) => {
        setSelectedMedia(resource);
        setOpenView(true);
    };

    const handleCloseView = () => {
        setOpenView(false);
        setSelectedMedia(null);
    };

    const handleEdit = (resource) => {
        setSelectedMedia(resource);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setSelectedMedia(null);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: `Are you sure?`,
            text: 'You will not be able to recover this media resource!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel'
        }).then((result) => {
            if (result.value) {
                dispatch(deleteMediaResource(id));
            }
        });
    };

    return (
        <Container sx={{ py: 2 }}>
            <LoadingBackdrop open={loading} />
            <AppBar position="sticky">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Media Resources
                    </Typography>
                    <Button color="inherit" component={Link} to={`/media-resources/create`} startIcon={<Add />}>
                        Add New
                    </Button>
                </Toolbar>
            </AppBar>

            <TableContainer component={Paper} sx={{ marginTop: 3 }}>
                <Table aria-label="media resources table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableCell><strong>Thumbnail</strong></TableCell>
                            <TableCell><strong>Title / Description</strong></TableCell>
                            <TableCell align="right"><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            mediaResources.length > 0 ? (
                                mediaResources.map((resource) => (
                                    <TableRow key={resource.id}>
                                        <TableCell>
                                            <Avatar
                                                variant="rounded"
                                                src={resource.resource_url}
                                                alt={resource.file_name}
                                                sx={{ width: 100, height: 100 }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1">{resource.name}</Typography>
                                            <Typography variant="body2" color="textSecondary">{resource.description}</Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton color="primary" onClick={() => handleView(resource)}>
                                                <Visibility />
                                            </IconButton>
                                            <IconButton color="info" onClick={() => handleEdit(resource)}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton color="error" onClick={() => handleDelete(resource.id)}>
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <MediaResourceModal
                open={openView}
                onClose={handleCloseView}
                mediaResource={selectedMedia}
            />

            <MediaResourceUpdateForm
                open={openEdit}
                onClose={handleCloseEdit}
                mediaResource={selectedMedia}
                setMediaResource={setSelectedMedia}
            />
        </Container>
    )
}
