import { Delete, Link, AudioFile } from '@mui/icons-material';
import VisuallyHiddenInput from './VisuallyHiddenInput';
import { Button, Card, CardActions, CardContent, Snackbar, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { copyToClipboard } from '../../utils/clipboard';

export default function AudioContainerPreview({ title, audio, name, handleRemove, handleAudioUpload, onLoadedData, showCopyUrlButton, ...props }) {
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCopyUrl = () => {
    if (audio) {
      copyToClipboard(audio);
      setSnackbarMessage('URL copied to clipboard!');
      setOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpen(false);
    setSnackbarMessage('');
  };

  return (
    <Card>
      {audio ? (
        <div {...props}>
          <AudioPlayer
            src={audio}
            customAdditionalControls={[]}
            onLoadedData={onLoadedData}
            showJumpControls={true}
            showDownloadProgress={true}
          />
        </div>
      ) : (
        <div {...props} style={{ height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            No audio available
          </Typography>
        </div>
      )}
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
      </CardContent>
      <CardActions>
        {audio ? (
          <>
            <Button size="small" color="error" startIcon={<Delete />} name={name} onClick={handleRemove}>
              Remove
            </Button>
            {showCopyUrlButton && (
              <Tooltip title={audio} placement="bottom">
                <Button size="small" color="primary" startIcon={<Link />} onClick={handleCopyUrl}>
                  Copy URL
                </Button>
              </Tooltip>
            )}
          </>
        ) : (
          <Button
            fullWidth
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<AudioFile />}
          >
            Add Audio
            <VisuallyHiddenInput type="file" name={name} accept="audio/*" onChange={handleAudioUpload} />
          </Button>
        )}
      </CardActions>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Card>
  );
}
