import { alpha, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';
import colors from './scss/_theme1.module.scss';

export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark'
};

// This function will create our theme
const createAppTheme = (mode) => createTheme({
  palette: {
    mode,
    common: {
      black: colors.darkPaper
    },
    primary: {
      light: mode === ThemeMode.DARK ? colors.darkPrimaryLight : colors.primaryLight,
      main: mode === ThemeMode.DARK ? colors.darkPrimaryMain : colors.primaryMain,
      dark: mode === ThemeMode.DARK ? colors.darkPrimaryDark : colors.primaryDark,
      200: mode === ThemeMode.DARK ? colors.darkPrimary200 : colors.primary200,
      800: mode === ThemeMode.DARK ? colors.darkPrimary800 : colors.primary800
    },
    secondary: {
      light: mode === ThemeMode.DARK ? colors.darkSecondaryLight : colors.secondaryLight,
      main: mode === ThemeMode.DARK ? colors.darkSecondaryMain : colors.secondaryMain,
      dark: mode === ThemeMode.DARK ? colors.darkSecondaryDark : colors.secondaryDark,
      200: mode === ThemeMode.DARK ? colors.darkSecondary200 : colors.secondary200,
      800: mode === ThemeMode.DARK ? colors.darkSecondary800 : colors.secondary800
    },
    error: {
      light: colors.errorLight,
      main: colors.errorMain,
      dark: colors.errorDark
    },
    orange: {
      light: colors.orangeLight,
      main: colors.orangeMain,
      dark: colors.orangeDark
    },
    warning: {
      light: colors.warningLight,
      main: colors.warningMain,
      dark: colors.warningDark
    },
    success: {
      light: colors.successLight,
      200: colors.success200,
      main: colors.successMain,
      dark: colors.successDark
    },
    grey: {
      50: colors.grey50,
      100: colors.grey100,
      500: mode === ThemeMode.DARK ? colors.darkTextSecondary : colors.grey500,
      600: mode === ThemeMode.DARK ? colors.darkTextTitle : colors.grey600,
      700: mode === ThemeMode.DARK ? colors.darkTextPrimary : colors.grey700,
      900: mode === ThemeMode.DARK ? colors.darkTextPrimary : colors.grey900
    },
    dark: {
      light: colors.darkTextPrimary,
      main: colors.darkLevel1,
      dark: colors.darkLevel2,
      800: colors.darkBackground,
      900: colors.darkPaper
    },
    text: {
      primary: mode === ThemeMode.DARK ? colors.darkTextPrimary : colors.grey700,
      secondary: mode === ThemeMode.DARK ? colors.darkTextSecondary : colors.grey500,
      dark: mode === ThemeMode.DARK ? colors.darkTextPrimary : colors.grey900,
      hint: colors.grey100
    },
    divider: mode === ThemeMode.DARK ? alpha(colors.grey200, 0.2) : colors.grey200,
    background: {
      paper: mode === ThemeMode.DARK ? colors.darkLevel2 : colors.paper,
      default: mode === ThemeMode.DARK ? colors.darkPaper : colors.paper
    }
  }
});

// This is a custom hook that we'll use in our components
export function useAppTheme() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(
    // TODO define dark theme colors because now it's not working.
    // change the 'light' : 'light' to 'dark' : 'light' when dark theme is ready
    () => createAppTheme(prefersDarkMode ? ThemeMode.LIGHT : ThemeMode.LIGHT),
    [prefersDarkMode]
  );

  return theme;
}

// We'll also export a default theme for cases where we can't use the hook
export default createAppTheme('light');