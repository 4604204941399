import React from 'react';
import Swal from 'sweetalert2';

const FormatTimestamp = ({ handleChange, value, stateProp, indexToChange }) => {

  const handleMinutesChange = (e) => {
    let valSeconds = value % 60;
    let newValue = Number(e.target.value) * 60 + valSeconds;
    handleChange(newValue, stateProp, indexToChange, 'timestamp');
  }

  const handleSecondsChange = (e) => {
    if (Number(e.target.value) > 59) {
      Swal.fire({
        icon: 'error',
        text: 'Seconds cannot be greater than 59'
      })
    }
    let valMinutes = value - (value % 60);
    let newValue = Number(e.target.value) + valMinutes;
    handleChange(newValue, stateProp, indexToChange, 'timestamp');
  }

  return (
    <div className="utility-flex-row" style={{ alignItems: 'center' }}>
      M
      <input
        type="number"
        style={{ width: '50px', margin: '0 10px' }}
        className="utility-text-input"
        name="minutes"
        value={Math.floor(value / 60)}
        onChange={handleMinutesChange}
        min="0"
        max="60"
        required
      />
      &nbsp;:&nbsp;
      S
      <input
        type="number"
        style={{ width: '50px', margin: '0 10px' }}
        className="utility-text-input"
        name="seconds"
        value={value % 60}
        onChange={handleSecondsChange}
        min="0"
        max="60"
        required
      />
    </div>
  );
};

export default FormatTimestamp;
