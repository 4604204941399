import Swal from "sweetalert2";
import { api, errorHandler, queryString } from "./index";
import {
  API_ERROR,
  FETCH_CANCELLATION_REASONS,
  FETCH_USER_CANCELLATION_REPORT,
} from "../constants/ActionTypes";

export function fetchAllcancellationReasons() {
  return function (dispatch) {
    api.post(`/fetch-cancellation-reasons.php`)
      .then((response) => {
        dispatch({
          type: FETCH_CANCELLATION_REASONS,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditCancellationReasons(json) {
  return function (dispatch) {
    api.post(`/add-n-edit-cancellation-reasons.php`,
      queryString.stringify({
        json: JSON.stringify(json),
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Saved!",
          timer: 1000,
        }).then(() => {
          dispatch(fetchAllcancellationReasons());
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.response.data.message,
          heightAuto: true,
        });
      });
  };
}

export function deleteCancellationReasons(cancellation_id) {
  return function (dispatch) {
    api.post(`/delete-cancellation-reasons.php`,
      queryString.stringify({
        cancellation_id: cancellation_id,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Deleted Successfully!",
          timer: 1000,
        }).then(() => {
          dispatch(fetchAllcancellationReasons());
        });
      })
      .catch((error) => {
        console.log("error");

        Swal.fire({
          icon: "error",
          title: error.response.data.message,
          heightAuto: true,
        });
      });
  };
}

export function fetchUserCancellationReports() {
  return function (dispatch) {
    api.post(`/fetch-user-cancellation-report.php`)
      .then((response) => {
        dispatch({
          type: FETCH_USER_CANCELLATION_REPORT,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
