import { Add, Delete, Edit, Save } from '@mui/icons-material'
import { AppBar, Box, Button, Chip, Container, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { deleteDailyReflection, fetchAllDailyReflections, fetchDailyInspirationDescription, updateDailyInspirationDescription } from '../../../actions/dailyReflectionActions';
import moment from 'moment';
import Swal from 'sweetalert2';
import LoadingBackdrop from '../../Common/LoadingBackdrop';

export default function DailyReflectionPage() {
    const dispatch = useDispatch();
    const history = useHistory();

    const currentDate = moment().format("YYYY-MM-DD");

    const [dailyReflections, setDailyReflections] = useState(null);
    const [dailyReflectionDescription, setDailyReflectionDescription] = useState(null);

    const loading = useSelector(state => state.daily_reflections.loading);
    const drStore = useSelector(state => state.daily_reflections.daily_reflections);
    const drDescriptionStore = useSelector(state => state.general_data.general_data);

    useEffect(() => {
        loadDailyReflections();
    }, [drStore]);

    useEffect(() => {
        loadDailyReflectionDescription();
    }, [drDescriptionStore])

    const loadDailyReflections = () => {
        if (drStore) {
            setDailyReflections(drStore);
            return;
        }

        dispatch(fetchAllDailyReflections());
    }

    const loadDailyReflectionDescription = () => {
        if (drDescriptionStore) {
            setDailyReflectionDescription(drDescriptionStore.daily_inspiration_description);
            return;
        }

        dispatch(fetchDailyInspirationDescription());
    }

    const handleSaveDescription = () => {
        dispatch(updateDailyInspirationDescription(dailyReflectionDescription));
    }

    const handleEdit = dailyReflection => {
        history.push(`/daily-inspirations/${dailyReflection.daily_reflection_id}/edit`);
    }

    const handleDelete = id => {
        Swal.fire({
            title: `Are you sure?`,
            text: 'You will not be able to recover this record!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel'
        }).then((result) => {
            if (result.value) {
                dispatch(deleteDailyReflection(id));
            }
        });
    }

    return (
        <Container sx={{ py: 2 }}>
            <LoadingBackdrop open={loading || dailyReflections == null} />
            <AppBar position="sticky">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Daily Inspiration
                    </Typography>
                    <Button color="inherit" component={Link} to={`/daily-inspirations/create`} startIcon={<Add />}>
                        Add New
                    </Button>
                </Toolbar>
            </AppBar>
            <Box sx={{ marginTop: 2 }}>
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                    <Grid item xs={10}>
                        <TextField
                            fullWidth
                            label="Description"
                            variant="outlined"
                            name="description"
                            value={dailyReflectionDescription || ''}
                            onChange={e => setDailyReflectionDescription(e.target.value)}
                            autoFocus
                            required
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            component="label"
                            role={undefined}
                            variant="contained"
                            color="success"
                            tabIndex={-1}
                            startIcon={<Save />}
                            size='large'
                            sx={{ boxSizing: 'border-box', paddingTop: 2, paddingBottom: 1.5, color: 'white' }}
                            onClick={handleSaveDescription}
                        >
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <TableContainer component={Paper} sx={{ marginTop: 3 }}>
                <Table aria-label="daily inspiration table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableCell><strong>Id</strong></TableCell>
                            <TableCell><strong>Scheduled For</strong></TableCell>
                            <TableCell><strong>Title</strong></TableCell>
                            <TableCell align="right"><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dailyReflections && dailyReflections.length > 0 ? (
                                dailyReflections.map(dailyReflection => {
                                    const dailyReflectionDate = dailyReflection.date_epoch ? moment(dailyReflection.date_epoch * 1000).format("YYYY-MM-DD") : "N/A";
                                    const isCurrentDateProps = currentDate === dailyReflectionDate ? { backgroundColor: 'cornsilk' } : null;

                                    return (
                                        <TableRow key={dailyReflection.daily_reflection_id} sx={isCurrentDateProps}>
                                            <TableCell>
                                                <Typography variant="subtitle1">{dailyReflection.daily_reflection_id}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle1">{dailyReflectionDate}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle1">{dailyReflection.title}</Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="Edit" placement="top">
                                                    <IconButton color="info" onClick={() => handleEdit(dailyReflection)}>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete" placement="top">
                                                    <IconButton color="error" onClick={() => handleDelete(dailyReflection.daily_reflection_id)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}
