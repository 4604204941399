import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { generalErrorHandle } from "../../actions";
import {
  fetchTopSearches,
  deleteTopSearch,
  addOrEditTopSearch,
} from "../../actions/topSearchActions";
import TopSearchSortable from "./TopSearchSortable";
import Swal from "sweetalert2";
import { Button, Container, Typography, Box } from "@mui/material";

const TopSearch = (props) => {
  const [topSearchData, setTopSearchData] = useState([]);

  // Fetch initial data
  useEffect(() => {
    props.fetchTopSearches();
  }, []);

  // Update topSearchData when props.top_search changes
  useEffect(() => {
    if (props.top_search) {
      setTopSearchData(props.top_search);
    }
  }, [props.top_search]);

  // Handle deleting an item
  const handleDelete = (e, top_search_id, index) => {
    e.preventDefault();
    if (top_search_id) {
      props.deleteTopSearch(top_search_id);
    }
    const updatedData = [...topSearchData];
    updatedData.splice(index, 1);
    setTopSearchData(updatedData);
  };

  // Handle input change
  const handleChange = (e, index) => {
    const value = e.target.value;
    const updatedData = [...topSearchData];
    updatedData[index] = { ...updatedData[index], value };
    setTopSearchData(updatedData);
  };

  // Add a new top search item
  const addNewTopSearch = () => {
    const hasUnsavedItem = topSearchData.some((item) => item.top_search_id === undefined);

    if (!hasUnsavedItem) {
      const newItem = {
        value: "",
        sort_order: topSearchData.length + 1,
      };
      setTopSearchData([...topSearchData, newItem]);
    } else {
      Swal.fire({ icon: "error", text: "Please save the current new item before adding another." });
    }
  };

  // Validate data before submitting
  const validateData = () => {
    for (let i = 0; i < topSearchData.length; i++) {
      const data = topSearchData[i];
      if (!data.value) {
        throw generalErrorHandle("Please Fill All Missing Data For Top Search");
      }
    }
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    validateData();
    props.addOrEditTopSearch(JSON.stringify(topSearchData));
  };

  // Render loading state if top_search is not available
  if (!props.top_search) {
    return <Typography variant="h3" component="h3">Loading...</Typography>;
  }

  return (
    <Container>
      {/* Header Section */}
      <Box my={4} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" component="h2">
          Top Search
        </Typography>
        <Button variant="outlined" color="primary" onClick={addNewTopSearch}>
          Add New
        </Button>
      </Box>

      {/* Sortable Items Section */}
      <Box>
        {topSearchData.length > 0 && (
          <TopSearchSortable
            items={topSearchData}
            setItems={setTopSearchData}
            onDelete={handleDelete}
            handleChange={handleChange}
          />
        )}
      </Box>

      {/* Save Button Section */}
      <Box display="flex" justifyContent="flex-end" mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ width: "35%" }}
        >
          Save
        </Button>
      </Box>
    </Container>
  );
};

// Map state to props
function mapStateToProps(state) {
  return {
    top_search: state.search.top_search_data || [],
  };
}

// Map dispatch to props
const mapDispatchToProps = {
  fetchTopSearches,
  deleteTopSearch,
  addOrEditTopSearch,
};

// Export connected component
export default connect(mapStateToProps, mapDispatchToProps)(TopSearch);
