import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderBar from "./../HeaderBar/HeaderBar";
import MainContentContainer from "./../MainContentContainer/MainContentContainer";
import {
  fetchAllCategories,
  addEditCategory,
  deleteCategory,
  nonExistentCategory,
} from "./../../actions/categoryActions";
import {
  uploadImage,
  uploadCategoryImage,
  clearMedia,
} from "./../../actions/mediaActions";
import { fetchAllSessions } from "./../../actions/sessionActions";
import { fetchAllSubCategories } from "./../../actions/subcategoryActions";
import { fetchAllSeries } from "./../../actions/seriesActions";
import { fetchAllQuickTapSessions } from "./../../actions/quickTapsActions";

import Select from "react-select";

import DynamicOptionsSelect from "./../DynamicOptionsSelect/DynamicOptionsSelect";
import { arrayMoveImmutable } from "array-move";

import Swal from "sweetalert2";

import "./AddEditCategory.css";

class AddEditCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category_title: "",
      category_is_active: 1,
      child_objects: null,
      session_data: [],
      quick_tap_session_data: [],
      session_objects: [],
      subcategories_objects: [],
      quick_tap_session_objects: [],
      series_objects: [],
      isSetToEdit: false,
      isSessionsLoaded: false,
      isQuickTapSessionsLoaded: false,
      isSubcategoriesLoaded: false,
      isSeriesLoaded: false,
      category_image_preview: null,
      category_image_url: "",
      category_image: "",
      category_is_dark_mode: 0,
      category_is_quick_tap: null,
      category_is_select_interest: 1,
      category_explore_term: "",
      category_deep_link_ios: null,
      category_deep_link_android: null,
      category_deep_link_image: null,

      deep_link_image_preview: "",
      deep_link_image_uploading: false,
    };
  }

  componentDidMount() {
    if (this.props.isEdit) {
      this.props.fetchAllSubCategories();
      this.props.fetchAllSessions();
      this.props.fetchAllQuickTapSessions();
      this.props.fetchAllSeries();
      this.props.fetchAllCategories();
    }
  }

  componentDidUpdate(prevProps) {
    const { isSetToEdit, session_objects, quick_tap_session_objects, subcategories_objects, series_objects } = this.state;
    const { isEdit, all_categories, isLoadingSessions, isLoadingSubcategories, isLoadingSeries, isLoadingQuickTap } = this.props;

    if (isEdit && all_categories && !isSetToEdit) {
      this.setCategoryForEditing(this.props);
    }

    if (isSetToEdit && !isLoadingSessions && !isLoadingSubcategories && !isLoadingSeries && !isLoadingQuickTap) {
      this.checkAndLoadData();
    }

    if (this.shouldUpdateChildObjects() && !isLoadingSessions && !isLoadingSubcategories && !isLoadingSeries && !isLoadingQuickTap) {
      const children = [...session_objects, ...quick_tap_session_objects, ...subcategories_objects, ...series_objects];
      const objects = children.sort((a, b) => a.index === -1 ? 1 : a.index - b.index).map(item => item.value);
      this.setState({ child_objects: objects });
    }

    if (this.props.image && this.props.image !== prevProps.image) {
      if (this.state.deep_link_image_uploading) {
        this.setState({
          category_deep_link_image: this.props.image,
          deep_link_image_uploading: false
        });
        this.props.clearMedia();
      } else {
        this.setState({
          category_image: this.props.image
        });
        this.props.clearMedia();
      }
    }
  }

  checkAndLoadData() {
    const { category_is_quick_tap, isSessionsLoaded, isQuickTapSessionsLoaded, isSeriesLoaded, isSubcategoriesLoaded } = this.state;
    const { all_sessions, all_quick_tap_sessions, all_series, subcategories } = this.props;

    if (category_is_quick_tap === 0 && all_sessions && !isSessionsLoaded) {
      this.updateSessions(this.props);
    }
    if (category_is_quick_tap === 1 && all_quick_tap_sessions && !isQuickTapSessionsLoaded) {
      this.updateQuickTapSessions(this.props);
    }
    if (all_series && !isSeriesLoaded) {
      this.updateSeries(this.props);
    }
    if (subcategories && !isSubcategoriesLoaded) {
      this.updateSubcategories(this.props);
    }
  }

  shouldUpdateChildObjects() {
    const { isSetToEdit, child_objects, isSeriesLoaded, isSubcategoriesLoaded, isQuickTapSessionsLoaded, isSessionsLoaded } = this.state;

    return isSetToEdit &&
      !child_objects &&
      isSeriesLoaded &&
      isSubcategoriesLoaded &&
      (isQuickTapSessionsLoaded || isSessionsLoaded);
  }

  setCategoryForEditing = ({
    all_categories
  }) => {
    let currentCategory = null;
    for (let x = 0; x < all_categories.length; x++) {
      if (all_categories[x].category_id === Number(this.props.selectedId)) {
        currentCategory = all_categories[x];
        break;
      }
    }
    if (currentCategory == null) {
      this.props.nonExistentCategory()
      return;
    }
    this.setState({
      ...currentCategory,
      isSetToEdit: true,
    });
  };

  updateSessions = ({
    all_sessions
  }) => {
    let sessions = all_sessions.filter(
      (el) => Number(this.props.selectedId) === el.category_id
    ).sort((a, b) => a.global_sort_number - b.global_sort_number);

    let child_objects = [];
    sessions.forEach((obj) => {
      child_objects.push({
        index: obj.global_sort_number - 1,
        value: obj.session_id + "SESS"
      });
    });
    let child_sessions = sessions.map((obj, i) => {
      let sessionsObj = {};
      sessionsObj[obj.session_id] = {
        session_is_featured: !!obj.session_is_featured,
        session_is_dark_mode: !!obj.session_is_dark_mode,
      };
      return sessionsObj;
    });
    this.setState({
      session_data: child_sessions,
      session_objects: child_objects,
      isSessionsLoaded: true
    });
  }

  updateQuickTapSessions = ({
    all_quick_tap_sessions,
  }) => {
    let quick_tap_sessions = all_quick_tap_sessions.filter(
      (quickTap) => {
        let category = quickTap.category;
        return category != null && Number(this.props.selectedId) === Number(category.category_id)
      }
    ).sort((a, b) => a.global_sort_number - b.global_sort_number);

    let child_objects = [];
    quick_tap_sessions.forEach((obj) => {
      child_objects.push({
        index: obj.global_sort_number - 1,
        value: obj.id + "QTSESS"
      });
    });
    let child_sessions = quick_tap_sessions.map((obj, i) => {
      let sessionsObj = {};
      sessionsObj[obj.id] = {
        session_is_featured: !!obj.is_featured,
        session_is_dark_mode: !!obj.is_dark_mode,
      };
      return sessionsObj;
    });
    this.setState({
      session_data: child_sessions,
      quick_tap_session_objects: child_objects,
      isQuickTapSessionsLoaded: true
    });
  }

  updateSubcategories = ({ subcategories }) => {
    let child_objects = [];
    let subs = subcategories.filter(
      (obj) => Number(obj.category_id) === Number(this.props.selectedId)
    ).sort((a, b) => a.global_sort_number - b.global_sort_number);

    subs.forEach((obj) => {
      child_objects.push({
        index: obj.global_sort_number - 1,
        value: obj.subcategory_id + "SUB"
      });
    });
    this.setState({
      subcategories_objects: child_objects,
      isSubcategoriesLoaded: true
    });
  }

  updateSeries = ({ all_series }) => {
    let series = all_series.filter(
      (obj) => Number(obj.category_id) === Number(this.props.selectedId)
    ).sort((a, b) => a.sort_order_number - b.sort_order_number);

    let child_objects = [];
    series.forEach((obj) => {
      child_objects.push({
        index: -1,
        value: obj.series_id + "SERIES"
      });
    });
    this.setState({
      series_objects: child_objects,
      isSeriesLoaded: true
    });
  }

  handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };
  handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!this.state.category_image_preview) {
      this.setState({
        category_image_preview: window.URL.createObjectURL(file),
      });
    } else {
      window.URL.revokeObjectURL(this.state.category_image_preview);
      this.setState({
        category_image_preview: window.URL.createObjectURL(file),
      });
    }
    this.props.uploadCategoryImage(this.props.selectedId, file);
  };

  handleDeepLinkImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.deep_link_image_preview) {
      window.URL.revokeObjectURL(this.state.deep_link_image_preview);
    }
    this.setState({
      deep_link_image_preview: window.URL.createObjectURL(file),
      deep_link_image_uploading: true,
    });
    this.props.uploadImage(file);
  };

  generateSessionOptions = () => {
    const currentCategory = Number(this.props.selectedId);

    let availableSeries = this.props.all_series
      ? this.props.all_series.filter(
        (obj) => obj.category_id === currentCategory
      )
      : [];

    let availableSubCategories = this.props.subcategories.filter(
      (obj) => Number(obj.category_id) === currentCategory
    );

    let availableSessions = this.props.all_sessions
      ? this.props.all_sessions.filter(
        (obj) => Number(obj.category_id) === currentCategory
      )
      : [];

    let availableQuickTapSessions = this.props.all_quick_tap_sessions
      ? this.props.all_quick_tap_sessions.filter(
        (quickTap) => {
          let category = quickTap.category;
          return category != null && currentCategory === Number(category.category_id)
        }
      )
      : [];

    availableSeries = availableSeries.map((obj) => ({
      label: obj.series_title + " [Series]",
      value: obj.series_id + "SERIES",
    }));

    availableSubCategories = availableSubCategories.map((obj) => ({
      label: obj.subcategory_title + " [Subcategory]",
      value: obj.subcategory_id + "SUB",
    }));

    availableSessions = availableSessions.map((obj) => ({
      label: obj.session_name + " [Session]",
      value: obj.session_id + "SESS",
    }));

    availableQuickTapSessions = availableQuickTapSessions.map((obj) => ({
      label: obj.title + " [Quick Tap Session]",
      value: obj.id + "QTSESS",
    }));

    return (
      [...availableSeries, ...availableSubCategories, ...availableSessions, ...availableQuickTapSessions] ||
      []
    );
  };

  handleSessionData = (e, isFeatured = true) => {
    const id = e.target.name;
    const value = e.target.checked;
    const newSessions = this.state.session_data.map((obj) => {
      let object_format = {
        session_is_featured: false,
        session_is_dark_mode: false,
      };
      if (Object.keys(obj)[0] === id) {
        const object_format = Object.values(obj)[0];
        if (isFeatured) {
          object_format.session_is_featured = value;
        } else {
          object_format.session_is_dark_mode = value;
        }
        obj[id] = object_format;
      }
      return obj;
    });

    this.setState({
      session_is_featured: newSessions,
    });
  };

  editSessionsDropDown = (e, index) => {
    if (e === null) return;
    this.setState((prevState) => {
      const state = { ...prevState };
      state.child_objects[index] = e.value;
      return state;
    });
  };

  editSort = ({ oldIndex, newIndex }) => {
    this.setState({
      child_objects: arrayMoveImmutable(this.state.child_objects, oldIndex, newIndex),
    });
  };

  removeSession = (index) => {
    this.setState((prevState) => {
      const state = { ...prevState };
      state.child_objects.splice(index, 1);
      return state;
    });
  };

  addSession = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      const state = { ...prevState };
      state.child_objects.push("");
      return state;
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let {
      child_objects,
      subcategories,
      sessions,
      session_data,
      quick_tap_session_data,
      ...category_json
    } = this.state;

    if (category_json.category_is_quick_tap && category_json.category_is_select_interest) {
      Swal.fire({
        icon: "warning",
        text: "Quick Tap Categories cannot be in the Select Interest section",
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: "Ok",
      });
      return;
    }
    Swal.fire({
      title: "Saving...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    child_objects = child_objects != null ? child_objects.filter((obj) => obj.value !== "") : [];

    const child_objects_json = [];

    child_objects.forEach((str, i) => {
      const object_id = parseInt(str, 10);
      const is_sub = str.indexOf("SUB") > -1 ? 1 : 0;
      const is_series = str.indexOf("SERIES") > -1 ? 1 : 0;
      const is_quick_tap = str.indexOf("QTSESS") > -1 ? 1 : 0;
      const sort_number = i + 1;
      child_objects_json.push({
        object_id,
        is_sub,
        is_series,
        sort_number,
        is_quick_tap
      });
    });

    if (session_data) {
      session_data.forEach((session) => {
        child_objects_json.forEach((childObj) => {
          if (childObj.object_id === parseInt(Object.keys(session))) {
            childObj.session_data = session;
          }
        });
      });
    }

    const options = {
      child_objects_json: JSON.stringify(child_objects_json),
      category_json: JSON.stringify(category_json),
    };
    this.props.addEditCategory(options);
  };

  handleDelete = (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "warning",
      text: "Are you sure you want to delete this category?",
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Delete Category",
    }).then((result) => {
      if (result.value) {
        this.props.deleteCategory(this.state.category_id);
      }
    });
  };

  handleCheckBox = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    this.setState({
      [name]: value === true ? 1 : 0,
    });
  };

  render() {
    const { isEdit, all_sessions, subcategories, all_series } = this.props;
    if (!all_sessions && !subcategories && !all_series) {
      return <h3>Loading...</h3>;
    }
    return (
      <form
        className="AddEditCategory"
        onSubmit={this.handleSubmit}
        autoComplete="off"
      >
        <HeaderBar header={isEdit ? "Edit Category" : "Create New Category"}>
          <Link
            to="/category"
            className="utility-btn-transparent utility-lr-margin-small"
          >
            Cancel
          </Link>
          <button className="utility-btn-primary">
            {isEdit ? "Update" : "Create"}
          </button>
        </HeaderBar>
        <MainContentContainer subContent={true}>
          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Category Name</p>
              <input
                type="text"
                className="utility-text-input"
                name="category_title"
                value={this.state.category_title}
                onChange={this.handleFieldChange}
                required
              />
            </label>
            <label className="utility-inline-label">
              <p>Status</p>
              <Select
                name="form-field-name"
                value={this.state.category_is_active}
                onChange={(e) => this.setState({ category_is_active: e.value })}
                style={{ width: "150px" }}
                clearable={false}
                searchable={false}
                options={[
                  { value: 1, label: "Active" },
                  { value: 0, label: "Not Active" },
                ]}
              />
            </label>
            <label className="utility-inline-label">
              <p>Appear in Select Interest</p>
              <Select
                name="form-field-name"
                value={this.state.category_is_select_interest}
                onChange={(e) =>
                  this.setState({ category_is_select_interest: e.value })
                }
                style={{ width: "150px" }}
                clearable={false}
                searchable={false}
                options={[
                  { value: 1, label: "Appear" },
                  { value: 0, label: "Not Appear" },
                ]}
              />
            </label>
            <label className="utility-inline-label">
              <p>Dark mode</p>
              <input
                name="category_is_dark_mode"
                type="checkbox"
                value={this.state.category_is_dark_mode === 0 ? false : true}
                onChange={this.handleCheckBox}
                checked={this.state.category_is_dark_mode === 0 ? false : true}
                style={{ width: "80px" }}
                clearable={undefined}
                searchable={undefined}
              />
            </label>
            <label className="utility-inline-label">
              <p>Quick Tap?</p>
              <input
                name="category_is_quick_tap"
                type="checkbox"
                value={this.state.category_is_quick_tap === 1 ? true : false}
                onChange={this.handleCheckBox}
                checked={this.state.category_is_quick_tap === 1 ? true : false}
                style={{ width: "80px" }}
                clearable={undefined}
                searchable={undefined}
              />
            </label>
          </div>
          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Category Description</p>
              <textarea
                className="utility-textarea"
                name="category_description"
                value={this.state.category_description}
                onChange={this.handleFieldChange}
              />
            </label>
          </div>
          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Explore More Search Term</p>
              <input
                type="text"
                className="utility-text-input"
                name="category_explore_term"
                value={this.state.category_explore_term}
                onChange={this.handleFieldChange}
                style={{ width: "580px" }}
              />
            </label>
          </div>
          <div className="utility-inline-label AddEditCategory-img-upload">
            <p>Image</p>
            <div className="utility-flex-row">
              {(this.state.category_image_preview ||
                this.state.category_image) && (
                  <img
                    src={
                      this.state.category_image_preview ||
                      this.state.category_image
                    }
                    alt="Profile"
                    style={{ maxHeight: "200px", marginRight: "50px" }}
                  />
                )}
              <label className="AddEditCategory-img-upload">
                <input
                  type="file"
                  onChange={this.handleImageUpload}
                  accept="image/*"
                />
                <p className="utility-btn-primary" style={{ color: "#fff" }}>
                  Upload Picture
                </p>
              </label>
            </div>
          </div>
          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Deep Link URL (Android)</p>
              <input
                type="text"
                className="utility-text-input"
                name="category_deep_link_android"
                value={this.state.category_deep_link_android}
                onChange={this.handleFieldChange}
              />
            </label>
            <label className="utility-inline-label">
              <p>Deep Link URL (iOS)</p>
              <input
                type="text"
                className="utility-text-input"
                name="category_deep_link_ios"
                value={this.state.category_deep_link_ios}
                onChange={this.handleFieldChange}
              />
            </label>
            <div className="utility-inline-label">
              <p>Deep Link Image</p>
              <div className="utility-flex-row">
                {(this.state.deep_link_image_preview ||
                  this.state.category_deep_link_image) && (
                    <img
                      src={
                        this.state.deep_link_image_preview ||
                        this.state.category_deep_link_image
                      }
                      alt="Profile"
                      style={{
                        maxHeight: "124px",
                        maxWidth: "150px",
                        marginRight: "20px",
                      }}
                    />
                  )}
                <label>
                  <input
                    type="file"
                    onChange={this.handleDeepLinkImageUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Image
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div>
            {isEdit ? (
              <div className="utility-flex-row">
                <div className="utility-inline-label">
                  <p>Series / Subcategories / Sessions</p>
                </div>
              </div>
            ) : <></>
            }
            <DynamicOptionsSelect
              items={this.state.child_objects}
              options={this.generateSessionOptions()}
              editDropDown={this.editSessionsDropDown}
              editSort={this.editSort}
              removeItem={this.removeSession}
              handleSessionData={this.handleSessionData}
              sessions={this.props.all_sessions ? this.props.all_sessions : []}
              quickTapSessions={this.props.all_quick_tap_sessions ? this.props.all_quick_tap_sessions : []}
              state={this.state}
            />
            {isEdit ? (
              <button
                className="utility-btn-primary"
                onClick={this.addSession}
                style={{ margin: "20px 0" }}
              >
                Add SubCategory / Session
              </button>
            ) : <></>
            }
          </div>

          {isEdit && (
            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <button
                className="utility-btn-danger"
                onClick={this.handleDelete}
              >
                Delete Category
              </button>
            </div>
          )}
        </MainContentContainer>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    all_categories: state.categories.all_categories,
    isLoadingQuickTap: state.quick_taps.isLoading,
    all_quick_tap_sessions: state.quick_taps.all_quick_tap_sessions,
    isLoadingSeries: state.sessions.isLoading,
    all_sessions: state.sessions.all_sessions,
    isLoadingSessions: state.sessions.isLoading,
    all_series: state.series.all_series,
    isLoadingSubcategories: state.subcategories.isLoading,
    subcategories: state.subcategories.subcategories
      ? Object.values(state.subcategories.subcategories)
      : [],
    image: state.media.image,
  };
}

const mapDispatchToProps = {
  fetchAllCategories,
  fetchAllSubCategories,
  fetchAllSeries,
  fetchAllSessions,
  fetchAllQuickTapSessions,
  addEditCategory,
  deleteCategory,
  uploadImage,
  uploadCategoryImage,
  clearMedia,
  nonExistentCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCategory);
