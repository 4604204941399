import React from 'react';
import { Container } from '@mui/material';

export default function (props) {
  return (
    <Container>
      {props.children}
    </Container>
  );
}
