import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateMediaResource } from '../../actions/mediaResourceActions';

export default function MediaResourceUpdateForm({ open, onClose, mediaResource, setMediaResource }) {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.mediaResources.loading);

    const handleChange = e => {
        setMediaResource({
            ...mediaResource,
            [e.target.name]: e.target.value
        });
    }

    const handleSave = e => {
        e.preventDefault();
        dispatch(updateMediaResource(mediaResource, onClose));
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSave
            }}
        >
            <DialogTitle>Edit Media Resource #{ mediaResource?.id }</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ marginBottom: 2}}>
                    Complete the fields to update the details of the media resource.
                </DialogContentText>
                <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    name="name"
                    value={mediaResource?.name}
                    onChange={handleChange}
                    autoFocus
                    required
                    disabled={loading}
                    sx={{
                        marginBottom: 2
                    }}
                />
                <TextField
                    fullWidth
                    label="Description"
                    variant="outlined"
                    name="description"
                    multiline
                    minRows={3}
                    value={mediaResource?.description}
                    onChange={handleChange}
                    disabled={loading}
                />
            </DialogContent>
            <DialogActions>
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                    { loading ? <CircularProgress color="primary" size="1.5rem" /> : 'Update'}
                </Button>
                <Button 
                    onClick={onClose} 
                    variant="contained" 
                    color="error"
                    disabled={loading}
                >Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}
