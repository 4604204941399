import {
  FETCH_ALL_TAGS,
} from '../constants/ActionTypes'

const INITIAL_STATE = { all_tags: null };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_TAGS:
      return { ...state, all_tags: action.payload };
    default:
      return state;
  }
}
