import { combineReducers } from "redux";
import authReducer from "./authReducer";
import modalReducer from "./modalReducer";
import categoriesReducer from "./categoriesReducer";
import sessionsReducer from "./sessionsReducer";
import authorsReducer from "./authorsReducer";
import tappingPointsReducer from "./tappingPointsReducer";
import quotesReducer from "./quotesReducer";
import mediaReducer from "./mediaReducer";
import subcategoriesReducer from "./subcategoriesReducer";
import tagsReducer from "./tagsReducer";
import feedbacksReducer from "./feedbacksReducer";
import requestsReducer from "./requestsReducer";
import promoBannerReducer from "./promoBannerReducer";
import promoDataReducer from "./promoDataRedurer";
import generalDataReducer from "./generalDataReducer";
import onboardingDataReducer from "./onboardingReducer";
import seriesReducer from "./seriesReducer";
import challengesReducer from "./challengesReducer";
import searchReducer from "./searchReducer";
import dailyReflectionReducer from "./dailyReflectionReducer";
import backgroundMusicReducer from "./backgroundMusicReducer";
import cancellationReducer from "./cancellationReducer";
import userCancellationReducer from "./userCancellationReducer";
import quickTapsReducer from "./quickTapsReducer";
import highlightReducer from "./highlightReducer";
import streakReducer from "./streakReducer";
import cardDecksReducer from "./cardDecksReducer";
import cardsReducer from "./cardsReducer";
import mediaResourcesReducer from "./mediaResourcesReducer";
import adminsReducer from "./adminsReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  categories: categoriesReducer,
  sessions: sessionsReducer,
  authors: authorsReducer,
  tapping_points: tappingPointsReducer,
  quotes: quotesReducer,
  media: mediaReducer,
  subcategories: subcategoriesReducer,
  tags: tagsReducer,
  feedbacks: feedbacksReducer,
  requests: requestsReducer,
  banners: promoBannerReducer,
  promo_data: promoDataReducer,
  general_data: generalDataReducer,
  onboarding_data: onboardingDataReducer,
  series: seriesReducer,
  challenges: challengesReducer,
  search: searchReducer,
  background_musics: backgroundMusicReducer,
  daily_reflections: dailyReflectionReducer,
  cancellation_reasons: cancellationReducer,
  user_cancellations: userCancellationReducer,
  quick_taps: quickTapsReducer,
  highlight_index: highlightReducer,
  user_streak: streakReducer,
  card_decks: cardDecksReducer,
  cards: cardsReducer,
  mediaResources: mediaResourcesReducer,
  admins: adminsReducer,
});

export default rootReducer;
