import React, { useState, useEffect } from 'react';
import { Grid, TextField, FormControl, Autocomplete, Switch, FormGroup, FormControlLabel, CircularProgress, Box } from '@mui/material';
import CardDeckImage from '../../Common/ImageContainerPreview';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage, clearMedia } from '../../../actions/mediaActions';
import FieldSetGroup from '../../Elements/FieldSetGroup';
import { ChevronRight, Folder } from '@mui/icons-material';

const DeckForm = ({ deck, setDeck }) => {
  const dispatch = useDispatch();
  
  const mediaStore = useSelector((state) => state.media);
  const categoryStore = useSelector((state) => state.categories);
  const subcategoryStore = useSelector(state => state.subcategories);

  const [categories, setCategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [ isEdited, setIsEdited ] = useState(false);

  useEffect(() => {
    if (mediaStore.image) {
      deck[mediaStore.target] = mediaStore.image;
      dispatch(clearMedia());
      handleChange({ target: { name: mediaStore.target, value: mediaStore.image } });
    }
  }, [mediaStore]);

  useEffect(() => {
    handleLoadCategories();
  }, [categoryStore, subcategoryStore])

  useEffect(() => {
    handleSelectionSubcategory();
  }, [deck, categories]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    dispatch(uploadImage(file, e.target.name));
  };

  const removeImage = (e) => {
    handleChange({ target: { name: e.target.name, value: "" } });
  }

  const handleSwitchChange = (e) => {
    handleChange({ target: { name: e.target.name, value: e.target.checked ? 1 : 0 } });
  }

  const handleLoadCategories = () => {
    if (!categoryStore.all_categories) return;
    if (!subcategoryStore.subcategories) return;

    const subs = Object.values(subcategoryStore.subcategories);

    const all = combineCategoryAndSubcategory(categoryStore.all_categories, subs);
    setCategories(all);
  }

  const combineCategoryAndSubcategory = (categories, subcategories) => {
    const combined = [];

    categories.forEach(category => {

      if (category.category_id === 32) return;

      combined.push({
        id: `cat-${category.category_id}`,
        name: `${category.category_title}${category.category_is_quick_tap > 0 ? ' (Quick Tap)' : ''}`,
        type: 'category',
        value: category.category_id,
        icon: <Folder sx={{ fontSize: '1rem', mr: 1 }} />
      });

      subcategories.forEach(subcategory => {
        if (subcategory.category_id == category.category_id) {
          combined.push({
            id: `sub-${subcategory.subcategory_id}`,
            name: buildSubcategoryLabel(subcategory),
            type: 'subcategory',
            value: subcategory.subcategory_id,
            icon: <ChevronRight sx={{ fontSize: '1rem', mr: 1 }} />
          });
        }
      });
    });

    return combined;
  }

  const buildSubcategoryLabel = subcategory => {
    if (!subcategory) return '';

    let label = subcategory.subcategory_title;

    if (subcategory.subcategory_is_quick_tap > 0) {
      label += ' (Quick Tap)';
    }

    if (subcategory.subcategory_is_book > 0) {
      label += ' (Audiobook)';
    }

    return label;
  };

  const handleChange = (e) => {
    setDeck({ ...deck, [e.target.name]: e.target.value });
  };

  const handleChangeCategorySubcategory = (_,v) => {
    if(!isEdited) setIsEdited(true);

    if (!v) {
      setDeck({
        ...deck,
        category_id: null,
        subcategory_id: null
      })
      return;
    }

    if (v.type == 'category') {
      setDeck({
        ...deck,
        category_id: v.value,
        subcategory_id: null
      })
      return;
    }

    if (v.type == 'subcategory') {
      setDeck({
        ...deck,
        category_id: null,
        subcategory_id: v.value
      })
      return;
    }
  }

  const handleSelectionSubcategory = () => { 
    if (!deck) return;
    if (!categories) return;

    if (deck.category_id) {
      setSelectedSubcategory(categories.find(s => s.id === `cat-${deck.category_id}`));
      return;
    }

    if (deck.subcategory_id) {
      setSelectedSubcategory(categories.find(s => s.id === `sub-${deck.subcategory_id}`));
      return;
    }

    setSelectedSubcategory(null);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            name="name"
            value={deck.name ?? ""}
            onChange={handleChange}
            autoFocus
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Autocomplete
              id='category-subcategory-select'
              disablePortal
              options={categories}
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.id}
              value={selectedSubcategory || null}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Category/Subcategory" 
                  variant="outlined" 
                  placeholder="Select a category/subcategory"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                          {subcategoryStore.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  />
              )}
              loading={subcategoryStore.isLoading}
              onChange={handleChangeCategorySubcategory}
              noOptionsText="No category/subcategory found"
              loadingText="Loading data..."
              clearText="Clear"
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                  key={option.id}
                >
                  { option.icon }
                  { option.name }
                </Box>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} container spacing={2}>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              label="Primary color"
              variant="outlined"
              type="color"
              name="primary_color_hex"
              value={deck.primary_color_hex}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              label="Free View Limit"
              variant="outlined"
              name="free_view_limit"
              value={deck.free_view_limit}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch aria-label="is premium" checked={Boolean(deck.is_premium)} onChange={handleSwitchChange} name="is_premium" />}
                label="Is Premium?"
              />
            </FormGroup>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch aria-label="is active" checked={Boolean(deck.is_active)} onChange={handleSwitchChange} name="is_active" />}
                label="Is Active?"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <FieldSetGroup title="Deck Descriptions">
            <Grid container spacing={2} item xs={12} >

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Module Description"
                  variant="outlined"
                  name="module_description"
                  multiline
                  minRows={3}
                  value={deck.module_description ?? ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Choose Card Screen Description"
                  variant="outlined"
                  name="description"
                  multiline
                  minRows={3}
                  value={deck.description ?? ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="See All - Description"
                  variant="outlined"
                  multiline
                  minRows={3}
                  name="see_all_description"
                  value={deck.see_all_description ?? ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </FieldSetGroup>
        </Grid>
        <Grid item xs={12}>
          <FieldSetGroup title="Sharing">
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={4}>
                <CardDeckImage
                  title={"Share Image"}
                  image={deck.share_image_url}
                  name="share_image_url"
                  handleRemove={removeImage}
                  handleImageUpload={handleImageUpload}
                  showCopyUrlButton={true}
                  sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
                />
              </Grid>
              <Grid item xs={8}>
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <TextField
                    fullWidth
                    label="Share Deeplink URL"
                    variant="outlined"
                    name="share_deeplink_url"
                    value={deck.share_deeplink_url ?? ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FieldSetGroup>
        </Grid>
        <Grid item xs={12}>
          <FieldSetGroup title="Purchase Store">
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Purchase Store URL"
                  variant="outlined"
                  name="purchase_store_url"
                  value={deck.purchase_store_url ?? ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Purchase Store Text"
                  variant="outlined"
                  multiline
                  minRows={3}
                  name="purchase_store_text"
                  value={deck.purchase_store_text ?? ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </FieldSetGroup>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={3}>
          <CardDeckImage
            title={"Module Image"}
            image={deck.module_image_url}
            name="module_image_url"
            handleRemove={removeImage}
            handleImageUpload={handleImageUpload}
            sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
          />
        </Grid>
        <Grid item xs={3}>
          <CardDeckImage
            title={"Header Image"}
            image={deck.header_image_url}
            name="header_image_url"
            handleRemove={removeImage}
            handleImageUpload={handleImageUpload}
            sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
          />
        </Grid>
        <Grid item xs={3}>
          <CardDeckImage
            title={"Wrapper Image"}
            image={deck.wrapper_image_url}
            name="wrapper_image_url"
            handleRemove={removeImage}
            handleImageUpload={handleImageUpload}
            sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
          />
        </Grid>
        <Grid item xs={3}>
          <CardDeckImage
            title={"Search Result Image"}
            image={deck.search_result_image_url}
            name="search_result_image_url"
            handleRemove={removeImage}
            handleImageUpload={handleImageUpload}
            sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
          />
        </Grid>
      </Grid >
    </>
  );
};

export default DeckForm;
