import {
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_TARGET,
  UPLOAD_AUDIO,
  CLEAR_MEDIA,
  UPLOAD_VIDEO,
  UPLOAD_MUX_VIDEO,
} from "../constants/ActionTypes";

const INITIAL_STATE = { image: "", audio: "", video: "" };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPLOAD_IMAGE:
      return { ...state, image: action.payload };
    case UPLOAD_IMAGE_TARGET:
      return { ...state, image: action.payload.image_url, target: action.payload.target };
    case UPLOAD_AUDIO:
      return { ...state, audio: action.payload };
    case UPLOAD_VIDEO:
      return { ...state, video: action.payload };
    case UPLOAD_MUX_VIDEO:
      const { playback_id, duration = 0 } = action.payload;
      const minutes = Math.floor(duration / 60);
      const seconds = Math.round(duration - minutes * 60);
      return {
        ...state,
        video: {
          playback_id,
          duration_in_sec: Math.round(duration),
          duration: `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`,
        },
      };
    case CLEAR_MEDIA:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
