import { api, errorHandler, queryString } from "./index";
import { push } from "react-router-redux";
import Swal from "sweetalert2";
import { API_ERROR, DELETE_MEDIA_RESOURCE, DELETE_MEDIA_RESOURCE_ERROR, DELETE_MEDIA_RESOURCE_SUCCESS, FETCH_MEDIA_RESOURCES, FETCH_MEDIA_RESOURCES_ERROR, FETCH_MEDIA_RESOURCES_SUCCESS, SAVE_MEDIA_RESOURCE, SAVE_MEDIA_RESOURCE_ERROR, SAVE_MEDIA_RESOURCE_SUCCESS, UPDATE_MEDIA_RESOURCE, UPDATE_MEDIA_RESOURCE_ERROR, UPDATE_MEDIA_RESOURCE_SUCCESS } from "../constants/ActionTypes";

export function fetchAllMediaResources() {
    return function (dispatch) {
        dispatch({
            type: FETCH_MEDIA_RESOURCES
        });

        api.post(`/cms/media-resources/fetch-all-media-resources.php`)
            .then((response) => {
                dispatch({
                    type: FETCH_MEDIA_RESOURCES_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_MEDIA_RESOURCES_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function saveMediaResource(data) {
    return function (dispatch) {
        dispatch({
            type: SAVE_MEDIA_RESOURCE
        });

        var form = new FormData();
        form.append("name", data.name);
        form.append("description", data.description);
        form.append("file", data.file);

        api.post(`/cms/media-resources/add-media-resource.php`, form)
            .then((response) => {
                dispatch({
                    type: SAVE_MEDIA_RESOURCE_SUCCESS,
                    payload: response.data
                });
                Swal.fire({
                    icon: "success",
                    title: "Media Resource Saved!",
                    timer: 2000
                });
                dispatch(push("/media-resources"));
            })
            .catch((error) => {
                dispatch({
                    type: SAVE_MEDIA_RESOURCE_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function deleteMediaResource(id) {
    return function (dispatch) {
        dispatch({
            type: DELETE_MEDIA_RESOURCE
        });

        api.post(`/cms/media-resources/delete-media-resource.php`,
            queryString.stringify({
                id
            })
        )
            .then((response) => {
                dispatch({
                    type: DELETE_MEDIA_RESOURCE_SUCCESS,
                    payload: id
                });
                Swal.fire({
                    icon: "success",
                    title: "Media Resource Deleted!",
                    timer: 2000
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_MEDIA_RESOURCE_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function updateMediaResource(data, handleClose) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_MEDIA_RESOURCE
        });

        api.post(`/cms/media-resources/edit-media-resource.php`,
            queryString.stringify(data)
        )
            .then((response) => {
                dispatch({
                    type: UPDATE_MEDIA_RESOURCE_SUCCESS,
                    payload: data
                });
                Swal.fire({
                    icon: "success",
                    title: "Media Resource Updated!",
                    timer: 2000
                });
                handleClose();
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_MEDIA_RESOURCE_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}