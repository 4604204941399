import { api, errorHandler, queryString } from "./index";
import { push } from "react-router-redux";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { API_ERROR, FETCH_ALL_SERIES, FETCH_ALL_SERIES_START } from "../constants/ActionTypes";

export function fetchAllSeries() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_SERIES_START
    });

    api.post(`/fetch-all-series-cms.php`)
      .then(response => {
        dispatch({
          type: FETCH_ALL_SERIES,
          payload: response.data.objects
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditSeries(options) {
  return function (dispatch) {
    api.post(`/add-n-edit-series.php`,
      queryString.stringify({
        series_json: options.series_json,
        category_id: options.category_id,
        session_ids_json: options.session_ids_json
      })
    )
      .then(response => {
        Swal.fire({
          icon: "success",
          title: "Saved!",
          timer: 1000
        }).then(() => {
          dispatch(push("/series"));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function clearAllSeries() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_SERIES,
      payload: null
    });
  };
}

export function deleteSeries(series_id) {
  return function (dispatch) {
    api.post(`/delete-series.php`,
      queryString.stringify({
        series_id: series_id,
      })
    )
      .then(response => {
        Swal.fire({
          icon: "success",
          title: "Series Deleted!",
          timer: 1000
        }).then(() => {
          dispatch(push("/series"));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
