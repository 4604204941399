import {
  FETCH_USER_STREAK,
  RESTORE_USER_STREAK,
  FETCH_USER_STREAK_ERROR
} from '../constants/ActionTypes'
import { parseISO, format, eachDayOfInterval } from 'date-fns';

const INITIAL_STATE = { user_streak: [], restored_dates: [] };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER_STREAK: {
      const fetchedData = action.payload;
      const dates = Object.keys(fetchedData);
      const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));
      const completeData = [];

      // Parse the first date to ensure we start from the earliest date
      let startDate = parseISO(sortedDates[0]);
      const endDate = new Date();

      // Get the interval of days between the start date and the end date
      const allDates = eachDayOfInterval({ start: startDate, end: endDate });

      // Process each date in the interval
      allDates.forEach((date) => {
        const dateString = format(date, 'yyyy-MM-dd');
        if (fetchedData[dateString]) {
          completeData.unshift({
            date: dateString,
            streak: Number(fetchedData[dateString].streak)
          });
        } else {
          completeData.unshift({
            date: dateString,
            streak: 0
          });
        }
      });

      return { ...state, user_streak: completeData, restored_dates: [] };
    }
    case FETCH_USER_STREAK_ERROR: {
      return { ...state, user_streak: [], restored_dates: [] };
    }
    case RESTORE_USER_STREAK: {
      const fetchedData = action.payload.streak;
      const dates = Object.keys(fetchedData);
      const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));
      const completeData = [];

      // Parse the first date to ensure we start from the earliest date
      let startDate = parseISO(sortedDates[0]);
      const endDate = new Date();

      // Get the interval of days between the start date and the end date
      const allDates = eachDayOfInterval({ start: startDate, end: endDate });

      // Process each date in the interval
      allDates.forEach((date) => {
        const dateString = format(date, 'yyyy-MM-dd');
        if (fetchedData[dateString]) {
          completeData.unshift({
            date: dateString,
            streak: Number(fetchedData[dateString].streak)
          });
        } else {
          completeData.unshift({
            date: dateString,
            streak: 0
          });
        }
      });

      return { ...state, user_streak: completeData, restored_dates: action.payload.missed };
    }
    default:
      return state;
  }
}
