import { push } from "react-router-redux";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import {
  API_ERROR,
  FETCH_ALL_BACKGROUND_MUSICS,
} from "../constants/ActionTypes";
import { api, errorHandler, queryString } from "./index";

//= ===============================
// Category actions
//= ===============================

export function fetchAllBGM() {
  return function (dispatch) {
    api.post(`/fetch-all-bgm.php`)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_BACKGROUND_MUSICS,
          payload: response.data.objects || [],
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditBGM(options) {
  return function (dispatch) {
    api.post(`/add-n-edit-bgm.php`,
      queryString.stringify({
        bgm_json: options.bgm_json,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Saved!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/background-musics"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteBGM(bgm_id) {
  return function (dispatch) {
    api.post(`/delete-bgm.php`,
      queryString.stringify({
        bgm_id: bgm_id,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Category Deleted!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/category"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
