import { api, errorHandler, queryString } from "./index";
import { push } from "react-router-redux";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import {
  API_ERROR,
  FETCH_ALL_GENERAL_DATA,
  FETCH_ALL_CHALLENGES,
  FETCH_ONE_CHALLENGE,
} from "../constants/ActionTypes";


//= ===============================
// Session actions
//= ===============================

export function fetchAllChallenges(type = 0) {
  return function (dispatch) {
    api.post(
      `/fetch-cms-challenges.php`,
      queryString.stringify({
        is_audiobook: type,
      })
    )
      .then((response) => {
        const challenges = response.data.objects.reduce((acc, el) => {
          acc[el.challenge_id] = el;
          return acc;
        }, {});
        dispatch({
          type: FETCH_ALL_CHALLENGES,
          payload: challenges,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export async function fetchChallengeIntro() {
  return function (dispatch) {
    api.post(`/fetch-challenge-intro.php`)
      .then((response) => {
        console.log("response: ", response);
        dispatch({
          type: FETCH_ALL_GENERAL_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updateChallengeIntro(challenge_intro_text) {
  return async function (dispatch) {
    try {
      api.post(`/update-challenge-intro.php`,
        queryString.stringify({
          challenge_intro_text: challenge_intro_text,
        })
      );
    } catch (error) {
      return errorHandler(dispatch, error.response, API_ERROR);
    }
    Swal.fire({
      icon: "success",
      title: "Saved!",
      timer: 1000,
    }).then(() => {
      dispatch(push("/challenges"));
    });
  };
}

export function fetchOneChallenge(challenge_id) {
  return function (dispatch) {
    api.post(`/fetch-cms-challenge.php`,
      queryString.stringify({
        challenge_id: challenge_id,
      })
    )
      .then((response) => {
        dispatch({
          type: FETCH_ONE_CHALLENGE,
          payload: { [response.data.challenge_id]: response.data },
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function clearAllChallenges() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_CHALLENGES,
      payload: null,
    });
  };
}

export function addEditChallenges(options) {
  return function (dispatch) {
    api.post(`/add-n-edit-challenge.php`,
      queryString.stringify({
        challenge_json: options.challenge_json,
        session_ids_json: options.session_ids_json,
        intro_session_json: options.intro_session_json,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Saved!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/challenges"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteChallenges(challenge_id) {
  return function (dispatch) {
    api.post(`/delete-challenge.php`,
      queryString.stringify({
        challenge_id: challenge_id,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "challenge Deleted!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/challenges"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function sortChallenges(challenge_ids) {
  return function (dispatch) {
    api.post(`/update-challenge-order.php`,
      queryString.stringify({
        challenge_ids: challenge_ids,
      })
    )
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
