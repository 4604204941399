import { CLOSE_EDIT_CARD_DECK, CLOSE_EDIT_CARD_DECK_SUCCESS, DELETE_CARD_DECK, DELETE_CARD_DECK_ERROR, DELETE_CARD_DECK_SUCCESS, FETCH_CARD_DECKS, FETCH_CARD_DECKS_ERROR, FETCH_CARD_DECKS_SUCCESS, FETCH_ONE_CARD_DECK, FETCH_ONE_CARD_DECK_ERROR, FETCH_ONE_CARD_DECK_SUCCESS, SAVE_CARD_DECKS, SAVE_CARD_DECKS_ERROR, SAVE_CARD_DECKS_SUCCESS, SORT_CARD_DECKS, SORT_CARD_DECKS_ERROR, SORT_CARD_DECKS_SUCCESS } from "../constants/ActionTypes";



const initialState = {
    decks: [],
    error: null,
    allLoading: false,
    loading: false,
    deck: null
}


export default function cardDecksReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CARD_DECKS:
            return {
                ...state,
                allLoading: true
            }
        case SAVE_CARD_DECKS:
        case FETCH_ONE_CARD_DECK:
        case CLOSE_EDIT_CARD_DECK:
        case DELETE_CARD_DECK:
        case SORT_CARD_DECKS:
            return {
                ...state,
                loading: true
            }
        case FETCH_CARD_DECKS_SUCCESS:
            return {
                ...state,
                allLoading: false,
                decks: action.payload
            }
        case SAVE_CARD_DECKS_SUCCESS:
            return {
                ...state,
                deck: null,
                loading: false
            }
        case FETCH_ONE_CARD_DECK_SUCCESS:
            return {
                ...state,
                loading: false,
                deck: action.payload.data
            }
        case CLOSE_EDIT_CARD_DECK_SUCCESS:
            return {
                ...state,
                loading: false,
                deck: null
            }
        case DELETE_CARD_DECK_SUCCESS:
            return {
                ...state,
                loading: false,
                decks: state.decks.filter(deck => deck.id !== action.payload)
            }
        case SORT_CARD_DECKS_SUCCESS:
            return {
                ...state,
                loading: false,
                decks: action.payload
            }
        case FETCH_CARD_DECKS_ERROR:
        case SAVE_CARD_DECKS_ERROR:
        case FETCH_ONE_CARD_DECK_ERROR:
        case DELETE_CARD_DECK_ERROR:
        case SORT_CARD_DECKS_ERROR:
            return {
                ...state,
                loading: false,
                allLoading: false,
                error: action.payload
            }
        default:
            return state;
    }
}