import React from 'react';
import { useParams } from 'react-router-dom';
import AudioBookTable from '../../AudiobookTable/AudiobookTable';

const AudioBookPage = () => {
  const { id } = useParams();

  return (
    <AudioBookTable selectedId={id} />
  );
};

export default AudioBookPage;