import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import {
  fetchSearchDeeplinksInfo,
  saveSearchDeeplinksInfo,
} from "../../actions/searchDeeplinksActions";
import {
  uploadImage,
  clearMedia
} from "../../actions/mediaActions";
import ImageContainerPreview from "../Common/ImageContainerPreview";

const SearchDeeplinks = (props) => {
  const [searchDeeplinkImageURL, setSearchDeeplinkImageURL] = useState(props.search_deeplink_image_url || "");
  const [searchDeeplinkIOSURL, setSearchDeeplinkIOSURL] = useState(props.search_deeplink_ios_url || "");
  const [searchDeeplinkAndroidURL, setSearchDeeplinkAndroidURL] = useState(props.search_deeplink_android_url || "");
  const [deeplinkImageUploading, setDeeplinkImageUploading] = useState(false);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    props.fetchSearchDeeplinksInfo();
    props.clearMedia();
  }, []);

  useEffect(() => {
    setSearchDeeplinkIOSURL(props.search_deeplink_ios_url);
  }, [props.search_deeplink_ios_url]);

  useEffect(() => {
    setSearchDeeplinkAndroidURL(props.search_deeplink_android_url);
  }, [props.search_deeplink_android_url]);

  useEffect(() => {
    setSearchDeeplinkImageURL(props.search_deeplink_image_url);
  }, [props.search_deeplink_image_url]);

  useEffect(() => {
    if (props.image && deeplinkImageUploading) {
      setSearchDeeplinkImageURL(props.image);
      setDeeplinkImageUploading(false);
      setEdited(true);
      props.clearMedia();
    }
  }, [props.image, deeplinkImageUploading]);

  const handleDeepLinkImageUpload = (e) => {
    const file = e.target.files[0];
    setDeeplinkImageUploading(true);
    props.uploadImage(file, e.target.name);
  };

  const removeImage = (e) => {
    setSearchDeeplinkImageURL("");
    setEdited(true);
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    props.saveSearchDeeplinksInfo(searchDeeplinkImageURL, searchDeeplinkIOSURL, searchDeeplinkAndroidURL);
    setEdited(false);
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h2" gutterBottom>
          Search Results Deeplinks
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box display="flex" alignItems="center" mb={3}>
            <ImageContainerPreview
              title={"Deeplink Image"}
              image={searchDeeplinkImageURL}
              name="share_image_url"
              handleRemove={removeImage}
              handleImageUpload={handleDeepLinkImageUpload}
              showCopyUrlButton={true}
              sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
            />
          </Box>

          <Box mb={3}>
            <TextField
              fullWidth
              label="Deeplink iOS"
              variant="outlined"
              value={searchDeeplinkIOSURL}
              onChange={(e) => {
                setSearchDeeplinkIOSURL(e.target.value);
                setEdited(true);
              }}
            />
          </Box>

          <Box mb={3}>
            <TextField
              fullWidth
              label="Deeplink Android"
              variant="outlined"
              value={searchDeeplinkAndroidURL}
              onChange={(e) => {
                setSearchDeeplinkAndroidURL(e.target.value);
                setEdited(true);
              }}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button variant="contained" color="primary" type="submit" disabled={!edited}>
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

function mapStateToProps(state) {
  const deeplinks = state.search.deeplinks;
  return {
    search_deeplink_image_url: deeplinks ? deeplinks.deeplink_image_url : "",
    search_deeplink_ios_url: deeplinks ? deeplinks.deeplink_ios : "",
    search_deeplink_android_url: deeplinks ? deeplinks.deeplink_android : "",
    image: state.media ? state.media.image : "",
  };
}

const mapDispatchToProps = {
  saveSearchDeeplinksInfo,
  fetchSearchDeeplinksInfo,
  clearMedia,
  uploadImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchDeeplinks);
