import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import Swal from "sweetalert2";
import { fetchAllBGM } from "../../actions/backgroundMusicActions";
import { fetchAllChallenges } from "../../actions/challengeActions";
import { addObjectTag, removeObjectTag } from "../../actions/tagActions";
import { fetchAuthors } from "./../../actions/authorsActions";
import { fetchAllCategories } from "./../../actions/categoryActions";
import {
  clearMedia,
  uploadAudio,
  uploadImage,
} from "./../../actions/mediaActions";
import { fetchAllSeries } from "./../../actions/seriesActions";
import {
  addEditSession,
  deleteSession,
  fetchOneSession,
  clearSessions,
} from "./../../actions/sessionActions";
import { fetchAllSubCategories } from "./../../actions/subcategoryActions";
import { fetchTappingPoints } from "./../../actions/tappingPointsActions";
import HeaderBar from "./../HeaderBar/HeaderBar";
import MainContentContainer from "./../MainContentContainer/MainContentContainer";
import "./AddEditSession.css";
import BackgroundImageTimings from "./BackgroundImageTimings/BackgroundImageTimings";
import FormatTimestamp from "./FormatTimestamp/FormatTimestamp";

class AddEditSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      session_name: "",
      session_allow_rating: 0,
      session_text_page_url: "",
      session_text_page_ios_url: "",
      session_description: "",
      session_main_url: "",
      session_main_url_preview: "",
      session_main_url_uploading: false,
      session_music_url: "",
      session_music_url_preview: "",
      session_music_url_uploading: false,
      session_image: "",
      session_image_preview: "",
      session_image_uploading: false,
      session_text_image_url: "",
      session_text_image_url_preview: "",
      session_text_image_url_uploading: false,
      session_grayscale_image: "",
      session_grayscale_image_preview: "",
      session_grayscale_image_uploading: false,
      session_is_active: 1,
      session_is_chapter: 0,
      session_is_locked: 0,
      session_media_type: 1,
      session_rating_start: 0,
      session_rating_end: 0,
      no_avatar: 0,
      session_is_recommended: 0,
      session_is_default_recommended: 0,
      session_author_id: "",
      session_bg_timestamps: [{ tapping_point_id: "", timestamp: 0 }],
      associatedParent: "0",
      associatedSeries: "0",
      session_content_start_timestamp: 0,
      isSetToEdit: false,
      isSetToDuplicate: false,
      session_label: "",
      session_tags: [],
      session_tag: "",
      tags: [],
      tagsToRemove: [],
      session_length: "",
      session_length_in_sec: 0,
      session_is_new: 0,
      session_is_dark_mode: 0,
      is_bgm_locked: 0,
      session_bgm_id: null,
    };

    // Prevents two new timestamps created after one is finished
    this.shouldAddNextTimestamp = true;
  }

  componentDidMount() {
    this.props.fetchAllCategories(0);
    this.props.fetchAllSubCategories();
    this.props.fetchAllChallenges();
    this.props.fetchAuthors();
    this.props.fetchAllSeries();
    this.props.fetchTappingPoints();
    this.props.clearMedia();
    this.props.clearSessions();
    this.props.fetchAllBGM();
    if (this.props.isEdit) {
      this.props.fetchOneSession(this.props.selectedId);
      console.log("session_id", this.props.selectedId);
    }
    if (this.props.isDuplicate) {
      this.props.fetchOneSession(this.props.duplicateSessionId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.isEdit &&
      nextProps.all_sessions &&
      !this.state.isSetToEdit
    ) {
      this.setSessionForEditing(nextProps);
    }

    if (
      this.props.isDuplicate &&
      nextProps.all_sessions &&
      !this.state.isSetToDuplicate
    ) {
      this.setSessionForDuplicate(nextProps);
    }

    if (nextProps.image) {
      if (this.state.session_image_uploading) {
        this.setState({
          session_image: nextProps.image,
          session_image_uploading: false,
        });
        this.props.clearMedia();
      }
      if (this.state.session_grayscale_image_uploading) {
        this.setState({
          session_grayscale_image: nextProps.image,
          session_grayscale_image_uploading: false,
        });
        this.props.clearMedia();
      }
      if (this.state.session_text_image_url_uploading) {
        this.setState({
          session_text_image_url: nextProps.image,
          session_text_image_url_uploading: false,
        });
        this.props.clearMedia();
      }
    }

    if (nextProps.audio && !this.props.audio) {
      if (this.state.session_main_url_uploading) {
        const audio = nextProps.audio;
        this.setState({
          session_main_url_uploading: false,
          session_main_url: audio,
        });
        this.props.clearMedia();
      }

      if (this.state.session_music_url_uploading) {
        const audio = nextProps.audio;
        this.setState({
          session_music_url_uploading: false,
          session_music_url: audio,
        });
        this.props.clearMedia();
      }
    }
  }

  componentWillUnmount() {
    if (this.state.session_image_preview) {
      window.URL.revokeObjectURL(this.state.session_image_preview);
    }
    if (this.state.session_grayscale_image_preview) {
      window.URL.revokeObjectURL(this.state.session_grayscale_image_preview);
    }
    if (this.state.session_text_image_url_preview) {
      window.URL.revokeObjectURL(this.state.session_text_image_url_preview);
    }
    if (this.state.session_main_url_preview) {
      window.URL.revokeObjectURL(this.state.session_main_url_preview);
    }
    if (this.state.session_music_url_preview) {
      window.URL.revokeObjectURL(this.state.session_music_url_preview);
    }
    this.props.clearMedia();
  }

  setSessionForEditing = ({ all_sessions }) => {
    let currentSession = null;
    for (let x = 0; x < all_sessions.length; x++) {
      if (all_sessions[x].session_id === Number(this.props.selectedId)) {
        currentSession = all_sessions[x];
        // currentSession.session_author =
        //   all_sessions[x].session_author.author_id;
        break;
      }
    }
    if (!currentSession) {
      Swal.fire({ icon: "error", text: "Error finding selected session to edit" });
    }
    if (currentSession.category_id !== 0) {
      currentSession.associatedParent = currentSession.category_id + "CAT";
    } else if (currentSession.subcategory_id !== 0) {
      currentSession.associatedParent = currentSession.subcategory_id + "SUB";
    }
    if (currentSession.series_id !== 0) {
      currentSession.associatedParent = currentSession.series_id + "SER";
    }

    if (currentSession.challenge_id) {
      currentSession.associatedParent = currentSession.challenge_id + "CHA";
    }

    this.setState({ ...currentSession, isSetToEdit: true });
  };

  setSessionForDuplicate = ({ all_sessions }) => {
    let currentSession = null;
    for (let x = 0; x < all_sessions.length; x++) {
      if (
        all_sessions[x].session_id === Number(this.props.duplicateSessionId)
      ) {
        currentSession = all_sessions[x];
        currentSession.session_author =
          all_sessions[x].session_author.author_id;
        break;
      }
    }
    if (!currentSession) {
      Swal.fire({ icon: "error", text: "Error finding selected session to edit" });
    }
    if (currentSession.category_id !== 0) {
      currentSession.associatedParent = currentSession.category_id + "CAT";
    } else if (currentSession.subcategory_id !== 0) {
      currentSession.associatedParent = currentSession.subcategory_id + "SUB";
    }
    if (currentSession.series_id !== 0) {
      currentSession.associatedParent = currentSession.series_id + "SER";
    }

    if (currentSession.challenge_id !== 0) {
      currentSession.associatedParent = currentSession.challenge_id + "CHA";
    }

    this.setState({ ...currentSession, isSetToDuplicate: true });
  };

  handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  clickParent = (e) => {
    e.target.parentNode.click();
  };

  handleSelectChange = (e) => {
    if (!e) {
      return false;
    }

    const { value, prop, session_music_url } = e;

    if (session_music_url) {
      this.setState({ session_music_url: session_music_url });
    }

    this.setState((prevState) => {
      const state = { ...prevState };
      state[prop] = value;
      return state;
    });
  };

  renderAuthors = () => {
    return this.props.authors.map((obj) => {
      return {
        label: obj.author_name,
        value: obj.author_id,
        prop: "session_author_id",
      };
    });
  };

  handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.session_image_preview) {
      window.URL.revokeObjectURL(this.state.session_image_preview);
    }
    this.setState({
      session_image_preview: window.URL.createObjectURL(file),
      session_image_uploading: true,
    });
    this.props.uploadImage(file);
  };

  handleDeepLinkImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.session_text_image_url_preview) {
      window.URL.revokeObjectURL(this.state.session_text_image_url_preview);
    }
    this.setState({
      session_text_image_url_preview: window.URL.createObjectURL(file),
      session_text_image_url_uploading: true,
    });
    this.props.uploadImage(file);
  };

  handleGrayscaleImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.session_grayscale_image_preview) {
      window.URL.revokeObjectURL(this.state.session_grayscale_image_preview);
    }
    this.setState({
      session_grayscale_image_preview: window.URL.createObjectURL(file),
      session_grayscale_image_uploading: true,
    });
    this.props.uploadImage(file);
  };

  handleCheckBox = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    console.log("name", name);
    this.setState({
      [name]: value === true ? 1 : 0,
    });
  };

  handleMainMediaUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.session_main_url_preview) {
      window.URL.revokeObjectURL(this.state.session_main_url_preview);
    }
    this.setState({
      session_main_url_preview: window.URL.createObjectURL(file),
      session_main_url_uploading: true,
    });
    const objectURL = window.URL.createObjectURL(file);
    const mySound = new Audio([objectURL]);
    mySound.addEventListener(
      "canplaythrough",
      () => {
        URL.revokeObjectURL(objectURL);
        const minutes = Math.floor(mySound.duration / 60);
        const seconds = Math.round(mySound.duration - minutes * 60);
        this.setState({
          session_length: `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`,
          session_length_in_sec: Math.round(mySound.duration),
        });
      },
      false
    );
    this.props.uploadAudio(file);
  };

  handleBGMediaUpload = (e) => {
    e.preventDefault();
    return Swal.fire({
      icon: "error",
      title: "BGM Upload Error",
      text: "Please upload directly from Background Music in Background Music Section",
    });
  };

  renderParentOptions = (i) => {
    const categories = this.props.all_categories.map((obj) => ({
      label: obj.category_title + " - Category",
      value: obj.category_id + "CAT",
      prop: "associatedParent",
    }));

    const challenges = this.props.challenges.map((obj) => ({
      label: obj.challenge_title + " - Challenge",
      value: obj.challenge_id + "CHA",
      prop: "associatedParent",
    }));

    const subcategories = this.props.subcategories.map((obj) => {
      let label = obj.subcategory_title;
      if (obj.category) {
        label += ` (${obj.category.category_title})`;
      }
      if (obj.subcategory_is_book === 1) {
        label += " - AudioBook";
      } else {
        label += " - Subcategory";
      }
      return {
        label: label,
        value: obj.subcategory_id + "SUB",
        prop: "associatedParent",
      };
    });

    let series = [];
    if (this.props.series) {
      series = this.props.series.map((obj) => ({
        label: obj.series_title + " - Series",
        value: obj.series_id + "SER",
        prop: "associatedParent",
      }));
    }

    const none = { label: "none", value: "0", prop: "associatedParent" };

    return [none, ...challenges, ...categories, ...subcategories, ...series];
  };

  renderBGMOptions = () => {
    const background_musics = this.props.background_musics.map((obj) => ({
      label: obj.bgm_title,
      value: obj.bgm_id,
      prop: "session_bgm_id",
      session_music_url: obj.bgm_url,
    }));

    const none = {
      label: "none",
      value: "0",
      prop: "session_bgm_id",
      session_music_url: "",
    };

    return [none, ...background_musics];
  };
  renderSeriesOptions = (i) => {
    const series = this.props.series.map((obj) => ({
      label: obj.series_title + " - Series",
      value: obj.series_id + "SER",
      prop: "associatedSeries",
    }));

    const none = { label: "none", value: "0", prop: "associatedSeries" };

    return [none, ...series];
  };

  addTimestamp = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState((prevState) => {
      const state = { ...prevState };
      state.session_bg_timestamps.push({
        tapping_point_id: "",
        timestamp: 0,
      });
      this.shouldAddNextTimestamp = true;
      return state;
    });
  };

  handleTimestampChange = (newValue, stateProp, indexToChange, objProp) => {
    this.setState(
      (prevState) => {
        const state = { ...prevState };
        if (indexToChange === undefined) {
          state[stateProp] = newValue;
        } else {
          state[stateProp][indexToChange][objProp] = newValue;
        }
        return state;
      },
      () => {
        if (
          this.shouldAddNextTimestamp &&
          stateProp === "session_bg_timestamps" &&
          indexToChange === this.state[stateProp].length - 1 &&
          this.state[stateProp][indexToChange].tapping_point_id &&
          this.state[stateProp][indexToChange].timestamp
        ) {
          this.shouldAddNextTimestamp = false;
          this.addTimestamp();
        }
      }
    );
  };

  removeTimestamp = (index) => {
    this.setState((prevState) => {
      const state = { ...prevState };
      state.session_bg_timestamps.splice(index, 1);
      return state;
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Saving...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    if (this.state.session_main_url === "") {
      Swal.fire({
        icon: "error",
        text: "You must upload an Audio file for this session.",
      });
      return false;
    }

    if (this.state.session_author_id === "") {
      Swal.fire({
        icon: "error",
        text: "You must select an Author for this session.",
      });
      return false;
    }

    const audio = document.querySelector("#session_main_url");
    if (this.state.session_rating_start > this.state.session_rating_end) {
      Swal.fire({
        icon: "error",
        text: "End Rating Timestamp cannot be before Start Rating Timestamp",
      });
      return false;
    }

    if (audio && audio.duration < this.state.session_rating_end) {
      Swal.fire({
        icon: "error",
        text: "End Rating Timestamp cannot be greater than the audio length",
      });
      return false;
    }

    let { session_bg_timestamps: timestamps_json, ...session_json } =
      this.state;

    timestamps_json = timestamps_json.filter(
      (obj) => obj.tapping_point_id !== ""
    );
    const times = timestamps_json.map((obj) => obj.timestamp);
    const uniqueTimes = [...new Set([...times])];
    if (times.length !== uniqueTimes.length) {
      Swal.fire({
        icon: "error",
        text: "Cannot have two tapping points at same time.",
      });
      return false;
    }

    let category_id = 0;
    let subcategory_id = 0;
    let series_id = 0;
    let challenge_id = 0;

    if (this.props.isDuplicate) {
      session_json.session_id = 0;
      session_json.session_is_duplicate = 1;
    }
    if (!session_json.session_bgm_id) {
      session_json.session_bgm_id = null;
    }

    if (this.state.associatedParent.indexOf("CAT") > -1) {
      category_id = parseInt(this.state.associatedParent, 10);
    } else if (this.state.associatedParent.indexOf("SUB") > -1) {
      subcategory_id = parseInt(this.state.associatedParent, 10);
    } else if (this.state.associatedParent.indexOf("SER") > -1) {
      series_id = parseInt(this.state.associatedParent, 10);
    } else if (this.state.associatedParent.indexOf("CHA") > -1) {
      challenge_id = parseInt(this.state.associatedParent, 10);
    }

    // if (this.state.associatedParent.indexOf("SER") > -1) {
    //   series_id = parseInt(this.state.associatedSeries, 10);
    // }

    // console.log("options: ", parseInt(this.state.associatedSeries, 10));
    const options = {
      session_json: JSON.stringify(session_json),
      timestamps_json: JSON.stringify(timestamps_json),
      category_id,
      subcategory_id,
      series_id,
      challenge_id,
    };
    console.log("challenge_id", challenge_id, options);
    this.props.addEditSession(options);
    //remove or add tags
    this.state.tags.forEach((tag) => {
      tag.newTag && tag.newTag === true && this.addTags(tag.tag);
    });
    this.state.tagsToRemove.length > 0 &&
      this.state.tagsToRemove.forEach((tags) => {
        this.deleteTags(tags.tag);
      });
  };
  addTags = (tag) => {
    const { addObjectTag } = this.props;
    const options = {
      object_id: this.state.session_id,
      tag: tag,
      object_type: "Session",
    };
    this.setState({
      session_tag: "",
    });
    console.log("TAGS OPTIONS", options);
    addObjectTag(options);
  };
  deleteTags = (tag) => {
    const options = {
      object_id: this.state.session_id,
      tag: tag,
      object_type: "Session",
    };
    console.log("delete tag option", options);
    this.props.removeObjectTag(options);
    const newTags = this.state.tags.filter((stateTag) => stateTag.tag !== tag);
    console.log("newTags", newTags);
    this.setState({
      tags: newTags,
      tagsToRemove: [],
    });
  };
  //Due to BE add temp states for tags to add and remove
  addToStateTag = (tag) => {
    const options = {
      tag: tag,
      newTag: true,
    };
    this.setState({
      session_tag: "",
      tags: [...this.state.tags, options],
    });
  };
  removeFromStateTag = (tag) => {
    const tagToRemove = this.state.tags.filter(
      (stateTag) => stateTag.tag === tag
    )[0];
    const newTags = this.state.tags.filter((stateTag) => stateTag.tag !== tag);
    console.log("newTags", newTags);
    this.setState({
      tags: newTags,
      tagsToRemove: [...this.state.tagsToRemove, tagToRemove],
    });
  };

  handleDelete = (e) => {
    e.preventDefault();
    if (this.state.session_is_active === 1) {
      return Swal.fire({
        icon: "error",
        text: "Can't archive selected session, please update session to inactive status to archive session",
      });
    }
    Swal.fire({
      icon: "warning",
      text: "Are you sure you want to archive this session?",
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Archive Session",
    }).then((result) => {
      if (result.value) {
        this.props.deleteSession(this.state.session_id);
      }
    });
  };

  render() {
    const { isEdit, all_sessions, subcategories, all_categories, authors } =
      this.props;

    if (!all_categories || !authors || !subcategories) {
      return <h3>Loading...</h3>;
    }
    console.log(this.state);

    return (
      <form
        className="AddEditSession"
        onSubmit={this.handleSubmit}
        autoComplete="off"
      >
        <HeaderBar
          header={
            isEdit
              ? "Edit Session"
              : this.props.isDuplicate
                ? "Create Duplicate Session"
                : "Create New Session"
          }
        >
          {this.props.isEdit ? (
            <Link
              to={`/sessions/duplicate/${this.state.session_id}`}
              className="utility-btn-transparent utility-lr-margin-small"
            >
              {" "}
              Duplicate{" "}
            </Link>
          ) : null}
          <Link
            to="/sessions"
            className="utility-btn-transparent utility-lr-margin-small"
          >
            Cancel
          </Link>
          <button className="utility-btn-primary">
            {isEdit ? "Update" : "Create"}
          </button>
        </HeaderBar>
        <MainContentContainer subContent={true}>
          <div className="utility-flex-row">
            <div>
              <div className="utility-flex-row">
                <label className="utility-inline-label">
                  <p>Session Name</p>
                  <input
                    type="text"
                    className="utility-text-input"
                    name="session_name"
                    value={this.state.session_name}
                    onChange={this.handleFieldChange}
                    required
                  />
                </label>
                <label className="utility-inline-label">
                  <p>Status</p>
                  <Select
                    name="form-field-name"
                    value={this.state.session_is_active}
                    onChange={this.handleSelectChange}
                    style={{ width: "170px" }}
                    clearable={undefined}
                    searchable={undefined}
                    options={[
                      { value: 1, label: "Active", prop: "session_is_active" },
                      {
                        value: 0,
                        label: "Not Active",
                        prop: "session_is_active",
                      },
                    ]}
                  />
                </label>
                <label className="utility-inline-label">
                  <p>Show Rating</p>
                  <input
                    name="session_allow_rating"
                    type="checkbox"
                    value={this.state.session_allow_rating === 0 ? false : true}
                    onChange={this.handleCheckBox}
                    checked={
                      this.state.session_allow_rating === 0 ? false : true
                    }
                    style={{ width: "150px" }}
                    clearable={undefined}
                    searchable={undefined}
                  />
                </label>

                <label className="utility-inline-label">
                  <p>Is New</p>
                  <input
                    name="session_is_new"
                    type="checkbox"
                    value={this.state.session_is_new === 0 ? false : true}
                    onChange={this.handleCheckBox}
                    checked={this.state.session_is_new === 0 ? false : true}
                    style={{ width: "80px" }}
                    clearable={undefined}
                    searchable={undefined}
                  />
                </label>

                <label className="utility-inline-label">
                  <p>Dark mode</p>
                  <input
                    name="session_is_dark_mode"
                    type="checkbox"
                    value={this.state.session_is_dark_mode === 0 ? false : true}
                    onChange={this.handleCheckBox}
                    checked={
                      this.state.session_is_dark_mode === 0 ? false : true
                    }
                    style={{ width: "80px" }}
                    clearable={undefined}
                    searchable={undefined}
                  />
                </label>

                <label className="utility-inline-label">
                  <p>No Avatar</p>
                  <input
                    name="no_avatar"
                    type="checkbox"
                    value={this.state.no_avatar === 0 ? false : true}
                    onChange={this.handleCheckBox}
                    checked={this.state.no_avatar === 0 ? false : true}
                    style={{ width: "80px" }}
                    clearable={undefined}
                    searchable={undefined}
                  />
                </label>

                {/* <label className="utility-inline-label">
                  <p>Session Label</p>
                  <input
                    type="text"
                    className="utility-text-input"
                    name="session_label"
                    value={this.state.session_label}
                    onChange={this.handleFieldChange}
                  />
                </label> */}
              </div>
            </div>
            <div className="utility-inline-label">
              <p>Image</p>
              <div className="utility-flex-row">
                {(this.state.session_image_preview ||
                  this.state.session_image) && (
                    <img
                      src={
                        this.state.session_image_preview ||
                        this.state.session_image
                      }
                      alt="Profile"
                      style={{
                        maxHeight: "124px",
                        maxWidth: "150px",
                        marginRight: "20px",
                      }}
                    />
                  )}
                <label>
                  <input
                    type="file"
                    onChange={this.handleImageUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Image
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Author</p>
              <Select
                name="session_author_id"
                value={this.state.session_author_id}
                onChange={this.handleSelectChange}
                style={{ width: "259px" }}
                clearable={false}
                searchable={true}
                options={this.renderAuthors()}
              />
            </label>
            <label className="utility-inline-label">
              <p>Subscription Requirement</p>
              <Select
                name="form-field-name"
                value={this.state.session_is_locked}
                onChange={this.handleSelectChange}
                style={{ width: "170px" }}
                clearable={false}
                searchable={false}
                options={[
                  { value: 1, label: "Yes", prop: "session_is_locked" },
                  { value: 0, label: "No", prop: "session_is_locked" },
                ]}
              />
            </label>
            <label className="utility-inline-label">
              <p>Recommended</p>
              <Select
                name="form-field-name"
                value={this.state.session_is_recommended}
                onChange={this.handleSelectChange}
                style={{ width: "170px" }}
                clearable={false}
                searchable={false}
                options={[
                  {
                    value: 1,
                    label: "Yes",
                    prop: "session_is_recommended",
                  },
                  { value: 0, label: "No", prop: "session_is_recommended" },
                ]}
              />
            </label>
            <label className="utility-inline-label">
              <p>Default Recommended</p>
              <Select
                name="form-field-name"
                value={this.state.session_is_default_recommended}
                onChange={this.handleSelectChange}
                style={{ width: "170px" }}
                clearable={false}
                searchable={false}
                options={[
                  {
                    value: 1,
                    label: "Yes",
                    prop: "session_is_default_recommended",
                  },
                  {
                    value: 0,
                    label: "No",
                    prop: "session_is_default_recommended",
                  },
                ]}
              />
            </label>
            <label className="utility-inline-label">
              <p>Is Chapter</p>
              <Select
                name="form-field-name"
                value={this.state.session_is_chapter}
                onChange={this.handleSelectChange}
                style={{ width: "170px" }}
                clearable={false}
                searchable={false}
                options={[
                  {
                    value: 1,
                    label: "Yes",
                    prop: "session_is_chapter",
                  },
                  { value: 0, label: "No", prop: "session_is_chapter" },
                ]}
              />
            </label>
          </div>

          <div>
            <div className="utility-flex-row" />
            <label className="utility-inline-label">
              <p>Deep Link URL (Android)</p>
              <input
                type="text"
                className="utility-text-input"
                name="session_text_page_url"
                value={this.state.session_text_page_url}
                onChange={this.handleFieldChange}
              // required
              // style={{ width: 700 }}
              />
            </label>
            <label className="utility-inline-label">
              <p>Deep Link URL (iOS)</p>
              <input
                type="text"
                className="utility-text-input"
                name="session_text_page_ios_url"
                value={this.state.session_text_page_ios_url}
                onChange={this.handleFieldChange}
              // required
              // style={{ width: 700 }}
              />
            </label>
            <div className="utility-inline-label">
              <p>Deep Link Image</p>
              <div className="utility-flex-row">
                {(this.state.session_text_image_url_preview ||
                  this.state.session_text_image_url) && (
                    <img
                      src={
                        this.state.session_text_image_url_preview ||
                        this.state.session_text_image_url
                      }
                      alt="Profile"
                      style={{
                        maxHeight: "124px",
                        maxWidth: "150px",
                        marginRight: "20px",
                      }}
                    />
                  )}
                <label>
                  <input
                    type="file"
                    onChange={this.handleDeepLinkImageUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Image
                  </p>
                </label>
              </div>
            </div>
            {/* <div className="utility-inline-label">
              <p>Deep Link Image (iOS)</p>
              <div className="utility-flex-row">
                {(this.state.session_text_image_url_preview ||
                  this.state.session_text_image_url) && (
                  <img
                    src={
                      this.state.session_text_image_url_preview ||
                      this.state.session_text_image_url
                    }
                    alt="Profile"
                    style={{
                      maxHeight: "124px",
                      maxWidth: "150px",
                      marginRight: "20px"
                    }}
                  />
                )}
                <label>
                  <input
                    type="file"
                    onChange={this.handleDeepLinkImageUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Image
                  </p>
                </label>
              </div>
            </div> */}
          </div>
          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Description</p>
              <textarea
                className="utility-textarea"
                name="session_description"
                value={this.state.session_description}
                onChange={this.handleFieldChange}
                style={{ width: "640px" }}
                required
              />
            </label>
          </div>
          {isEdit && (
            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <p>Tags</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "5px",
                    flexWrap: "wrap",
                  }}
                >
                  {this.state.tags &&
                    this.state.tags.map((tag) => {
                      return (
                        <div
                          key={tag.id}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "2px 2px",
                          }}
                        >
                          <p>•</p>
                          <p
                            style={{
                              display: "inline-block",
                              borderRadius: "3px",
                              fontSize: "18px",
                            }}
                          >
                            {tag.tag}
                          </p>
                          <p
                            className="utility-btn-danger"
                            onClick={() => this.removeFromStateTag(tag.tag)}
                          >
                            X
                          </p>
                        </div>
                      );
                    })}
                </div>
              </label>
            </div>
          )}
          {isEdit && (
            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <input
                  type="text"
                  className="utility-text-input"
                  name="session_tag"
                  value={this.state.session_tag}
                  onChange={this.handleFieldChange}
                />
                <p
                  className="utility-btn-primary"
                  style={{ color: "#fff" }}
                  onClick={() => this.addToStateTag(this.state.session_tag)}
                >
                  Upload tag
                </p>
              </label>
            </div>
          )}
          <div className="utility-flex-row">
            <label style={{ marginRight: "20px", width: "300px" }}>
              <p
                style={{
                  fontSize: "14px",
                  margin: "30px 0 -11px",
                }}
              >
                {this.state.session_media_type === 1
                  ? "MP3 Audio File"
                  : "MP4 Video File"}
              </p>
              <input
                type="file"
                onChange={this.handleMainMediaUpload}
                style={{ display: "none" }}
                accept={this.state.session_media_type === 1 ? ".mp3" : ".mp4"}
              />
              <p
                className="utility-btn-primary"
                style={{ color: "#fff" }}
                tabIndex="0"
                onKeyPress={this.clickParent}
              >
                Upload {this.state.session_media_type === 1 ? "Audio" : "Video"}
              </p>
            </label>
          </div>

          <div className="utility-flex-row">
            <audio
              id="session_main_url"
              src={
                this.state.session_main_url_preview ||
                this.state.session_main_url
              }
              controls
              style={{ width: "300px" }}
            />
          </div>

          <div className="utility-flex-row">
            <label style={{ marginRight: "20px", width: "300px" }}>
              <p
                style={{
                  fontSize: "14px",
                  margin: "30px 0 -11px",
                }}
              >
                Background Music
              </p>
            </label>
          </div>

          <div className="utility-flex-row">
            <div
              style={{ margin: "30px 0", alignItems: "center" }}
              className="utility-inline-label"
            >
              <p>Background Music Option</p>
              <Select
                value={this.state.session_bgm_id}
                onChange={this.handleSelectChange}
                style={{ width: "500px" }}
                clearable={false}
                searchable={true}
                options={this.renderBGMOptions()}
              />
            </div>
            <label className="utility-inline-label">
              <p>Lock Background Music </p>
              <input
                name="is_bgm_locked"
                type="checkbox"
                value={this.state.is_bgm_locked === 0 ? false : true}
                onChange={this.handleCheckBox}
                checked={this.state.is_bgm_locked === 0 ? false : true}
                style={{ width: "80px" }}
                clearable={undefined}
                searchable={undefined}
              />
            </label>
          </div>

          <div className="utility-flex-row">
            <audio
              id="session_music_url"
              src={
                this.state.session_music_url_preview ||
                this.state.session_music_url
              }
              controls
              style={{ width: "300px" }}
            />
          </div>

          <div
            style={{ margin: "60px 0px 30px", alignItems: "center" }}
            className="utility-inline-label"
          >
            <p>Parent Category or Subcategory</p>
            <Select
              value={this.state.associatedParent}
              onChange={this.handleSelectChange}
              style={{ width: "850px" }}
              clearable={false}
              searchable={true}
              options={this.renderParentOptions()}
            />
          </div>

          <div className="utility-flex-row">
            <div className="utility-inline-label">
              <p>Session Content Start</p>
              <FormatTimestamp
                value={this.state.session_content_start_timestamp}
                handleChange={this.handleTimestampChange}
                stateProp="session_content_start_timestamp"
              />
            </div>
          </div>

          <div className="utility-flex-row">
            <div className="utility-inline-label">
              <p>Start Rating Timestamps</p>
              <FormatTimestamp
                value={this.state.session_rating_start}
                handleChange={this.handleTimestampChange}
                stateProp="session_rating_start"
              />
            </div>

            <div className="utility-inline-label">
              <p>End Rating Timestamps</p>
              <FormatTimestamp
                value={this.state.session_rating_end}
                handleChange={this.handleTimestampChange}
                stateProp="session_rating_end"
              />
            </div>
          </div>

          <div className="AddEditSession-background-images">
            <h4>Background Image Timings</h4>
            <p>Timestamp</p>
            <BackgroundImageTimings
              items={this.state.session_bg_timestamps}
              removeTimestamp={this.removeTimestamp}
              stateProp="session_bg_timestamps"
              handleTimestampChange={this.handleTimestampChange}
              tappingPoints={this.props.tapping_points}
            />
            <button
              className="utility-btn-primary"
              onClick={this.addTimestamp}
              style={{ margin: "20px 0" }}
            >
              Add Timestamp
            </button>
          </div>

          {isEdit && (
            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <button
                className="utility-btn-danger"
                onClick={this.handleDelete}
              >
                Archive Session
              </button>
            </div>
          )}
        </MainContentContainer>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    all_categories: state.categories.all_categories,
    subcategories: state.subcategories.subcategories
      ? Object.values(state.subcategories.subcategories)
      : [],
    all_sessions: state.sessions.all_sessions,
    challenges: state.challenges.challenges
      ? Object.values(state.challenges.challenges)
      : [],
    series: state.series.all_series,
    authors: state.authors.authors,
    tapping_points: state.tapping_points.tapping_points,
    image: state.media.image,
    audio: state.media.audio,
    currentTags: state.sessions.current_tags,
    is_session_duplicate: state.sessions.is_duplicate,
    background_musics: state.background_musics.background_musics,
  };
}

const mapDispatchToProps = {
  fetchAllCategories,
  fetchAllSubCategories,
  fetchTappingPoints,
  fetchOneSession,
  fetchAuthors,
  addEditSession,
  deleteSession,
  clearMedia,
  uploadImage,
  uploadAudio,
  addObjectTag,
  removeObjectTag,
  fetchAllSeries,
  clearSessions,
  fetchAllChallenges,
  fetchAllBGM,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSession);
