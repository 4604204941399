import React from 'react';
import { DndContext, closestCenter, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { MdReorder, MdDelete } from 'react-icons/md';
import { CSS } from '@dnd-kit/utilities';
import { Box, TextField, IconButton } from '@mui/material';

// Drag Handle equivalent for dnd-kit using MUI
const DragHandle = (props) => (
  <Box display="flex" alignItems="center" {...props.listeners} {...props.attributes}>
    <MdReorder style={{ cursor: 'grab' }} />
  </Box>
);

// Sortable item component using dnd-kit and MUI
const TopSearchSortableItem = ({ value, el_index, handleChange, onDelete }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: value.top_search_id }); // Using top_search_id instead of id

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: "flex",
    alignItems: "center",
    padding: "10px 0",
  };

  return (
    <Box ref={setNodeRef} sx={style} {...attributes}>
      {/* Drag Handle */}
      <DragHandle listeners={listeners} attributes={attributes} />

      {/* TextField for Input */}
      <TextField
        value={value.value}
        onChange={(e) => handleChange(e, el_index)}
        placeholder="Top Search Text"
        variant="outlined"
        size="small"
        fullWidth
        sx={{ ml: 2 }}
        required
      />

      {/* Delete Button */}
      <IconButton
        onClick={(e) => onDelete(e, value.top_search_id, el_index)}
        color="error"
        sx={{ ml: 2 }}
      >
        <MdDelete />
      </IconButton>
    </Box>
  );
};

// List of Sortable Items
const TopSearchSortableList = ({ items, onDelete, handleChange }) => {
  if (!items || items.length === 0) {
    return <Typography>Please Add Items</Typography>;
  }

  return (
    <Box>
      {items.map((value, index) => (
        <TopSearchSortableItem
          key={`topsearch-${value.top_search_id}`} // Using top_search_id in key
          value={value}
          el_index={index}
          handleChange={handleChange}
          onDelete={onDelete}
        />
      ))}
    </Box>
  );
};

// Main Sortable Component
const TopSearchSortable = ({ items, setItems, onDelete, handleChange }) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.top_search_id === active.id); // Using top_search_id
      const newIndex = items.findIndex((item) => item.top_search_id === over.id); // Using top_search_id
      setItems(arrayMove(items, oldIndex, newIndex));
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items.map((item) => item.top_search_id)} strategy={verticalListSortingStrategy}> {/* Using top_search_id */}
        <TopSearchSortableList items={items} onDelete={onDelete} handleChange={handleChange} />
      </SortableContext>
    </DndContext>
  );
};

export default TopSearchSortable;
