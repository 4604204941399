import { FETCH_ALL_GENERAL_DATA } from "../constants/ActionTypes";

const INITIAL_STATE = { general_data: null };

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_GENERAL_DATA:
      console.log("action.payload: ", action.payload);
      return { ...state, general_data: action.payload };
    default:
      return state;
  }
}
