import {
  API_ERROR,
  DELETE_SUGGESTED_SEARCH,
  FETCH_SUGGESTED_SEARCH,
  FETCH_SUGGESTED_SEARCH_OPTIONS,
} from "../constants/ActionTypes";
import { api, errorHandler, queryString } from "./index";
import Swal from "sweetalert2";

export function fetchAvailableOption() {
  return function (dispatch) {
    api.post(`/fetch-suggested-search-options.php`)
      .then((response) => {
        dispatch({
          type: FETCH_SUGGESTED_SEARCH_OPTIONS,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function fetchSuggestedSearches() {
  return function (dispatch) {
    api.post(`/fetch-suggested-searches.php`)
      .then((response) => {
        dispatch({
          type: FETCH_SUGGESTED_SEARCH,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteSuggesteedSearch(suggested_search_id) {
  return function (dispatch) {
    api.post(`/delete-one-suggested-search.php`,
      queryString.stringify({
        suggested_search_id: suggested_search_id,
      })
    )
      .then((response) => {
        dispatch({
          type: DELETE_SUGGESTED_SEARCH,
          payload: suggested_search_id,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addOrEditSuggestedSearch(suggested_search_data) {
  return function (dispatch) {
    api.post(`/add-n-edit-suggested-searches.php`,
      queryString.stringify({
        suggested_search_data: suggested_search_data,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Suggested Search Updated!",
          timer: 1000,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
