import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderBar from "./../HeaderBar/HeaderBar";
import MainContentContainer from "./../MainContentContainer/MainContentContainer";
import {
  fetchAuthors,
  clearAuthors,
  addEditAuthor,
  deleteAuthor,
} from "./../../actions/authorsActions";
import { uploadImage, clearMedia } from "./../../actions/mediaActions";
import Swal from "sweetalert2";
import AddEditAuthorTextEditor from "./AddEditAuthorTextEditor/AddEditAuthorTextEditor";

import "./AddEditAuthor.css";

class AddEditAuthor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      author_name: "",
      author_title: "",
      author_image: "",
      author_image_uploading: false,
      author_signature: "",
      author_signature_preview: null,
      author_signature_uploading: false,
      author_bio:
        '<p style="font-family: Asap-Regular; font-size: 16px; color: #545454;"><br></p>',
      author_facebook_url: "",
      author_twitter_url: "",
      author_instagram_url: "",
      author_image_preview: null,
      author_is_active: 1,
      isSetToEdit: false,
    };
  }

  componentDidMount() {
    this.props.clearAuthors();
    this.props.clearMedia();
    if (this.props.isEdit) {
      this.props.fetchAuthors();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.author_signature_uploading) {
      this.setState({
        author_signature: nextProps.image,
        author_signature_uploading: false,
      });
      this.props.clearMedia();
    }

    if (this.state.author_image_uploading) {
      this.setState({
        author_image: nextProps.image,
        author_image_uploading: false,
      });
      this.props.clearMedia();
    }
    if (this.props.isEdit && nextProps.authors && !this.state.isSetToEdit) {
      let currentAuthor = null;
      for (let x = 0; x < nextProps.authors.length; x++) {
        if (nextProps.authors[x].author_id === Number(this.props.selectedId)) {
          currentAuthor = nextProps.authors[x];
          break;
        }
      }
      if (currentAuthor === null) {
        Swal.fire({
          icon: "warning",
          text: "Error finding selected Author",
        });
      }

      this.setState({ ...currentAuthor, isSetToEdit: true });
    }
  }

  componentWillUnmount() {
    if (this.state.author_image_preview) {
      window.URL.revokeObjectURL(this.state.author_image_preview);
    }
    if (this.state.author_signature_preview) {
      window.URL.revokeObjectURL(this.state.author_signature_preview);
    }
  }

  handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  bioChange = (text) => {
    this.setState({ author_bio: text });
  };

  handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!this.state.author_image_preview) {
      console.log("handle upload for image", this.state);
      this.setState({
        author_image_preview: window.URL.createObjectURL(file),
        author_image_uploading: true,
      });
    } else {
      window.URL.revokeObjectURL(this.state.author_image_preview);
      this.setState({
        author_image_preview: window.URL.createObjectURL(file),
        author_image_uploading: true,
      });
    }
    this.props.uploadImage(file);
  };

  handleSignatureUpload = (e) => {
    const file = e.target.files[0];
    if (!this.state.author_signature_preview) {
      this.setState({
        author_signature_preview: window.URL.createObjectURL(file),
        author_signature_uploading: true,
      });
    } else {
      window.URL.revokeObjectURL(this.state.author_signature_preview);
      this.setState({
        author_signature_preview: window.URL.createObjectURL(file),
        author_signature_uploading: true,
      });
    }
    this.props.uploadImage(file);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let data = { ...this.state };
    console.log("author data", data);
    data = JSON.stringify(data);
    this.props.addEditAuthor(data);
  };

  handleDelete = (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "warning",
      text: "Are you sure you want to delete this Author?",
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.value) {
        this.props.deleteAuthor(this.props.selectedId);
      }
    });
  };

  render() {
    const { isEdit } = this.props;

    if (isEdit && !this.props.authors) {
      return <h1>Loading...</h1>;
    }
    return (
      <div>
        <form
          className="AddEditAuthor"
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <HeaderBar header={isEdit ? "Edit Author" : "Add Author"}>
            {isEdit && (
              <Link
                to="/authors"
                className="utility-btn-transparent utility-lr-margin-small"
              >
                Cancel
              </Link>
            )}
            <button className="utility-btn-primary">
              {isEdit ? "Update" : "Create"}
            </button>
          </HeaderBar>
          <MainContentContainer subContent={true}>
            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <p>Author Name</p>
                <input
                  type="text"
                  className="utility-text-input"
                  name="author_name"
                  value={this.state.author_name}
                  onChange={this.handleFieldChange}
                  required
                />
              </label>
              <label className="utility-inline-label">
                <p>Author Title</p>
                <input
                  type="text"
                  className="utility-text-input"
                  name="author_title"
                  value={this.state.author_title}
                  onChange={this.handleFieldChange}
                />
              </label>
            </div>

            <div>
              <label className="utility-inline-label">
                <p>Author Facebook URL</p>
                <input
                  type="text"
                  className="utility-text-input"
                  name="author_facebook_url"
                  value={this.state.author_facebook_url}
                  onChange={this.handleFieldChange}
                  style={{ width: "400px" }}
                />
              </label>
            </div>

            <div>
              <label className="utility-inline-label">
                <p>Author Twitter URL</p>
                <input
                  type="text"
                  className="utility-text-input"
                  name="author_twitter_url"
                  value={this.state.author_twitter_url}
                  onChange={this.handleFieldChange}
                  style={{ width: "400px" }}
                />
              </label>
            </div>

            <div>
              <label className="utility-inline-label">
                <p>Author Instagram URL</p>
                <input
                  type="text"
                  className="utility-text-input"
                  name="author_instagram_url"
                  value={this.state.author_instagram_url}
                  onChange={this.handleFieldChange}
                  style={{ width: "400px" }}
                />
              </label>
            </div>

            <div className="utility-inline-label AddEditTappingPoints-img-upload">
              <p>Picture</p>
              <div
                className="utility-flex-row"
                style={{ alignItems: "flex-start" }}
              >
                {(this.state.author_image_preview ||
                  this.state.author_image) && (
                    <img
                      src={
                        this.state.author_image_preview || this.state.author_image
                      }
                      alt="Profile"
                      style={{ maxHeight: "200px", marginRight: "50px" }}
                    />
                  )}
                <label className="AddEditArtist-img-upload">
                  <input
                    type="file"
                    onChange={this.handleImageUpload}
                    accept="image/*"
                  />
                  <p className="utility-btn-primary" style={{ color: "#fff" }}>
                    Upload Picture
                  </p>
                </label>
              </div>
            </div>

            <div>
              <div className="utility-inline-label AddEditTappingPoints-img-upload">
                <p>Signature Image</p>
                <div
                  className="utility-flex-row"
                  style={{ alignItems: "flex-start" }}
                >
                  {(this.state.author_signature_preview ||
                    this.state.author_signature) && (
                      <img
                        src={
                          this.state.author_signature_preview ||
                          this.state.author_signature
                        }
                        alt="Profile"
                        style={{ maxHeight: "200px", marginRight: "50px" }}
                      />
                    )}
                  <label className="AddEditArtist-img-upload">
                    <input
                      type="file"
                      onChange={this.handleSignatureUpload}
                      accept="image/*"
                    />
                    <p
                      className="utility-btn-primary"
                      style={{ color: "#fff" }}
                    >
                      Upload Signature
                    </p>
                  </label>
                </div>
              </div>
              <div></div>

              <label className="utility-inline-label">
                <p>Author Bio</p>
                <AddEditAuthorTextEditor
                  handleChange={this.bioChange}
                  value={this.state.author_bio}
                />
              </label>
            </div>

            {isEdit && (
              <div style={{ marginTop: "60px" }}>
                <button
                  className="utility-btn-danger"
                  onClick={this.handleDelete}
                >
                  Delete Author
                </button>
              </div>
            )}
          </MainContentContainer>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    image: state.media.image,
    authors: state.authors.authors,
  };
}

const mapDispatchToProps = {
  fetchAuthors,
  clearAuthors,
  addEditAuthor,
  deleteAuthor,
  uploadImage,
  clearMedia,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAuthor);
