import {
  FETCH_ALL_QUOTES,
} from '../constants/ActionTypes'

const INITIAL_STATE = { all_quotes: null };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_QUOTES:
      return { ...state, all_quotes: action.payload };
    default:
      return state;
  }
}
