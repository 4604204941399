import { FETCH_REQUESTS } from '../constants/ActionTypes'

const INITIAL_STATE = {
  requests: {
    data: []
  }
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_REQUESTS:
      return { ...state, requests: action.payload };
    default:
      return state;
  }
}