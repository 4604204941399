import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  addEditBGM,
  deleteBGM,
  fetchAllBGM,
} from "../../../actions/backgroundMusicActions";
import { uploadAudio, clearMedia } from "../../../actions/mediaActions";
import HeaderBar from "../../HeaderBar/HeaderBar";

class BackgroundMusicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgm_title: "",
      bgm_url: "",
      bgm_url_preview: "",
      bgm_audio_length: 0,
      bgm_url_uploading: false,
      isSetToEdit: false,
    };
  }
  componentDidMount() {
    this.props.fetchAllBGM();
    this.props.clearMedia();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps.background_musics, this.props.isEdit);

    if (
      this.props.isEdit &&
      nextProps.background_musics &&
      !this.state.isSetToEdit
    ) {
      this.setBGMForEditing(nextProps);
    }

    if (nextProps.audio && !this.props.audio) {
      if (this.state.bgm_url_uploading) {
        const audio = nextProps.audio;
        this.setState({
          bgm_url_uploading: false,
          bgm_url: audio,
        });
        this.props.clearMedia();
      }
    }
  }

  setBGMForEditing = ({ background_musics }) => {
    let currentBGM = null;
    for (let x = 0; x < background_musics.length; x++) {
      if (background_musics[x].bgm_id === Number(this.props.selectedId)) {
        currentBGM = background_musics[x];
        break;
      }
    }

    console.log(currentBGM);

    this.setState({ ...currentBGM, isSetToEdit: true });
  };

  handleBGMediaUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.bgm_url) {
      window.URL.revokeObjectURL(this.state.bgm_url);
    }
    this.setState({
      bgm_url: window.URL.createObjectURL(file),
      bgm_url_uploading: true,
    });
    const objectURL = window.URL.createObjectURL(file);
    const mySound = new Audio([objectURL]);
    mySound.addEventListener(
      "canplaythrough",
      () => {
        URL.revokeObjectURL(objectURL);
        this.setState({
          bgm_audio_length: Math.round(mySound.duration),
          bgm_url_uploading: true,
        });
      },
      false
    );
    this.props.uploadAudio(file);
  };

  handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Saving...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    if (this.state.bgm_title === "") {
      Swal.fire({
        icon: "error",
        text: "You must enter title of BGM",
      });
      return false;
    }

    if (this.state.bgm_url === "") {
      Swal.fire({
        icon: "error",
        text: "You must upload BGM audio.",
      });
      return false;
    }

    let { session_bg_timestamps: timestamps_json, ...bgm_json } = this.state;

    const options = {
      bgm_json: JSON.stringify(bgm_json),
    };
    this.props.addEditBGM(options);
  };

  render() {
    const isEdit = this.props.isEdit;
    return (
      <form
        className="AddEditBGM"
        onSubmit={this.handleSubmit}
        autoComplete="off"
      >
        <HeaderBar header={isEdit ? "Edit BGM" : "Create New BGM"}>
          <Link
            to="/background-musics"
            className="utility-btn-transparent utility-lr-margin-small"
          >
            Cancel
          </Link>
          <button className="utility-btn-primary">
            {isEdit ? "Update" : "Create"}
          </button>
        </HeaderBar>

        <div>
          <label className="utility-inline-label">
            <p>BGM Title</p>
            <input
              type="text"
              className="utility-text-input"
              name="bgm_title"
              value={this.state.bgm_title}
              onChange={this.handleFieldChange}
              required
            />
          </label>
          <div className="utility-flex-row">
            <label style={{ marginRight: "20px", width: "300px" }}>
              <p style={{ fontSize: "14px", margin: "13px 0 -11px" }}>
                Background MP3 Audio File
              </p>
              <input
                type="file"
                onChange={this.handleBGMediaUpload}
                style={{ display: "none" }}
                accept={".mp3"}
              />
              <p
                className="utility-btn-primary"
                style={{ color: "#fff" }}
                tabIndex="0"
                onKeyPress={this.clickParent}
              >
                Upload Audio
              </p>
            </label>
          </div>

          <div className="utility-flex-row">
            <audio
              id="bgm_url"
              src={this.state.bgm_url_preview || this.state.bgm_url}
              controls
              style={{ width: "300px" }}
            />
          </div>
        </div>
        {isEdit && (
          <div style={{ marginTop: "20px", textAlign: "right" }}>
            <button className="utility-btn-danger" onClick={this.handleDelete}>
              Delete BGM
            </button>
          </div>
        )}
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    background_musics: state.background_musics.background_musics,
    audio: state.media.audio,
  };
}

const mapDispatchToProps = {
  fetchAllBGM,
  deleteBGM,
  addEditBGM,
  uploadAudio,
  clearMedia,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackgroundMusicTable);
