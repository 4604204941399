import { push } from 'react-router-redux';
import { api, errorHandler, queryString } from './index';
import {
  API_ERROR,
  FETCH_ALL_PROMO_BANNERS,
} from '../constants/ActionTypes';
import Swal from 'sweetalert2';

export function fetchAllPromoBanners() {
  return function (dispatch) {
    api.post(`/fetch-all-promo-banners.php`)
      .then(response => {
        dispatch({
          type: FETCH_ALL_PROMO_BANNERS,
          payload: response.data.objects
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updatePromoBannerOrder(promo_banner_ids) {
  return function (dispatch) {
    api.post(`/update-promo-banners-order.php`,
      queryString.stringify({
        promo_banner_ids: promo_banner_ids,
      })
    )
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditPromoBanner(promo_banner_json) {
  return function (dispatch) {
    api.post(`/add-n-edit-promo-banner.php`,
      queryString.stringify({
        promo_banner_json: promo_banner_json,
      })
    )
      .then(response => {
        Swal.fire({
          icon: "success",
          title: "Saved!",
          timer: 1000
        }).then(() => {
          dispatch(push("/banners"));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deletePromoBanner(promo_banner_id) {
  return function (dispatch) {
    api.post(`/delete-promo-banner.php`,
      queryString.stringify({
        promo_banner_id: promo_banner_id,
      })
    )
      .then(response => {
        Swal.fire({
          icon: "success",
          title: "Promo Banner Deleted!",
          timer: 1000
        }).then(() => {
          dispatch(push("/banners"));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

