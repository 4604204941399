import React from 'react';
import { Modal, Box, Typography, IconButton, Grid, Paper } from '@mui/material';
import { Close } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export default function MediaResourceModal({ open, onClose, mediaResource }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="media-resource-modal-title"
      aria-describedby="media-resource-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={onClose}
        >
          <Close />
        </IconButton>

        {mediaResource && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={3}>
                  <img
                    src={mediaResource.resource_url}
                    alt={mediaResource.file_name}
                    style={{ 
                        width: '100%', 
                        height: 'auto', 
                        maxWidth: '100%',
                        maxHeight: '80vh',
                        objectFit: 'contain',
                        borderRadius: '10px'
                     }}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  id="media-resource-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ textAlign: 'center', marginTop: 2 }}
                >
                  {mediaResource.name}
                </Typography>
                <Typography
                  id="media-resource-modal-description"
                  variant="body1"
                  sx={{ textAlign: 'center', marginTop: 1 }}
                >
                  {mediaResource.description}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  );
}
