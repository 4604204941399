import { ADD_CARD_TO_DECK, ADD_CARD_TO_DECK_ERROR, ADD_CARD_TO_DECK_SUCCESS, API_ERROR, CLOSE_EDIT_DECK_CARD, CLOSE_EDIT_DECK_CARD_SUCCESS, FETCH_DECK_CARDS, FETCH_DECK_CARDS_ERROR, FETCH_DECK_CARDS_SUCCESS, FETCH_ONE_DECK_CARD, FETCH_ONE_DECK_CARD_ERROR, FETCH_ONE_DECK_CARD_SUCCESS, FETCH_UNPARENTED_CARDS, FETCH_UNPARENTED_CARDS_ERROR, FETCH_UNPARENTED_CARDS_SUCCESS, REMOVE_CARD_FROM_DECK, REMOVE_CARD_FROM_DECK_ERROR, REMOVE_CARD_FROM_DECK_SUCCESS, SAVE_DECK_CARD, SAVE_DECK_CARD_ERROR, SAVE_DECK_CARD_SUCCESS, SORT_DECK_CARDS, SORT_DECK_CARDS_ERROR, SORT_DECK_CARDS_SUCCESS } from "../constants/ActionTypes";
import { api, errorHandler, queryString } from ".";
import { push } from "react-router-redux";
import Swal from "sweetalert2";


export function fetchAllCards(deckId) {
    return function (dispatch) {
        dispatch({
            type: FETCH_DECK_CARDS
        });

        api.post(`/cms/decks/cards/fetch-all-by-deck.php`,
            queryString.stringify({
                deck_id: deckId
            })
        )
            .then((response) => {
                dispatch({
                    type: FETCH_DECK_CARDS_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_DECK_CARDS_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function fetchUnparentedCards() {
    return function (dispatch) {
        dispatch({
            type: FETCH_UNPARENTED_CARDS
        });

        api.post(`/cms/decks/cards/fetch-all-by-deck.php`,
            queryString.stringify({
                deck_id: null
            })
        )
            .then((response) => {
                dispatch({
                    type: FETCH_UNPARENTED_CARDS_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_UNPARENTED_CARDS_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function fetchOneCard(cardId) {
    return function (dispatch) {
        dispatch({
            type: FETCH_ONE_DECK_CARD
        });
        api.post(`/cms/decks/cards/fetch-one-card.php`,
            queryString.stringify({
                id: cardId
            })
        )
            .then((response) => {
                dispatch({
                    type: FETCH_ONE_DECK_CARD_SUCCESS,
                    payload: response.data.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_ONE_DECK_CARD_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function closeEditCard(deckId) {
    return function (dispatch) {
        dispatch({
            type: CLOSE_EDIT_DECK_CARD
        });

        dispatch({
            type: CLOSE_EDIT_DECK_CARD_SUCCESS,
            payload: deckId
        })

        dispatch(push(`/card-deck/${deckId}/cards`))
    }
}

export function removeCardFromDeck(deckId, cardId) {
    return function (dispatch) {
        dispatch({
            type: REMOVE_CARD_FROM_DECK
        });

        api.post(`/cms/decks/remove-card.php`,
            queryString.stringify({
                deck_id: deckId,
                card_id: cardId
            })
        )
            .then((response) => {
                dispatch({
                    type: REMOVE_CARD_FROM_DECK_SUCCESS,
                    payload: cardId
                });
                Swal.fire({
                    icon: "success",
                    title: "Card Removed!",
                    timer: 2000
                });
            })
            .catch((error) => {
                dispatch({
                    type: REMOVE_CARD_FROM_DECK_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function addCardToDeck(deckId, card) {
    return function (dispatch) {
        dispatch({
            type: ADD_CARD_TO_DECK
        });

        api.post(`/cms/decks/add-card.php`,
            queryString.stringify({
                deck_id: deckId,
                card_id: card.id
            })
        )
            .then((response) => {
                dispatch({
                    type: ADD_CARD_TO_DECK_SUCCESS,
                    payload: card
                });
                Swal.fire({
                    icon: "success",
                    title: "Card Added!",
                    timer: 2000,
                    position: 'bottom-start',
                    toast: true,
                    showConfirmButton: false,
                    target: document.getElementById('unparented-card-dialog')
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADD_CARD_TO_DECK_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function saveCard(card) {
    return function (dispatch) {
        dispatch({
            type: SAVE_DECK_CARD
        });

        api.post(
            `/cms/decks/cards/add-n-edit-card.php`,
            queryString.stringify(card)
        )
            .then((response) => {
                dispatch({
                    type: SAVE_DECK_CARD_SUCCESS,
                    payload: response.data
                });
                Swal.fire({
                    icon: "success",
                    title: "Card Saved!",
                    timer: 1000
                }).then(() => {
                    dispatch(push(`/card-deck/${card.deck_id}/cards`));
                })
            })
            .catch((error) => {
                dispatch({
                    type: SAVE_DECK_CARD_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function sortCards(sortedCards) {
    return function (dispatch) {
        dispatch({
            type: SORT_DECK_CARDS
        });

        const sortedIds = sortedCards.map(card => {
            return card.id
        });

        api.post(`/cms/decks/cards/update-cards-order.php`,
            queryString.stringify({
                ids: JSON.stringify(sortedIds)
            })
        )
            .then((response) => {
                dispatch({
                    type: SORT_DECK_CARDS_SUCCESS,
                    payload: sortedCards
                });
                Swal.fire({
                    icon: "success",
                    title: "Cards Sorted!",
                    timer: 2000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'bottom-end',
                });
            })
            .catch((error) => {
                dispatch({
                    type: SORT_DECK_CARDS_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}