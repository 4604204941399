import { api, errorHandler, queryString } from "./index";
import { API_ERROR, CLOSE_EDIT_CARD_DECK, CLOSE_EDIT_CARD_DECK_SUCCESS, DELETE_CARD_DECK, DELETE_CARD_DECK_ERROR, DELETE_CARD_DECK_SUCCESS, FETCH_CARD_DECKS, FETCH_CARD_DECKS_ERROR, FETCH_CARD_DECKS_SUCCESS, FETCH_ONE_CARD_DECK, FETCH_ONE_CARD_DECK_ERROR, FETCH_ONE_CARD_DECK_SUCCESS, SAVE_CARD_DECKS, SAVE_CARD_DECKS_ERROR, SAVE_CARD_DECKS_SUCCESS, SORT_CARD_DECKS, SORT_CARD_DECKS_ERROR, SORT_CARD_DECKS_SUCCESS } from "../constants/ActionTypes";
import { push } from "react-router-redux";
import Swal from "sweetalert2";

export function fetchAllDecks() {
    return function (dispatch) {
        dispatch({
            type: FETCH_CARD_DECKS
        });

        api.post(`/cms/decks/fetch-all-decks.php`)
            .then((response) => {
                dispatch({
                    type: FETCH_CARD_DECKS_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_CARD_DECKS_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function deleteCardDeck(id) {
    return function (dispatch) {
        dispatch({
            type: DELETE_CARD_DECK
        });

        api.post(`/cms/decks/delete-deck.php`,
            queryString.stringify({
                id
            })
        )
            .then((response) => {
                dispatch({
                    type: DELETE_CARD_DECK_SUCCESS,
                    payload: id
                });
                Swal.fire({
                    icon: "success",
                    title: "Card Deck Deleted!",
                    timer: 2000
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_CARD_DECK_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function selectCardDeck(deck, destination = "") {
    return function (dispatch) {
        dispatch({
            type: FETCH_ONE_CARD_DECK_SUCCESS,
            payload: { data: deck }
        });
        if (destination) {
            dispatch(push(destination));
        }
    }
}

export function saveCardDeck(deck) {
    return function (dispatch) {
        dispatch({
            type: SAVE_CARD_DECKS
        });

        api.post(`/cms/decks/add-n-edit-deck.php`,
            queryString.stringify(deck)
        )
            .then((response) => {
                dispatch({
                    type: SAVE_CARD_DECKS_SUCCESS,
                    payload: response.data
                });
                Swal.fire({
                    icon: "success",
                    title: "Card Deck Saved!",
                    timer: 1000
                }).then(() => {
                    dispatch(push('/card-decks'));
                })
            })
            .catch((error) => {
                dispatch({
                    type: SAVE_CARD_DECKS_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function fetchOneDeck(id) {
    return function (dispatch) {
        dispatch({
            type: FETCH_ONE_CARD_DECK
        });

        api.post(`/cms/decks/fetch-one-deck.php`,
            queryString.stringify({
                id
            })
        )
            .then((response) => {
                dispatch({
                    type: FETCH_ONE_CARD_DECK_SUCCESS,
                    payload: response.data
                })
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_ONE_CARD_DECK_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}

export function closeEditCardDeck() {
    return function (dispatch) {
        dispatch({
            type: CLOSE_EDIT_CARD_DECK
        });

        dispatch({
            type: CLOSE_EDIT_CARD_DECK_SUCCESS,
            payload: null
        })

        dispatch(push(`/card-decks`))
    }
}

export function sortCardDeck(sortedDecks) {
    return function (dispatch) {
        dispatch({
            type: SORT_CARD_DECKS
        });

        const sortedIds = sortedDecks.map(deck => {
            return deck.id
        });

        api.post(`/cms/decks/update-decks-order.php`,
            queryString.stringify({
                ids: JSON.stringify(sortedIds)
            })
        )
            .then((response) => {
                dispatch({
                    type: SORT_CARD_DECKS_SUCCESS,
                    payload: sortedDecks
                });
                Swal.fire({
                    icon: "success",
                    title: "Card Deck Sorted!",
                    timer: 2000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'bottom-end',
                });
            })
            .catch((error) => {
                dispatch({
                    type: SORT_CARD_DECKS_ERROR,
                    payload: []
                });
                errorHandler(dispatch, error.response, API_ERROR);
            })
    }
}