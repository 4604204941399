//= =====================
// Auth Action Types
//= =====================
export const AUTH_USER = "AUTH_USER";
export const UNAUTH_USER = "UNAUTH_USER";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";

//= =====================
// Error Types
//= =====================
export const API_ERROR = "API_ERROR";
export const STATIC_ERROR = "STATIC_ERROR";
export const AUTH_ERROR = "AUTH_ERROR";

//= =====================
// Category Types
//= =====================
export const FETCH_ALL_CATEGORIES = "FETCH_ALL_CATEGORIES";
export const FETCH_ALL_SUB_CATEGORIES = "FETCH_ALL_SUB_CATEGORIES";
export const CLEAR_ALL_SUB_CATEGORIES = "CLEAR_ALL_SUB_CATEGORIES";

//= =====================
// Subcategory Types
//= =====================
export const FETCH_ALL_SUBCATEGORIES_START = "FETCH_ALL_SUBCATEGORIES_START";
export const FETCH_ALL_SUBCATEGORIES = "FETCH_ALL_SUBCATEGORIES";
export const FETCH_ONE_SUBCATEGORY = "FETCH_ONE_SUBCATEGORY";

//= =====================
// Subcategory Types
//= =====================
export const FETCH_ALL_CHALLENGES = "FETCH_ALL_CHALLENGES";
export const FETCH_ONE_CHALLENGE = "FETCH_ONE_CHALLENGE";
export const CLEAR_ALL_CHALLENGES = "CLEAR_ALL_CHALLENGES";
//= =====================
// Series Types
//= =====================

export const FETCH_ALL_SERIES_START = "FETCH_ALL_SERIES_START";
export const FETCH_ALL_SERIES = "FETCH_ALL_SERIES";
//= =====================
// Reflection Types
//= =====================
export const FETCH_ALL_DAILY_REFLECTIONS = "FETCH_ALL_DAILY_REFLECTIONS";
export const FETCH_ALL_DAILY_REFLECTIONS_SUCCESS = "FETCH_ALL_DAILY_REFLECTIONS_SUCCESS";
export const FETCH_ALL_DAILY_REFLECTIONS_ERROR = "FETCH_ALL_DAILY_REFLECTIONS_ERROR";
export const SAVE_DAILY_REFLECTION = "SAVE_DAILY_REFLECTION";
export const SAVE_DAILY_REFLECTION_SUCCESS = "SAVE_DAILY_REFLECTION_SUCCESS";
export const SAVE_DAILY_REFLECTION_ERROR = "SAVE_DAILY_REFLECTION_ERROR";
export const DELETE_DAILY_REFLECTION = "DELETE_DAILY_REFLECTION";
export const DELETE_DAILY_REFLECTION_SUCCESS = "DELETE_DAILY_REFLECTION_SUCCESS";
export const DELETE_DAILY_REFLECTION_ERROR = "DELETE_DAILY_REFLECTION_ERROR";
export const FETCH_ONE_DAILY_REFLECTION = "FETCH_ONE_DAILY_REFLECTION";
export const FETCH_ONE_DAILY_REFLECTION_SUCCESS = "FETCH_ONE_DAILY_REFLECTION_SUCCESS";
export const FETCH_ONE_DAILY_REFLECTION_ERROR = "FETCH_ONE_DAILY_REFLECTION_ERROR";
export const CLOSE_DAILY_REFLECTION_FORM = "CLOSE_DAILY_REFLECTION_FORM";
//= =====================
// Session Types
//= =====================
export const FETCH_ALL_SESSIONS = "FETCH_ALL_SESSIONS";
export const FETCH_ALL_SESSIONS_START = "FETCH_ALL_SESSIONS_START";
export const FETCH_ALL_SESSIONS_FOR_PROGRAM = "FETCH_ALL_SESSIONS_FOR_PROGRAM";
//= =====================
// Quick Taps Types
//= =====================
export const FETCH_ALL_QUICK_TAPS_START = "FETCH_ALL_QUICK_TAPS_START";
export const FETCH_ALL_QUICK_TAPS = "FETCH_ALL_QUICK_TAPS";
//= =====================
// Session Tags
//= =====================
export const FETCH_TAGS = "FETCH_TAGS";
//= =====================
// Client Types
//= =====================
export const FETCH_ALL_CLIENTS = "FETCH_ALL_CLIENTS";

//= =====================
// Tapping Points Types
//= =====================
export const FETCH_ALL_TAPPING_POINTS = "FETCH_ALL_TAPPING_POINTS";
export const CLEAR_TAPPING_POINTS = "CLEAR_TAPPING_POINTS";

//= =====================
// Author Types
//= =====================
export const FETCH_AUTHORS = "FETCH_AUTHORS";
export const CLEAR_AUTHORS = "CLEAR_AUTHORS";
export const FETCH_AUTHORS_SESSIONS = "FETCH_AUTHORS_SESSIONS";
export const FETCH_AUTHORS_PROGRAMS = "FETCH_AUTHORS_PROGRAMS";
export const UPLOAD_AUTHORS_IMAGE = "UPLOAD_AUTHORS_IMAGE";

//= =====================
// Global Action Types
//= =====================
export const FETCH_GENERAL_INFO = "FETCH_GENERAL_INFO";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const CLEAR_HIGHLIGHT = "CLEAR_HIGHLIGHT";

//= =====================
// Quote Actions
//= =====================
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_TARGET = "UPLOAD_IMAGE_TARGET";
export const UPLOAD_AUDIO = "UPLOAD_AUDIO";
export const UPLOAD_VIDEO = "UPLOAD_VIDEO";
export const UPLOAD_MUX_VIDEO = "UPLOAD_MUX_VIDEO";
export const CLEAR_MEDIA = "CLEAR_MEDIA";

//= =====================
// Quote Actions
//= =====================
export const FETCH_ALL_QUOTES = "FETCH_ALL_QUOTES";

//= =====================
// Tag Actions
//= =====================
export const FETCH_ALL_TAGS = "FETCH_ALL_TAGS";

//= =====================
// Modal Actions
//= =====================
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

//= =====================
// Feedbacks Actions
//= =====================
export const FETCH_FEEDBACKS = "FETCH_FEEDBACKS";

//= =====================
// Requests Actions
//= =====================
export const FETCH_REQUESTS = "FETCH_REQUESTS";

//= =====================
// Promo Banner Actions
//= =====================
export const FETCH_ALL_PROMO_BANNERS = "FETCH_ALL_PROMO_BANNERS";

//= =====================
// Promo Data Actions
//= =====================
export const FETCH_ALL_PROMO_DATA = "FETCH_ALL_PROMO_DATA";
export const FETCH_ALL_GENERAL_DATA = "FETCH_ALL_GENERAL_DATA";

//= =====================
// Background Music Actions
//= =====================
export const FETCH_ALL_BACKGROUND_MUSICS = "FETCH_ALL_BACKGROUND_MUSICS";

//= =====================
// Onboarding Data Actions
//= =====================

export const FETCH_ALL_ONBOARDING_DATA = "FETCH_ALL_ONBOARDING_DATA";

//= =====================
// Search Actions
//= =====================

export const FETCH_ALL_TOP_SEARCH = "FETCH_ALL_TOP_SEARCH";
export const FETCH_SUGGESTED_SEARCH_OPTIONS = "FETCH_SUGGESTED_SEARCH_OPTIONS";
export const FETCH_SUGGESTED_SEARCH = "FETCH_SUGGESTED_SEARCH";
export const DELETE_TOP_SEARCH = "DELETE_TOP_SEARCH";
export const DELETE_SUGGESTED_SEARCH = "DELETE_SUGGESTED_SEARCH";
export const FETCH_SEARCH_DEEPLINKS = "FETCH_SEARCH_DEEPLINKS";

//= ============================
// CANCELLATION REASONS Actions
//= ============================

export const UPDATE_CANCELLATION_REASON = "UPDATE_CANCELLATION_REASON";
export const FETCH_CANCELLATION_REASONS = "FETCH_CANCELLATION_REASONS";
export const DELETE_CANCELLATION_REASON = "DELETE_CANCELLATION_REASON";
export const FETCH_USER_CANCELLATION_REPORT = "FETCH_USER_CANCELLATION_REPORT";

// USER STREAK
export const FETCH_USER_STREAK = "FETCH_USER_STREAK";
export const RESTORE_USER_STREAK = "RESTORE_USER_STREAK";
export const FETCH_USER_STREAK_ERROR = "FETCH_USER_STREAK_ERROR";

// DECKS
export const FETCH_CARD_DECKS = "FETCH_CARD_DECKS";
export const FETCH_CARD_DECKS_SUCCESS = "FETCH_CARD_DECKS_SUCCESS";
export const FETCH_CARD_DECKS_ERROR = "FETCH_CARD_DECKS_ERROR";
export const SAVE_CARD_DECKS = "SAVE_CARD_DECKS";
export const SAVE_CARD_DECKS_SUCCESS = "SAVE_CARD_DECKS_SUCCESS";
export const SAVE_CARD_DECKS_ERROR = "SAVE_CARD_DECKS_ERROR";
export const FETCH_ONE_CARD_DECK = "FETCH_ONE_CARD_DECK";
export const FETCH_ONE_CARD_DECK_SUCCESS = "FETCH_ONE_CARD_DECK_SUCCESS";
export const FETCH_ONE_CARD_DECK_ERROR = "FETCH_ONE_CARD_DECK_ERROR";
export const CLOSE_EDIT_CARD_DECK = "CLOSE_EDIT_CARD_DECK";
export const CLOSE_EDIT_CARD_DECK_SUCCESS = "CLOSE_EDIT_CARD_DECK_SUCCESS";
export const DELETE_CARD_DECK = "DELETE_CARD_DECK";
export const DELETE_CARD_DECK_SUCCESS = "DELETE_CARD_DECK_SUCCESS";
export const DELETE_CARD_DECK_ERROR = "DELETE_CARD_DECK_ERROR";
export const SORT_CARD_DECKS = "SORT_CARD_DECKS";
export const SORT_CARD_DECKS_SUCCESS = "SORT_CARD_DECKS_SUCCESS";
export const SORT_CARD_DECKS_ERROR = "SORT_CARD_DECKS_ERROR";

// CARDS
export const FETCH_DECK_CARDS = "FETCH_DECK_CARDS";
export const FETCH_DECK_CARDS_SUCCESS = "FETCH_DECK_CARDS_SUCCESS";
export const FETCH_DECK_CARDS_ERROR = "FETCH_DECK_CARDS_ERROR";
export const SAVE_DECK_CARD = "SAVE_DECK_CARD";
export const SAVE_DECK_CARD_SUCCESS = "SAVE_DECK_CARD_SUCCESS";
export const SAVE_DECK_CARD_ERROR = "SAVE_DECK_CARD_ERROR";
export const FETCH_UNPARENTED_CARDS = "FETCH_UNPARENTED_CARDS";
export const FETCH_UNPARENTED_CARDS_SUCCESS = "FETCH_UNPARENTED_CARDS_SUCCESS";
export const FETCH_UNPARENTED_CARDS_ERROR = "FETCH_UNPARENTED_CARDS_ERROR";
export const FETCH_ONE_DECK_CARD = "FETCH_ONE_DECK_CARD";
export const FETCH_ONE_DECK_CARD_SUCCESS = "FETCH_ONE_DECK_CARD_SUCCESS";
export const FETCH_ONE_DECK_CARD_ERROR = "FETCH_ONE_DECK_CARD_ERROR";
export const CLOSE_EDIT_DECK_CARD = "CLOSE_EDIT_DECK_CARD";
export const CLOSE_EDIT_DECK_CARD_SUCCESS = "CLOSE_EDIT_DECK_CARD_SUCCESS";
export const REMOVE_CARD_FROM_DECK = "REMOVE_CARD_FROM_DECK";
export const REMOVE_CARD_FROM_DECK_SUCCESS = "REMOVE_CARD_FROM_DECK_SUCCESS";
export const REMOVE_CARD_FROM_DECK_ERROR = "REMOVE_CARD_FROM_DECK_ERROR";
export const ADD_CARD_TO_DECK = "ADD_CARD_TO_DECK";
export const ADD_CARD_TO_DECK_SUCCESS = "ADD_CARD_TO_DECK_SUCCESS";
export const ADD_CARD_TO_DECK_ERROR = "ADD_CARD_TO_DECK_ERROR";
export const SORT_DECK_CARDS = "SORT_DECK_CARDS";
export const SORT_DECK_CARDS_SUCCESS = "SORT_DECK_CARDS_SUCCESS";
export const SORT_DECK_CARDS_ERROR = "SORT_DECK_CARDS_ERROR";

//MEDIA RESOURCES
export const FETCH_MEDIA_RESOURCES = "FETCH_MEDIA_RESOURCES";
export const FETCH_MEDIA_RESOURCES_SUCCESS = "FETCH_MEDIA_RESOURCES_SUCCESS";
export const FETCH_MEDIA_RESOURCES_ERROR = "FETCH_MEDIA_RESOURCES_ERROR";
export const SAVE_MEDIA_RESOURCE = "SAVE_MEDIA_RESOURCE";
export const SAVE_MEDIA_RESOURCE_SUCCESS = "SAVE_MEDIA_RESOURCE_SUCCESS";
export const SAVE_MEDIA_RESOURCE_ERROR = "SAVE_MEDIA_RESOURCE_ERROR";
export const DELETE_MEDIA_RESOURCE = "DELETE_MEDIA_RESOURCE";
export const DELETE_MEDIA_RESOURCE_SUCCESS = "DELETE_MEDIA_RESOURCE_SUCCESS";
export const DELETE_MEDIA_RESOURCE_ERROR = "DELETE_MEDIA_RESOURCE_ERROR";
export const UPDATE_MEDIA_RESOURCE = "UPDATE_MEDIA_RESOURCE";
export const UPDATE_MEDIA_RESOURCE_SUCCESS = "UPDATE_MEDIA_RESOURCE_SUCCESS";
export const UPDATE_MEDIA_RESOURCE_ERROR = "UPDATE_MEDIA_RESOURCE_ERROR";

//ADMINS
export const FETCH_ALL_ADMINS = "FETCH_ALL_ADMINS";
export const FETCH_ALL_ADMINS_SUCCESS = "FETCH_ALL_ADMINS_SUCCESS";
export const FETCH_ALL_ADMINS_ERROR = "FETCH_ALL_ADMINS_ERROR";
export const SAVE_ADMIN = "SAVE_ADMIN";
export const SAVE_ADMIN_SUCCESS = "SAVE_ADMIN_SUCCESS";
export const SAVE_ADMIN_ERROR = "SAVE_ADMIN_ERROR";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_ERROR = "DELETE_ADMIN_ERROR";
export const FETCH_ONE_ADMIN = "FETCH_ONE_ADMIN";
export const FETCH_ONE_ADMIN_SUCCESS = "FETCH_ONE_ADMIN_SUCCESS";
export const FETCH_ONE_ADMIN_ERROR = "FETCH_ONE_ADMIN_ERROR";
export const RESET_PASSWORD_ADMIN = "RESET_PASSWORD_ADMIN";
export const RESET_PASSWORD_ADMIN_SUCCESS = "RESET_PASSWORD_ADMIN_SUCCESS";
export const RESET_PASSWORD_ADMIN_ERROR = "RESET_PASSWORD_ADMIN_ERROR";
export const UPDATE_PROFILE_ADMIN = "UPDATE_PROFILE_ADMIN";
export const UPDATE_PROFILE_ADMIN_SUCCESS = "UPDATE_PROFILE_ADMIN_SUCCESS";
export const UPDATE_PROFILE_ADMIN_ERROR = "UPDATE_PROFILE_ADMIN_ERROR";