import { push } from 'react-router-redux';
import { api, errorHandler, queryString } from './index';
import {
  API_ERROR,
  FETCH_AUTHORS,
  CLEAR_AUTHORS
} from '../constants/ActionTypes';
import Swal from 'sweetalert2';

//= ===============================
// Client actions
//= ===============================

export function fetchAuthors() {
  return function (dispatch) {
    api.post(`/fetch-all-authors.php`)
      .then(response => {
        dispatch({
          type: FETCH_AUTHORS,
          payload: response.data.objects
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditAuthor(author_json) {
  return function (dispatch) {
    api.post(`/add-n-edit-author.php`,
      queryString.stringify({
        author_json: author_json,
      })
    )
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: 'Saved!',
          timer: 1000
        }).then(() => {
          dispatch(push('/authors'));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteAuthor(author_id) {
  return function (dispatch) {
    api.post(`/delete-author.php`,
      queryString.stringify({
        author_id: author_id,
      })
    )
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: 'Author Deleted!',
          timer: 1000
        }).then(() => {
          dispatch(push('/authors'));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function clearAuthors(page) {
  return function (dispatch) {
    dispatch({
      type: CLEAR_AUTHORS
    });
  };
}
