import Swal from "sweetalert2";
import { api, errorHandler, queryString } from "./index";
import {
  FETCH_ALL_DAILY_REFLECTIONS,
  API_ERROR,
  FETCH_ALL_GENERAL_DATA,
  DELETE_DAILY_REFLECTION_SUCCESS,
  SAVE_DAILY_REFLECTION_SUCCESS,
  SAVE_DAILY_REFLECTION_ERROR,
  DELETE_DAILY_REFLECTION_ERROR,
  FETCH_ALL_DAILY_REFLECTIONS_SUCCESS,
  SAVE_DAILY_REFLECTION,
  FETCH_ALL_DAILY_REFLECTIONS_ERROR,
  FETCH_ONE_DAILY_REFLECTION,
  FETCH_ONE_DAILY_REFLECTION_SUCCESS,
  FETCH_ONE_DAILY_REFLECTION_ERROR,
  CLOSE_DAILY_REFLECTION_FORM,
} from "../constants/ActionTypes";
import { push } from "react-router-redux";

export function fetchAllDailyReflections() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_DAILY_REFLECTIONS
    });

    api.post(`/fetch-cms-daily-reflections.php`)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_DAILY_REFLECTIONS_SUCCESS,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ALL_DAILY_REFLECTIONS_ERROR
        })
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditDailyReflections(daily_reflection_json) {
  return function (dispatch) {
    dispatch({
      type: SAVE_DAILY_REFLECTION
    });
    
    api.post(`/add-n-edit-daily-reflection.php`,
      queryString.stringify({
        daily_reflection_json: JSON.stringify(daily_reflection_json),
      })
    )
    .then((response) => {
      Swal.fire({
        icon: "success",
        title: "Saved!",
        timer: 1000,
      }).then(() => {
        dispatch({
          type: SAVE_DAILY_REFLECTION_SUCCESS,
          payload: daily_reflection_json
        })
        dispatch(push("/daily-inspirations"));
      });
    })
    .catch((error) => {
      dispatch({
        type: SAVE_DAILY_REFLECTION_ERROR,
      });
      Swal.fire({
        icon: "error",
        title: error.response.data.message,
        heightAuto: true,
      });
    });
  };
}

export function deleteDailyReflection(daily_reflection_id) {
  return function (dispatch) {
    api.post(`/delete-daily-reflection.php`,
      queryString.stringify({
        daily_reflection_id: daily_reflection_id,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Deleted Successfully!",
          timer: 1000,
        }).then(() => {
          dispatch({
            type: DELETE_DAILY_REFLECTION_SUCCESS,
            payload: daily_reflection_id
          });
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_DAILY_REFLECTION_ERROR,
          payload: daily_reflection_id
        });
        Swal.fire({
          icon: "error",
          title: error.response.data.message,
          heightAuto: true,
        });
      });
  };
}

export async function fetchDailyInspirationDescription() {
  return function (dispatch) {
    api.post(`/fetch-di-description.php`)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_GENERAL_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updateDailyInspirationDescription(
  daily_inspiration_description
) {
  return async function (dispatch) {
    try {
      api.post(`/update-di-description.php`,
        queryString.stringify({
          daily_inspiration_description: daily_inspiration_description,
        })
      );
    } catch (error) {
      return errorHandler(dispatch, error.response, API_ERROR);
    }
    Swal.fire({
      icon: "success",
      title: "Saved!",
      timer: 1000,
    });
  };
}

export function fetchOneDailyReflection(dail_reflection_id) {
  return function (dispatch) {
    dispatch({
      type: FETCH_ONE_DAILY_REFLECTION
    });

    api.post(`/fetch-one-cms-daily-reflection.php`, 
      queryString.stringify({
        dail_reflection_id
      })
    )
    .then((response) => {
      dispatch({
        type: FETCH_ONE_DAILY_REFLECTION_SUCCESS,
        payload: response.data.objects,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_ONE_DAILY_REFLECTION_ERROR
      })
      errorHandler(dispatch, error.response, API_ERROR);
    });
  };
}

export function closeDailyReflectionForm() {
  return function (dispatch) {
    dispatch({
      type: CLOSE_DAILY_REFLECTION_FORM
    });
    dispatch(push("/daily-inspirations"));
  };
}
