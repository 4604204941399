import { ADD_CARD_TO_DECK, ADD_CARD_TO_DECK_ERROR, ADD_CARD_TO_DECK_SUCCESS, CLOSE_EDIT_DECK_CARD, CLOSE_EDIT_DECK_CARD_SUCCESS, FETCH_DECK_CARDS, FETCH_DECK_CARDS_ERROR, FETCH_DECK_CARDS_SUCCESS, FETCH_ONE_DECK_CARD, FETCH_ONE_DECK_CARD_SUCCESS, FETCH_UNPARENTED_CARDS, FETCH_UNPARENTED_CARDS_ERROR, FETCH_UNPARENTED_CARDS_SUCCESS, REMOVE_CARD_FROM_DECK, REMOVE_CARD_FROM_DECK_ERROR, REMOVE_CARD_FROM_DECK_SUCCESS, SAVE_DECK_CARD, SAVE_DECK_CARD_ERROR, SAVE_DECK_CARD_SUCCESS, SORT_DECK_CARDS, SORT_DECK_CARDS_ERROR, SORT_DECK_CARDS_SUCCESS } from "../constants/ActionTypes"


const initialState = {
    cards: [],
    error: null,
    loading: false,
    allLoading: false,
    card: null,
    unparentedCards: []
}

export default function cardsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_DECK_CARDS:
            return {
                ...state,
                allLoading: true
            }
        case FETCH_UNPARENTED_CARDS:
        case SAVE_DECK_CARD:
        case FETCH_ONE_DECK_CARD:
        case CLOSE_EDIT_DECK_CARD:
        case REMOVE_CARD_FROM_DECK:
        case ADD_CARD_TO_DECK:
        case SORT_DECK_CARDS:
            return {
                ...state,
                loading: true
            }
        case FETCH_DECK_CARDS_SUCCESS:
            return {
                ...state,
                allLoading: false,
                cards: action.payload
            }
        case SAVE_DECK_CARD_SUCCESS:
            return {
                ...state,
                card: null,
                loading: false
            }
        case FETCH_UNPARENTED_CARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                unparentedCards: action.payload
            }
        case FETCH_ONE_DECK_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                card: action.payload
            }
        case CLOSE_EDIT_DECK_CARD_SUCCESS:
            return {
                ...state,
                loading: false,
                card: null
            }
        case REMOVE_CARD_FROM_DECK_SUCCESS:
            return {
                ...state,
                loading: false,
                cards: state.cards.filter(card => card.id !== action.payload)
            }
        case ADD_CARD_TO_DECK_SUCCESS:
            return {
                ...state,
                loading: false,
                cards: [
                    ...state.cards,
                    action.payload
                ],
                unparentedCards: state.unparentedCards.filter(card => card.id !== action.payload.id)
            }
        case SORT_DECK_CARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                cards: action.payload
            }
        case FETCH_DECK_CARDS_ERROR:
        case SAVE_DECK_CARD_ERROR:
        case FETCH_UNPARENTED_CARDS_ERROR:
        case REMOVE_CARD_FROM_DECK_ERROR:
        case ADD_CARD_TO_DECK_ERROR:
        case SORT_DECK_CARDS_ERROR:
            return {
                ...state,
                loading: false,
                allLoading: false,
                error: action.payload
            }
        default:
            return state;
    }
}