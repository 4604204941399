import { DELETE_MEDIA_RESOURCE, DELETE_MEDIA_RESOURCE_ERROR, DELETE_MEDIA_RESOURCE_SUCCESS, FETCH_MEDIA_RESOURCES, FETCH_MEDIA_RESOURCES_ERROR, FETCH_MEDIA_RESOURCES_SUCCESS, SAVE_MEDIA_RESOURCE, SAVE_MEDIA_RESOURCE_ERROR, SAVE_MEDIA_RESOURCE_SUCCESS, UPDATE_MEDIA_RESOURCE, UPDATE_MEDIA_RESOURCE_ERROR, UPDATE_MEDIA_RESOURCE_SUCCESS } from "../constants/ActionTypes";


const initialState = {
    mediaResources: [],
    error: null,
    loading: false
}

export default function mediaResourcesReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_MEDIA_RESOURCES:
        case SAVE_MEDIA_RESOURCE:
        case DELETE_MEDIA_RESOURCE:
        case UPDATE_MEDIA_RESOURCE:
            return {
                ...state,
                loading: true
            }
        case FETCH_MEDIA_RESOURCES_SUCCESS:
            return {
                ...state,
                loading: false,
                mediaResources: action.payload
            }
        case SAVE_MEDIA_RESOURCE_SUCCESS:
            return {
                ...state,
                loading: false,
                mediaResources: [...state.mediaResources, action.payload]
            }
        case DELETE_MEDIA_RESOURCE_SUCCESS:
            return {
                ...state,
                loading: false,
                mediaResources: state.mediaResources.filter(mediaResource => mediaResource.id !== action.payload)
            }
        case UPDATE_MEDIA_RESOURCE_SUCCESS:
            return {
                ...state,
                loading: false,
                mediaResources: state.mediaResources.map(mediaResource => mediaResource.id === action.payload.id ? action.payload : mediaResource)
            }
        case FETCH_MEDIA_RESOURCES_ERROR:
        case SAVE_MEDIA_RESOURCE_ERROR:
        case DELETE_MEDIA_RESOURCE_ERROR:
        case UPDATE_MEDIA_RESOURCE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}