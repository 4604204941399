import { Delete, CloudUpload, Link } from '@mui/icons-material'
import VisuallyHiddenInput from './VisuallyHiddenInput'
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Snackbar, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { copyToClipboard } from '../../utils/clipboard'

export default function ImageContainerPreview({ title, image, name, handleRemove, handleImageUpload, showCopyUrlButton, ...props }) {
    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleCopyUrl = () => {
        if (image) {
            copyToClipboard(image);
            setSnackbarMessage('URL copied to clipboard!');
            setOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpen(false);
        setSnackbarMessage('');
    };

    return (
        <Card>
            <CardActionArea>
                {image ? (
                    <CardMedia
                        component="img"
                        image={image}
                        alt={title}
                        {...props}
                    />
                ) : <CardMedia
                    component='div'
                    {...props}
                />
                }
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                {image ? (
                    <>
                        <Button size="small" color="error" startIcon={<Delete />} name={name} onClick={handleRemove}>
                            Remove
                        </Button>
                        {showCopyUrlButton && (
                            <Tooltip title={image} placement="bottom">
                                <Button size="small" color="primary" startIcon={<Link />} onClick={handleCopyUrl}>
                                    Copy URL
                                </Button>
                            </Tooltip>
                        )}
                    </>
                ) : (
                    <Button
                        fullWidth
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUpload />}
                    >
                        Add
                        <VisuallyHiddenInput type="file" name={name} onChange={handleImageUpload} />
                    </Button>
                )}
            </CardActions>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </Card>
    )
}
