import { FETCH_FEEDBACKS } from '../constants/ActionTypes'

const INITIAL_STATE = {
  feedbacks: {
    data: []
  }
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_FEEDBACKS:
      return { ...state, feedbacks: action.payload };
    default:
      return state;
  }
}