import {
  FETCH_ALL_QUICK_TAPS,
  FETCH_ALL_QUICK_TAPS_START,
  FETCH_TAGS
} from "../constants/ActionTypes";

const INITIAL_STATE = {
  isLoading: false,
  all_quick_tap_sessions: null,
  current_tags: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_QUICK_TAPS_START:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_ALL_QUICK_TAPS:
      return { ...state, all_quick_tap_sessions: action.payload, isLoading: false };
    case FETCH_TAGS:
      console.log("FETCH TAGS, payload", action.payload);
      return { ...state, current_tags: action.payload };
    default:
      return state;
  }
}
