import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderBar from "../HeaderBar/HeaderBar";
import MainContentContainer from "../MainContentContainer/MainContentContainer";
import { uploadImage, clearMedia } from "../../actions/mediaActions";
import { fetchAllCategories } from "../../actions/categoryActions";
import SortableSessions from "../AddEditSubCategory/SortableSessions/SortableSessions";
import {
  fetchOneSubcategory,
  addEditAudioBook,
  deleteSubCategory,
  clearAllSubCategories,
} from "../../actions/subcategoryActions";
import { arrayMoveImmutable } from "array-move";
import { fetchAllSessions } from "../../actions/sessionActions";

import Select from "react-select";
import "react-select/dist/react-select.css";

import Swal from "sweetalert2";

class AddEditAudioBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subcategory_title: "",
      subcategory_description: "",
      subcategory_is_active: 1,
      subcategory_image: "",
      subcategory_image_preview: "",
      subcategory_image_uploading: false,
      subcategory_grayscale_image: "",
      subcategory_grayscale_image_preview: "",
      subcategory_grayscale_image_uploading: false,
      subcategory_text_page_url: "",
      subcategory_text_image_url: "",
      subcategory_text_image_url_preview: "",
      subcategory_text_image_url_uploading: false,
      subcategory_is_dark_mode: 0,
      subcategory_is_book: 1,
      category: 0,
      sessions: [],
      quotes: [],
      isSetToEdit: false,
    };
  }

  componentDidMount() {
    this.props.fetchAllCategories();
    this.props.clearMedia();
    this.props.fetchAllSessions();
    this.props.clearAllSubCategories();

    if (this.props.isEdit) {
      this.props.fetchOneSubcategory(this.props.selectedId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.isEdit &&
      nextProps.subcategories &&
      !this.state.isSetToEdit
    ) {
      this.setSubCategoryForEditing(nextProps);
    }

    if (nextProps.image) {
      if (this.state.subcategory_image_uploading) {
        this.setState({
          subcategory_image: nextProps.image,
          subcategory_image_uploading: false,
        });
        this.props.clearMedia();
      }

      if (this.state.subcategory_grayscale_image_uploading) {
        this.setState({
          subcategory_grayscale_image: nextProps.image,
          subcategory_grayscale_image_uploading: false,
        });
        this.props.clearMedia();
      }
      if (this.state.subcategory_text_image_url_uploading) {
        this.setState({
          subcategory_text_image_url: nextProps.image,
          subcategory_text_image_url_uploading: false,
        });
        this.props.clearMedia();
      }
    }
  }

  setSubCategoryForEditing = ({ subcategories }) => {
    let currentSubcategory = subcategories[this.props.selectedId];
    let sessions = currentSubcategory.sessions;
    console.log("editing ", this.state.isSetToEdit, sessions);
    this.setState({
      ...currentSubcategory,
      sessions: sessions,
      category: currentSubcategory.category_id,
      isSetToEdit: true,
    });
  };

  handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  handleSessionData = (e, isFeatured = true) => {
    const id = e.target.name;
    const value = e.target.checked;

    console.log("haldne session change ", id, value, isFeatured);
    const newSessions = this.state.sessions.reduce((acc, obj) => {
      if (Number(obj.session_id) === Number(id)) {
        if (isFeatured) {
          obj.session_is_featured = Number(value);
        } else {
          console.log("hit here");
          obj.session_is_dark_mode = Number(value);
        }
      }
      acc.push(obj);
      return acc;
    }, []);

    this.setState({
      sessions: newSessions,
    });
  };

  handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.subcategory_image_preview) {
      window.URL.revokeObjectURL(this.state.subcategory_image_preview);
    }
    this.setState({
      subcategory_image_preview: window.URL.createObjectURL(file),
      subcategory_image_uploading: true,
    });
    this.props.uploadImage(file);
  };

  handleGrayscaleImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.subcategory_grayscale_image_preview) {
      window.URL.revokeObjectURL(
        this.state.subcategory_grayscale_image_preview
      );
    }
    this.setState({
      subcategory_grayscale_image_preview: window.URL.createObjectURL(file),
      subcategory_grayscale_image_uploading: true,
    });
    this.props.uploadImage(file);
  };

  renderCategoryOptions = () => {
    let { all_categories } = this.props;
    if (!all_categories) return [];
    all_categories = all_categories.filter(
      (el) => el.category_is_audiobook === 1
    );
    const options = all_categories.map((obj) => ({
      value: obj.category_id,
      label: obj.category_title,
    }));

    return [{ label: "None", value: 0 }, ...options];
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    console.log(this.state.sessions);
    this.setState({
      sessions: arrayMoveImmutable(this.state.sessions, oldIndex, newIndex),
    });
  };

  removeQuote = (index) => {
    this.setState((prevState) => {
      const state = { ...prevState };
      state.quotes.splice(index, 1);
      return state;
    });
  };

  addQuote = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      const state = { ...prevState };
      state.quotes.push({
        speaker: "",
        quote_text: "",
      });
      return state;
    });
  };

  renderQuotes = () => {
    return this.state.quotes.map((obj, i) => {
      return (
        <div
          key={"quote" + i}
          style={{ margin: "20px 0", alignItems: "center" }}
          className="utility-flex-row"
        >
          <textarea
            type="text"
            className="utility-textarea"
            value={obj.quote_text}
            style={{ width: "624px" }}
            onChange={(e) => {
              const { value } = e.target;
              this.setState((prevState) => {
                const state = { ...prevState };
                state.quotes[i].quote_text = value;
                return state;
              });
            }}
            required
          />
          <button
            className="utility-btn-danger"
            style={{ margin: "0 10px" }}
            onClick={(e) => {
              e.preventDefault();
              this.removeQuote(i);
            }}
          >
            Remove Quote
          </button>
        </div>
      );
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Saving...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    let {
      quotes: quotes_json,
      category: category_id,
      sessions: session_ids_json,
      ...subcategory_json
    } = this.state;

    quotes_json = quotes_json.filter((obj) => obj.quote_text !== "");
    console.log("category", category_id);
    const options = {
      quotes_json: JSON.stringify(quotes_json),
      category_id: category_id,
      subcategory_json: JSON.stringify(subcategory_json),
      session_ids_json: JSON.stringify(session_ids_json),
    };

    this.props.addEditAudioBook(options);
  };

  handleDelete = (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "warning",
      text: "Are you sure you want to delete this category?",
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Delete Category",
    }).then((result) => {
      if (result.value) {
        this.props.deleteSubCategory(this.state.subcategory_id);
      }
    });
  };

  handleCheckBox = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    console.log("name", name);
    this.setState({
      [name]: value === true ? 1 : 0,
    });
  };

  handleDeepLinkImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.subcategory_text_image_url_preview) {
      window.URL.revokeObjectURL(this.state.subcategory_text_image_url_preview);
    }
    this.setState({
      subcategory_text_image_url_preview: window.URL.createObjectURL(file),
      subcategory_text_image_url_uploading: true,
    });
    this.props.uploadImage(file);
  };

  render() {
    const { isEdit, all_categories, all_sessions } = this.props;
    console.log("SUBCAT PROPS", this.props);
    if (!all_sessions || !all_categories) {
      return <h3>Loading...</h3>;
    }
    console.log("SubCatState", this.state);

    return (
      <form
        className="addEditAudioBook"
        onSubmit={this.handleSubmit}
        autoComplete="off"
      >
        <HeaderBar header={isEdit ? "Edit AudioBook" : "Create New AudioBook"}>
          <Link
            to="/subcategories"
            className="utility-btn-transparent utility-lr-margin-small"
          >
            Cancel
          </Link>
          <button className="utility-btn-primary" type="submit">
            {isEdit ? "Update" : "Create"}
          </button>
        </HeaderBar>
        <MainContentContainer subContent={true}>
          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>AudioBook Name</p>
              <input
                type="text"
                className="utility-text-input"
                name="subcategory_title"
                value={this.state.subcategory_title}
                onChange={this.handleFieldChange}
                style={{ width: "500px" }}
                required
              />
            </label>
            <label className="utility-inline-label">
              <p>Status</p>
              <Select
                value={this.state.subcategory_is_active}
                onChange={(e) =>
                  this.setState({ subcategory_is_active: e.value })
                }
                style={{ width: "150px" }}
                clearable={false}
                searchable={false}
                options={[
                  { value: 1, label: "Active" },
                  { value: 0, label: "Not Active" },
                ]}
              />
            </label>
            <label className="utility-inline-label">
              <p>Dark Mode</p>
              <input
                name="subcategory_is_dark_mode"
                type="checkbox"
                value={this.state.subcategory_is_dark_mode === 0 ? false : true}
                onChange={this.handleCheckBox}
                checked={
                  this.state.subcategory_is_dark_mode === 0 ? false : true
                }
                style={{ width: "80px" }}
                clearable={undefined}
                searchable={undefined}
              />
            </label>
          </div>
          {/*
            Description for subcategories is not being used
            commenting out for client
          */}

          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Description</p>
              <textarea
                className="utility-textarea"
                name="subcategory_description"
                value={this.state.subcategory_description}
                onChange={this.handleFieldChange}
                style={{ width: "658px" }}
                required
              />
            </label>
          </div>

          <div className="utility-flex-row">
            <div className="utility-inline-label">
              <p>Image</p>
              <div className="utility-flex-row">
                {(this.state.subcategory_image_preview ||
                  this.state.subcategory_image) && (
                    <img
                      src={
                        this.state.subcategory_image_preview ||
                        this.state.subcategory_image
                      }
                      alt="Subcategory Main"
                      style={{
                        maxHeight: "124px",
                        maxWidth: "150px",
                        marginRight: "20px",
                      }}
                    />
                  )}
                <label>
                  <input
                    type="file"
                    onChange={this.handleImageUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Image
                  </p>
                </label>
              </div>
            </div>

            {/* <div className="utility-inline-label">
              <p>Grayscale Image</p>
              <div className="utility-flex-row">
                {
                (this.state.subcategory_grayscale_image_preview || this.state.subcategory_grayscale_image) &&
                <img src={this.state.subcategory_grayscale_image_preview || this.state.subcategory_grayscale_image} alt="Subcategory Grayscale"
                     style={{maxHeight: '124px',maxWidth: '150px', marginRight: '20px'}}
                />
                }
                <label>
                  <input type='file' onChange={this.handleGrayscaleImageUpload} accept="image/*" style={{display: 'none'}}/>
                  <p
                    className="utility-btn-primary"
                    style={{color: '#fff'}}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Image
                  </p>
                </label>
              </div>
            </div> */}
          </div>

          <div className="utility-flex-row">
            <div className="utility-inline-label">
              <label className="utility-inline-label">
                <p>Deep Link URL</p>
                <input
                  type="text"
                  className="utility-text-input"
                  name="subcategory_text_page_url"
                  value={this.state.subcategory_text_page_url}
                  onChange={this.handleFieldChange}
                />
              </label>
              <div className="utility-inline-label">
                <p>Deep Link Image</p>
                <div className="utility-flex-row">
                  {(this.state.subcategory_text_image_url_preview ||
                    this.state.subcategory_text_image_url) && (
                      <img
                        src={
                          this.state.subcategory_text_image_url_preview ||
                          this.state.subcategory_text_image_url
                        }
                        alt="Profile"
                        style={{
                          maxHeight: "124px",
                          maxWidth: "150px",
                          marginRight: "20px",
                        }}
                      />
                    )}
                  <label>
                    <input
                      type="file"
                      onChange={this.handleDeepLinkImageUpload}
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                    <p
                      className="utility-btn-primary"
                      style={{ color: "#fff" }}
                      tabIndex="0"
                      onKeyPress={this.clickParent}
                    >
                      Upload Image
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="utility-flex-row">
              <div className="utility-inline-label">
                <p>Category</p>
                <Select
                  value={this.state.category}
                  onChange={(e) => this.setState({ category: e.value })}
                  style={{ width: "450px", marginBottom: "40px" }}
                  clearable={false}
                  searchable={false}
                  options={this.renderCategoryOptions()}
                />
              </div>
            </div>
          </div>

          {isEdit && !!this.state.sessions && (
            <div className="utility-inline-label">
              <p>Session Order</p>
              <SortableSessions
                items={this.state.sessions}
                onSortEnd={this.onSortEnd}
                sessions={this.props.all_sessions}
                state={this.state}
                handleSessionData={this.handleSessionData}
              />
            </div>
          )}

          <div className="AddEditCategory-quotes">
            <div className="utility-flex-row">
              <div className="utility-inline-label">
                <p>Quotes</p>
              </div>
            </div>
            {this.renderQuotes()}
            <button
              className="utility-btn-primary"
              onClick={this.addQuote}
              style={{ margin: "20px 0" }}
            >
              Add Quote
            </button>
          </div>

          {isEdit && (
            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <button
                className="utility-btn-danger"
                onClick={this.handleDelete}
              >
                Delete Subcategory
              </button>
            </div>
          )}
        </MainContentContainer>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    all_categories: state.categories.all_categories,
    all_sessions: state.sessions.all_sessions,
    subcategories: state.subcategories.subcategories,
    image: state.media.image,
  };
}

const mapDispatchToProps = {
  fetchAllCategories,
  fetchAllSessions,
  fetchOneSubcategory,
  uploadImage,
  clearMedia,
  addEditAudioBook,
  deleteSubCategory,
  clearAllSubCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAudioBook);
