import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCategories,
  clearCategories,
  sortCategories,
} from "./../../actions/categoryActions";
import { Link } from "react-router-dom";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import SortableCategoryItem from "./SortableCategories/SortableCategoryItem";
import {
  Box,
  Container,
  InputBase,
  Typography,
  Paper,
  Toolbar,
  AppBar,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Add, Search, Close } from "@mui/icons-material";

const CategoriesTable = () => {
  const dispatch = useDispatch();
  const allCategories = useSelector((state) => state.categories.all_categories);
  const highlightIndex = useSelector((state) => state.highlight_index);

  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [highlightIndexState, setHighlightIndexState] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    dispatch(clearCategories());
    dispatch(fetchAllCategories());
  }, [dispatch]);

  useEffect(() => {
    if (allCategories && categories.length === 0) {
      const filtered = allCategories
        .filter((el) => el.category_is_audiobook === 0)
        .sort((a, b) => Number(a.category_sort_order) - Number(b.category_sort_order));
      setCategories(filtered);
    }
  }, [allCategories, categories]);

  useEffect(() => {
    if (highlightIndex !== highlightIndexState) {
      setHighlightIndexState(highlightIndex);
    }
  }, [highlightIndex, highlightIndexState]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = categories.findIndex((item) => item.category_id === active.id);
      const newIndex = categories.findIndex((item) => item.category_id === over.id);
      const newCategories = arrayMove(categories, oldIndex, newIndex);
      setCategories(newCategories);
      setHighlightIndexState(newIndex);
      const sortJson = newCategories.map((obj) => obj.category_id);
      dispatch(sortCategories(JSON.stringify(sortJson)));
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    let search = value.toLowerCase();
    const filtered =
      value.length > 0
        ? categories.filter((obj) => obj.category_title.toLowerCase().includes(search))
        : null;
    setSearchText(value);
    setFilteredCategories(filtered);
  };

  const handleToggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
    if (isSearchOpen) {
      setSearchText("");
      setFilteredCategories(null);
    }
  };

  if (!categories.length) {
    return <h1 style={{ textAlign: "center" }}>Loading</h1>;
  }

  return (
    <Container sx={{ py: 2 }}>
      <AppBar position="sticky">
        <Toolbar>
          {isSearchOpen ? (
            <Box display="flex" alignItems="center" flexGrow={1}>
              <InputBase
                value={searchText}
                onChange={handleSearchChange}
                sx={{ flexGrow: 1, px: 1, border: "1px solid #ccc", borderRadius: 1, backgroundColor: "white" }}
                placeholder="Search..."
              />
              <IconButton color="inherit" onClick={handleToggleSearch}>
                <Close />
              </IconButton>
            </Box>
          ) : (
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Categories
            </Typography>
          )}
          <IconButton color="inherit" onClick={handleToggleSearch}>
            <Search />
          </IconButton>
          <Button color="inherit" component={Link} to={`/category/add`} startIcon={<Add />}>
            Add New
          </Button>
        </Toolbar>
      </AppBar>

      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <TableContainer component={Paper} sx={{ marginTop: 3 }}>
          <Table aria-label="categories table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5', position: 'sticky', top: 0, zIndex: 1 }}>
                <TableCell><strong>Sort Order</strong></TableCell>
                <TableCell><strong>Title</strong></TableCell>
                <TableCell><strong>Subcategories</strong></TableCell>
                <TableCell><strong>Sessions</strong></TableCell>
                <TableCell align="right"><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <SortableContext items={categories.map((item) => item.category_id)}>
                {(filteredCategories || categories).map((category, index) => (
                  <SortableCategoryItem
                    key={category.category_id}
                    id={category.category_id}
                    category={category}
                    index={index}
                    highlightIndex={highlightIndexState}
                  />
                ))}
              </SortableContext>
            </TableBody>
          </Table>
        </TableContainer>
      </DndContext>
    </Container>
  );
};

export default CategoriesTable;