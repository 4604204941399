import {
  FETCH_ALL_SUBCATEGORIES,
  FETCH_ONE_SUBCATEGORY,
  CLEAR_ALL_SUB_CATEGORIES,
  FETCH_ALL_SUBCATEGORIES_START,
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  isLoading: false,
  subcategories: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_SUBCATEGORIES_START:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_ALL_SUBCATEGORIES:
      return { ...state, subcategories: action.payload, isLoading: false };
    case FETCH_ONE_SUBCATEGORY:
      return { ...state,subcategories: {...state.subcategories, ...action.payload} };
    default:
      return state;
  }
}
