import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllSubCategories,
  clearAllSubCategories,
} from "../../actions/subcategoryActions";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  IconButton,
  InputBase,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  AppBar,
} from "@mui/material";
import { Add, Edit, Search, Close, CheckCircle, Cancel } from '@mui/icons-material';
import LoadingBackdrop from "../Common/LoadingBackdrop";

const AudioBookTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const subcategories = useSelector((state) =>
    state.subcategories.subcategories
      ? Object.values(state.subcategories.subcategories)
      : null
  );
  const loading = useSelector((state) => state.subcategories.isLoading);

  const [searchText, setSearchText] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    dispatch(clearAllSubCategories());
    dispatch(fetchAllSubCategories(1));
  }, [dispatch]);

  const handleEdit = (audiobook) => {
    history.push(`/audiobooks/edit/${audiobook.subcategory_id}`);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleToggleSearch = () => {
    setIsSearchOpen((prev) => !prev);
    if (isSearchOpen) {
      setSearchText("");
    }
  };

  const renderAudioBooks = () => {
    if (!subcategories) return null;

    let data = [...subcategories];
    data = data.filter((el) => el.subcategory_is_book === 1);

    if (searchText !== "") {
      const search = searchText.toLowerCase();
      data = data.filter(
        (obj) => obj.subcategory_title.toLowerCase().indexOf(search) > -1
      );
    }

    return data.map((obj) => (
      <TableRow key={obj.subcategory_id + obj.subcategory_title}>
        <TableCell>
          <Typography variant="subtitle1">{obj.subcategory_title}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{obj.category_title || "none"}</Typography>
        </TableCell>
        <TableCell align="right">
          <Tooltip title={obj.subcategory_is_active === 0 ? "Inactive" : "Active"} placement="top">
            <IconButton>
              {obj.subcategory_is_active === 0 ? (
                <Cancel sx={{ color: "red" }} />
              ) : (
                <CheckCircle sx={{ color: "green" }} />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit" placement="top">
            <IconButton color="info" onClick={() => handleEdit(obj)}>
              <Edit />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Container sx={{ py: 2 }}>
      <LoadingBackdrop open={loading} />
      <AppBar position="sticky">
        <Toolbar>
          {isSearchOpen ? (
            <Box display="flex" alignItems="center" flexGrow={1}>
              <InputBase
                value={searchText}
                onChange={handleSearchChange}
                sx={{ flexGrow: 1, px: 1, border: "1px solid #ccc", borderRadius: 1, backgroundColor: "white" }}
                placeholder="Search..."
              />
              <IconButton color="inherit" onClick={handleToggleSearch}>
                <Close />
              </IconButton>
            </Box>
          ) : (
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Audiobooks
            </Typography>
          )}
          <IconButton color="inherit" onClick={handleToggleSearch}>
            <Search />
          </IconButton>
          <Button
            color="inherit"
            component={Link}
            to={"/category/edit/32"}
            sx={{ mr: 1 }}
          >
            Manage
          </Button>
          <Button
            color="inherit"
            component={Link}
            to={"/audiobooks/add"}
            startIcon={<Add />}
          >
            New AudioBook
          </Button>
        </Toolbar>
      </AppBar>

      <TableContainer component={Paper} sx={{ marginTop: 3 }}>
        <Table aria-label="audiobooks table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
              <TableCell><strong>Title</strong></TableCell>
              <TableCell><strong>In Category</strong></TableCell>
              <TableCell align="right"><strong>Actions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderAudioBooks()}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AudioBookTable;