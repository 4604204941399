import { api, errorHandler, queryString } from "./index";
import { push } from "react-router-redux";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import {
  API_ERROR,
  FETCH_ALL_QUICK_TAPS,
  FETCH_ALL_GENERAL_DATA,
  CLEAR_HIGHLIGHT,
  FETCH_ALL_QUICK_TAPS_START,
} from "../constants/ActionTypes";

export function fetchAllQuickTapSessions() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_QUICK_TAPS_START
    });

    api.post(`/fetch-all-quick-tap-session-cms.php`)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_QUICK_TAPS,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function fetchOneQuickTapSession(quick_tap_id) {
  return function (dispatch) {
    api.post(`/fetch-one-quick-tap-session-cms.php`,
      queryString.stringify({
        quick_tap_id,
      })
    )
      .then((response) => {
        dispatch({
          type: FETCH_ALL_QUICK_TAPS,
          payload: [response.data],
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditQuickTapSession(options) {
  return function (dispatch) {
    api.post(`/add-n-edit-quick-tap-session.php`,
      queryString.stringify({
        timestamps_json: options.timestamps_json,
        subcategory_id: options.subcategory_id,
        category_id: options.category_id,
        quick_tap_session_json: options.quick_tap_session_json,
      })
    )
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Saved!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/quick-taps"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteQuickTapSession(quick_tap_id) {
  return function (dispatch) {
    api.post(`/delete-quick-tap-session.php`,
      queryString.stringify({
        quick_tap_id,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Session Deleted!",
          timer: 1000,
        }).then(() => {
          dispatch(push("/quick-taps"));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function clearQuickTapSessions() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_QUICK_TAPS,
      payload: null,
    });
  };
}

export async function fetchQuickTapsIntro() {
  return function (dispatch) {
    api.post(`/fetch-quick-tap-intro.php`)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_GENERAL_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export async function fetchQuickTapsFeaturedIntro() {
  return function (dispatch) {
    api.post(`/fetch-quick-tap-featured-intro.php`)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_GENERAL_DATA,
          payload: response.data,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export async function updateQuickTapsIntro(quickTapsIntro) {
  return updateQuickTapsIntroForKey(quickTapsIntro, 'quick_tap_intro');
}

export async function updateQuickTapsFeaturedIntro(quickTapsIntro) {
  return updateQuickTapsIntroForKey(quickTapsIntro, 'quick_taps_featured_intro');
}

async function updateQuickTapsIntroForKey(quickTapsIntro, introKey) {
  return async function (dispatch) {
    try {
      api.post(`/set-general-info.php`,
        queryString.stringify({
          general_info_key: introKey,
          general_info_value: quickTapsIntro,
        })
      );
    } catch (error) {
      return errorHandler(dispatch, error.response, API_ERROR);
    }
    Swal.fire({
      icon: "success",
      title: "Saved!",
      timer: 1000,
    });
  };
}

export function fetchAllQuickTapFeaturedSessions() {
  return function (dispatch) {
    api.post(`/fetch-all-featured-quick-tap-session-cms.php`)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_QUICK_TAPS,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updateQuickTapFeaturedOrder(featured_ids) {
  return function (dispatch) {
    api.post(`/update-featured-quick-tap-session-sort-order.php`,
      queryString.stringify({
        featured_ids: featured_ids
      })
    )
      .then(() => {
        dispatch({
          type: CLEAR_HIGHLIGHT,
          payload: null
        });
      })
      .catch((error) => {
        dispatch({
          type: CLEAR_HIGHLIGHT,
          payload: null
        });
        fetchAllQuickTapFeaturedSessions()(dispatch);
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
