import SessionHelper from "../components/Helper/SessionHelper";
import { DELETE_ADMIN, DELETE_ADMIN_ERROR, DELETE_ADMIN_SUCCESS, FETCH_ALL_ADMINS, FETCH_ALL_ADMINS_ERROR, FETCH_ALL_ADMINS_SUCCESS, FETCH_ONE_ADMIN, FETCH_ONE_ADMIN_SUCCESS, SAVE_ADMIN, SAVE_ADMIN_ERROR, SAVE_ADMIN_SUCCESS, FETCH_ONE_ADMIN_ERROR, RESET_PASSWORD_ADMIN, RESET_PASSWORD_ADMIN_SUCCESS, RESET_PASSWORD_ADMIN_ERROR, UPDATE_PROFILE_ADMIN_ERROR, UPDATE_PROFILE_ADMIN, UPDATE_PROFILE_ADMIN_SUCCESS } from "../constants/ActionTypes";


const initialState = {
    admins: [],
    error: null,
    loading: false,
    admin: null
}

export default function adminsReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_ALL_ADMINS:
        case SAVE_ADMIN:
        case DELETE_ADMIN:
        case FETCH_ONE_ADMIN:
        case RESET_PASSWORD_ADMIN:
        case UPDATE_PROFILE_ADMIN:
            return {
                ...state,
                loading: true
            }
        case FETCH_ALL_ADMINS_SUCCESS:
            return {
                ...state,
                loading: false,
                admins: action.payload
            }
        case DELETE_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                admins: state.admins.filter(admin => admin.admin_id !== action.payload)
            }
        case FETCH_ONE_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                admin: action.payload
            }
        case RESET_PASSWORD_ADMIN_SUCCESS:
        case SAVE_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case UPDATE_PROFILE_ADMIN_SUCCESS:
            SessionHelper.setUserData(action.payload);
            return {
                ...state,
                loading:false
            }
        case FETCH_ALL_ADMINS_ERROR:
        case SAVE_ADMIN_ERROR:
        case DELETE_ADMIN_ERROR:
        case FETCH_ONE_ADMIN_ERROR:
        case RESET_PASSWORD_ADMIN_ERROR:
        case UPDATE_PROFILE_ADMIN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}