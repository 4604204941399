import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import queryString from "query-string";
import axios from 'axios';
import SessionHelper from "../components/Helper/SessionHelper";
import { history } from '../store/configureStore';

// Export queryString
export { queryString };  // Named export


const hostname = window && window.location && window.location.hostname;
let backendHost;

const DEV_URL = "http://localhost:4000/scripts";
const STAGING_URL = "https://staging.backend.thetappingsolution.com/scripts";
const PRODUCTION_URL = "https://ap1.thetappingsolution.com/scripts";

switch (hostname) {
  case "localhost":
    backendHost = DEV_URL;
    if (process.env.REACT_APP_DEV_URL_OVERRIDE) {
      backendHost = process.env.REACT_APP_DEV_URL_OVERRIDE;
    }
    break;
  case "dev-tapping-solution.netlify.com":
    backendHost = STAGING_URL;
    break;
  case "staging-tapping-solution.netlify.app":
    backendHost = STAGING_URL;
    break;
  case "cms.thetappingsolution.com":
    backendHost = PRODUCTION_URL;
    break;
  default:
    if (hostname.includes('-prod-tapping-solution.netlify.app')) {
      backendHost = PRODUCTION_URL;
    } else {
      backendHost = STAGING_URL;
    }
}
export const API_URL = backendHost;

//= ===============================
// Utility actions
//= ===============================

export function createError(message) {
  return {
    data: {
      message,
    },
  };
}

export function errorHandler(dispatch, error, type) {
  if (!error) {
    return false;
  }

  Swal.fire({
    icon: "warning",
    text: error && error.data ? error.data.message : error.data,
  });
}

export function generalErrorHandle(message) {
  Swal.fire({
    icon: "error",
    text: message,
  });
}

export const api = axios.create({
  baseURL: API_URL,
  headers: {
  },
});

export const anonymousApi = axios.create({
  baseURL: API_URL,
  headers: {
  },
});

// Add a request interceptor to automatically append the token
api.interceptors.request.use(
  (config) => {
    const user_id = SessionHelper.currentUser().admin_id;
    const token = SessionHelper.getToken();
    if (token) {
      if ( config.data instanceof FormData) {
        console.log('Is form data');
        config.data.append('user_token', token);
        config.data.append('requesting_user_id', user_id);
        return config;
      }

      // Parse the existing request data and append the token
      const parsedData = queryString.parse(config.data || '');
      parsedData['user_token'] = token;
      parsedData['requesting_user_id'] = user_id;
      // Re-stringify the data to be sent as form data
      config.data = queryString.stringify(parsedData);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle 401 errors
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error
      SessionHelper.clear();
      history.push('/login');
    } else {
      return Promise.reject(error);
    }
  }
);