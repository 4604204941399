import { FETCH_ALL_SERIES, FETCH_ALL_SERIES_START } from "../constants/ActionTypes";

const INITIAL_STATE = {
  isLoading: false,
  all_series: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_SERIES_START:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_ALL_SERIES:
      return { ...state, all_series: action.payload, isLoading: false };
    default:
      return state;
  }
}
