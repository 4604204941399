import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box, Paper } from '@mui/material';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Save, Cancel } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import DeckForm from './DeckForm';
import { closeEditCardDeck, fetchOneDeck, saveCardDeck } from '../../../actions/cardDeckActions';
import { fetchAllCategories } from "../../../actions/categoryActions";
import LoadingBackdrop from '../../Common/LoadingBackdrop';
import { fetchAllSubCategories } from '../../../actions/subcategoryActions';

function AddEditCardDeck() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [deck, setDeck] = useState({
    name: '',
    description: '',
    category_id: '',
    primary_color_hex: '#FFFFFF',
    module_description: '',
    module_image_url: '',
    header_image_url: '',
    wrapper_image_url: '',
    search_result_image_url: '',
    share_link_description: '',
    share_image_url: '',
    share_deeplink_url: '',
    purchase_store_text: '',
    purchase_store_url: '',
    see_all_description: '',
    sort_order: 0,
    free_view_limit: 1,
    is_premium: 1,
    is_active: 0,
    is_deleted: 0,
  });

  const isLoading = useSelector((state) => state.card_decks.loading);
  const deckStore = useSelector((state) => state.card_decks.deck);

  useEffect(() => {
    if (id && !deckStore) {
      dispatch(fetchOneDeck(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    fetchAllResources();
  }, [])

  useEffect(() => {
    if (deckStore) {
      setDeck(deckStore);
    }
  }, [deckStore]);

  const handleSave = () => {
    dispatch(saveCardDeck(deck));
  };

  const handleCancel = () => {
    dispatch(closeEditCardDeck())
  };

  const fetchAllResources = () => {
    dispatch(fetchAllCategories());
    dispatch(fetchAllSubCategories());
  }

  return (
    <Container sx={{ paddingBottom: 4 }}>
      <LoadingBackdrop open={isLoading || (id && !deck)} />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {id && deck ? `Edit Deck: ${deck.name}` : 'Create New Deck'}
          </Typography>
          <Button color="success" onClick={handleSave} startIcon={<Save />} variant="contained" sx={{ marginRight: 2 }}>
            Save
          </Button>
          <Button color="error" startIcon={<Cancel />} variant="contained" onClick={handleCancel}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Box gap={4}>
        <Paper sx={{ padding: 2 }}>
          {deck && <DeckForm deck={deck} setDeck={setDeck} />}
        </Paper>
      </Box>
    </Container>
  );
}

export default AddEditCardDeck;
