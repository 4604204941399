import { push } from 'react-router-redux';
import { api, errorHandler, queryString } from './index';
import {
  API_ERROR,
  FETCH_ALL_QUOTES,

} from '../constants/ActionTypes';
import Swal from 'sweetalert2';

export function fetchAllQuotes() {
  return function (dispatch) {
    api.post(`/fetch-all-quotes.php`)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_QUOTES,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR)
      });
  }
}

export function clearAllQuotes() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_QUOTES,
      payload: null,
    });
  }
}

export function addEditQuote(quote_json) {
  return function (dispatch) {
    api.post(`/add-n-edit-dashboard-quote.php`,
      queryString.stringify({
        quote_json: quote_json
      }))
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Quote Saved!',
          timer: 1000
        }).then(() => {
          dispatch(push('/quotes'));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR)
      });
  }
}

export function deleteQuote(quote_id) {
  return function (dispatch) {
    api.post(`/delete-quote.php`,
      queryString.stringify({
        quote_id: quote_id
      }))
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Quote Deleted!',
          timer: 1000
        }).then(() => {
          dispatch(push('/quotes'));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR)
      });
  }
}
