import { push } from 'react-router-redux';
import { api, errorHandler, queryString } from './index';
import {
  API_ERROR,
  FETCH_ALL_TAPPING_POINTS,
  CLEAR_TAPPING_POINTS
} from '../constants/ActionTypes';
import Swal from 'sweetalert2';

export function fetchTappingPoints() {
  return function (dispatch) {
    api.post(`/fetch-all-tapping-points.php`)
      .then(response => {
        dispatch({
          type: FETCH_ALL_TAPPING_POINTS,
          payload: response.data.objects
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addEditTappingPoint(tapping_point_json) {
  return function (dispatch) {
    api.post(`/add-n-edit-tapping-points.php`,
      queryString.stringify({
        tapping_point_json: tapping_point_json,
      })
    )
      .then(response => {
        Swal.fire({
          icon: 'success',
          title: 'Saved!',
          timer: 1000,
        }).then(() => {
          dispatch(push('/tapping-points'));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteTappingPoint(tapping_point_id) {
  return function (dispatch) {
    api.post(`/delete-tapping-point.php`,
      queryString.stringify({
        tapping_point_id: tapping_point_id,
      }))
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Tapping Point Deleted!',
          timer: 1000
        }).then(() => {
          dispatch(push('/tapping-points'));
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR)
      });
  }
}

export function clearTappingPoints() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_TAPPING_POINTS
    });
  };
}
