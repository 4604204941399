import { api, errorHandler, queryString } from './index';
import {
  API_ERROR,
  FETCH_FEEDBACKS,
} from '../constants/ActionTypes';

export function fetchFeedbacks(page, page_size) {
  return function (dispatch) {
    api.post(`/fetch-all-feedbacks.php`,
      queryString.stringify({
        page: page,
        page_size: page_size
      })
    )
      .then(response => {
        dispatch({
          type: FETCH_FEEDBACKS,
          payload: response.data
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

