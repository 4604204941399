import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderBar from "./../HeaderBar/HeaderBar";
import MainContentContainer from "./../MainContentContainer/MainContentContainer";
import { uploadImage, clearMedia } from "./../../actions/mediaActions";
import { fetchAllCategories } from "./../../actions/categoryActions";
import SortableSessions from "./SortableSessions/SortableSessions";
import {
  fetchAllSeries,
  deleteSeries,
  addEditSeries,
} from "./../../actions/seriesActions";
import { arrayMoveImmutable } from "array-move";
import { fetchAllSessions } from "./../../actions/sessionActions";

import Select from "react-select";
import "react-select/dist/react-select.css";

import Swal from "sweetalert2";

class AddEditSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series_title: "",
      series_description: "",
      series_is_active: 1,
      series_image: "",
      series_image_preview: "",
      series_image_uploading: false,
      series_grayscale_image: "",
      series_grayscale_image_preview: "",
      series_grayscale_image_uploading: false,
      series_description: "",
      series_text_page_url: "",
      series_text_image_url: "",
      series_text_image_url_preview: "",
      series_text_image_url_uploading: false,
      series_is_dark_mode: 0,
      category: 0,
      sessions: [],
      isSetToEdit: false,
      series_is_new: 0,
    };
  }
  componentDidMount() {
    this.props.fetchAllCategories();
    this.props.clearMedia();
    this.props.fetchAllSessions();
    if (this.props.isEdit) {
      this.props.fetchAllSeries();
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isEdit && nextProps.series && !this.state.isSetToEdit) {
      this.setSeriesForEditing(nextProps);
    }

    if (nextProps.image) {
      if (this.state.series_image_uploading) {
        this.setState({
          series_image: nextProps.image,
          series_image_uploading: false,
        });
        this.props.clearMedia();
      }

      if (this.state.series_grayscale_image_uploading) {
        this.setState({
          series_grayscale_image: nextProps.image,
          series_grayscale_image_uploading: false,
        });
        this.props.clearMedia();
      }
      if (this.state.series_text_image_url_uploading) {
        this.setState({
          series_text_image_url: nextProps.image,
          series_text_image_url_uploading: false,
        });
        this.props.clearMedia();
      }
    }
  }

  setSeriesForEditing = ({ series }) => {
    let currentSeries = null;
    for (let x = 0; x < series.length; x++) {
      if (series[x].series_id === Number(this.props.selectedId)) {
        currentSeries = series[x];
        break;
      }
    }
    currentSeries.sessions = currentSeries.sessions.map((obj, i) => {
      return {
        [obj.session_id]: {
          series_day: obj.series_day,
          session_is_dark_mode: obj.session_is_dark_mode,
        },
      };
    });

    console.log("currentSeries", currentSeries);

    currentSeries.category = currentSeries.category;
    this.setState({ ...currentSeries, isSetToEdit: true });
  };

  handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  handleCheckBox = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    console.log("name", name);
    this.setState({
      [name]: value === true ? 1 : 0,
    });
  };

  handleSeriesDay = (e, isDays = true) => {
    const id = e.target.name;
    const value = e.target.value;

    console.log("hit here ", value);
    const newSessions = this.state.sessions.reduce((acc, obj) => {
      if (obj[id]) {
        const obj_data = obj[id];
        if (isDays) {
          console.log("hit here ", value);
          obj_data.series_day = Number(value);
        } else {
          const value = e.target.checked;
          obj_data.session_is_dark_mode = Number(value);
        }
      }
      acc.push(obj);
      return acc;
    }, []);

    this.setState({
      sessions: newSessions,
    });
  };

  handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.series_image_preview) {
      window.URL.revokeObjectURL(this.state.series_image_preview);
    }
    this.setState({
      series_image_preview: window.URL.createObjectURL(file),
      series_image_uploading: true,
    });
    this.props.uploadImage(file);
  };

  handleGrayscaleImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.series_grayscale_image_preview) {
      window.URL.revokeObjectURL(this.state.series_grayscale_image_preview);
    }
    this.setState({
      series_grayscale_image_preview: window.URL.createObjectURL(file),
      series_grayscale_image_uploading: true,
    });
    this.props.uploadImage(file);
  };

  handleDeepLinkImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.series_text_image_url_preview) {
      window.URL.revokeObjectURL(this.state.series_text_image_url_preview);
    }
    this.setState({
      series_text_image_url_preview: window.URL.createObjectURL(file),
      series_text_image_url_uploading: true,
    });
    this.props.uploadImage(file);
  };

  renderCategoryOptions = () => {
    const { all_categories } = this.props;
    if (!all_categories) return [];

    const options = all_categories.map((obj) => ({
      value: obj.category_id,
      label: obj.category_title,
    }));

    return [{ label: "None", value: 0 }, ...options];
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      sessions: arrayMoveImmutable(this.state.sessions, oldIndex, newIndex),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Saving...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });
    let { category, sessions: session_ids_json, ...series_json } = this.state;
    // let newSessionJson = {};
    let newSessionJson = session_ids_json.map((obj) => {
      return obj;
      //below for pushing object - temp in case we need to switch back
      // Object.keys(obj)[0] = obj[Object.keys(obj)[0]];
    });

    console.log("newSessionJson", newSessionJson);
    const options = {
      category_id: category,
      series_json: JSON.stringify(series_json),
      session_ids_json: JSON.stringify(newSessionJson),
    };
    this.props.addEditSeries(options);
  };

  handleDelete = (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "warning",
      text: "Are you sure you want to delete this series?",
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Delete Series",
    }).then((result) => {
      if (result.value) {
        this.props.deleteSeries(this.state.series_id);
      }
    });
  };

  render() {
    console.log("props", this.props);
    console.log("state", this.state);

    const { isEdit, all_categories, all_sessions } = this.props;
    if (!all_sessions || !all_categories) {
      return <h3>Loading...</h3>;
    }

    return (
      <form
        className="AddEditSeries"
        onSubmit={this.handleSubmit}
        autoComplete="off"
      >
        <HeaderBar header={isEdit ? "Edit Series" : "Create New Series"}>
          <Link
            to="/series"
            className="utility-btn-transparent utility-lr-margin-small"
          >
            Cancel
          </Link>
          <button className="utility-btn-primary" type="submit">
            {isEdit ? "Update" : "Create"}
          </button>
        </HeaderBar>
        <MainContentContainer subContent={true}>
          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Series Name</p>
              <input
                type="text"
                className="utility-text-input"
                name="series_title"
                value={this.state.series_title}
                onChange={this.handleFieldChange}
                style={{ width: "500px" }}
                required
              />
            </label>
            <label className="utility-inline-label">
              <p>Status</p>
              <Select
                value={this.state.series_is_active}
                onChange={(e) => this.setState({ series_is_active: e.value })}
                style={{ width: "150px" }}
                clearable={false}
                searchable={false}
                options={[
                  { value: 1, label: "Active" },
                  { value: 0, label: "Not Active" },
                ]}
              />
            </label>
            <label className="utility-inline-label">
              <p>Is New</p>
              <input
                name="series_is_new"
                type="checkbox"
                value={this.state.series_is_new === 0 ? false : true}
                onChange={this.handleCheckBox}
                checked={this.state.series_is_new === 0 ? false : true}
                style={{ width: "80px" }}
                clearable={undefined}
                searchable={undefined}
              />
            </label>
            <label className="utility-inline-label">
              <p>Dark Mode</p>
              <input
                name="series_is_dark_mode"
                type="checkbox"
                value={this.state.series_is_dark_mode === 0 ? false : true}
                onChange={this.handleCheckBox}
                checked={this.state.series_is_dark_mode === 0 ? false : true}
                style={{ width: "80px" }}
                clearable={undefined}
                searchable={undefined}
              />
            </label>
          </div>

          <div className="utility-flex-row">
            <div className="utility-inline-label">
              <p>Image</p>
              <div className="utility-flex-row">
                {(this.state.series_image_preview ||
                  this.state.series_image) && (
                    <img
                      src={
                        this.state.series_image_preview || this.state.series_image
                      }
                      alt="Series Main"
                      style={{
                        maxHeight: "124px",
                        maxWidth: "150px",
                        marginRight: "20px",
                      }}
                    />
                  )}
                <label>
                  <input
                    type="file"
                    onChange={this.handleImageUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Image
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div className="utility-flex-row">
            <div className="utility-inline-label">
              <label className="utility-inline-label">
                <p>Deep Link URL</p>
                <input
                  type="text"
                  className="utility-text-input"
                  name="series_text_page_url"
                  value={this.state.series_text_page_url}
                  onChange={this.handleFieldChange}
                />
              </label>
              <div className="utility-inline-label">
                <p>Deep Link Image</p>
                <div className="utility-flex-row">
                  {(this.state.series_text_image_url_preview ||
                    this.state.series_text_image_url) && (
                      <img
                        src={
                          this.state.series_text_image_url_preview ||
                          this.state.series_text_image_url
                        }
                        alt="Profile"
                        style={{
                          maxHeight: "124px",
                          maxWidth: "150px",
                          marginRight: "20px",
                        }}
                      />
                    )}
                  <label>
                    <input
                      type="file"
                      onChange={this.handleDeepLinkImageUpload}
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                    <p
                      className="utility-btn-primary"
                      style={{ color: "#fff" }}
                      tabIndex="0"
                      onKeyPress={this.clickParent}
                    >
                      Upload Image
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="utility-flex-row">
              <div className="utility-inline-label">
                <p>Category</p>
                <Select
                  value={this.state.category}
                  onChange={(e) => this.setState({ category: e.value })}
                  style={{ width: "450px", marginBottom: "40px" }}
                  clearable={false}
                  searchable={false}
                  options={this.renderCategoryOptions()}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="utility-flex-row">
              <div className="utility-inline-label">
                <p>Series Description</p>
                <textarea
                  className="utility-textarea"
                  name="series_description"
                  value={this.state.series_description}
                  onChange={this.handleFieldChange}
                />
              </div>
            </div>
          </div>

          {isEdit && (
            <div className="utility-inline-label">
              <p>Session Order</p>
              <SortableSessions
                items={this.state.sessions}
                state={this.state}
                onSortEnd={this.onSortEnd}
                sessions={this.props.all_sessions}
                handleSeriesDay={this.handleSeriesDay}
              />
            </div>
          )}

          {isEdit && (
            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <button
                className="utility-btn-danger"
                onClick={this.handleDelete}
              >
                Delete Series
              </button>
            </div>
          )}
        </MainContentContainer>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    all_categories: state.categories.all_categories,
    all_sessions: state.sessions.all_sessions,
    series: state.series.all_series,
    image: state.media.image,
  };
}

const mapDispatchToProps = {
  fetchAllCategories,
  fetchAllSessions,
  fetchAllSeries,
  uploadImage,
  clearMedia,
  addEditSeries,
  deleteSeries,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSeries);
