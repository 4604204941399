import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { AddCard, Cancel, CreditCardOff } from '@mui/icons-material';
import { Alert, AppBar, Box, Button, CircularProgress, Container, Toolbar, Typography } from '@mui/material';
import Swal from 'sweetalert2';

import UnparentedCardList from './UnparentedCardList';
import CardItem from './CardItem';
import { fetchAllCards, removeCardFromDeck, sortCards } from '../../../../actions/cardsActions';
import { fetchOneDeck } from '../../../../actions/cardDeckActions';
import LoadingBackdrop from '../../../Common/LoadingBackdrop';

const SortableCardItem = ({ card, handleEdit, handleRemove }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: card.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    position: 'relative',
  };

  return (
    <div ref={setNodeRef} style={style}>
      <CardItem
        card={card}
        handleEdit={() => handleEdit(card)}
        handleRemove={() => handleRemove(card.id)}
        dragHandle={{
          ...attributes,
          ...listeners,
          style: { cursor: 'grab' }
        }}

      />
    </div>
  );
};

export default function CardsList() {
  const { id } = useParams();
  const history = useHistory();
  const [openUparented, setOpenUnparented] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.cards.allLoading);
  const removeLoading = useSelector(state => state.cards.loading);
  const cards = useSelector((state) => state.cards.cards);
  const deckStore = useSelector((state) => state.card_decks.deck);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  useEffect(() => {
    if (id) {
      if (!deckStore) {
        dispatch(fetchOneDeck(id));
      }
      dispatch(fetchAllCards(id));
    }
  }, [id, dispatch]);

  const handleOpen = () => {
    history.push(`/card-deck/${id}/cards/create`);
  };

  const handleOpenUnparented = () => {
    setOpenUnparented(true);
  };

  const handleEdit = card => {
    history.push(`/card-deck/${id}/cards/${card.id}/edit`);
  };

  const handleRemove = cardId => {
    Swal.fire({
      title: `Are you sure?`,
      text: 'The card will be removed from the Deck!',
      icon: 'warning',
      buttons: ["Cancel", "Yes, remove it!"],
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        dispatch(removeCardFromDeck(id, cardId));
      }
    });
  };

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = cards.findIndex((card) => card.id === active.id);
      const newIndex = cards.findIndex((card) => card.id === over.id);
      const newCards = arrayMove(cards, oldIndex, newIndex);
      dispatch(sortCards(newCards));
    }

    setActiveId(null);
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: '10px',
  };

  const activeCardCount = cards.filter(card => card.is_active > 0).length;
  return (
    <Container>
      <LoadingBackdrop open={removeLoading} />
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {deckStore ? deckStore.name : ""} - {activeCardCount} Active Cards
          </Typography>
          <Button color="info" variant="contained" sx={{ mr: 1 }} startIcon={<AddCard />} onClick={handleOpen}>Add New Card</Button>
          <Button color="secondary" variant="contained" sx={{ mr: 1 }} startIcon={<CreditCardOff />} onClick={handleOpenUnparented}>Unparented Cards</Button>
          <Button color="error" variant="contained" component={Link} to={`/card-decks`} startIcon={<Cancel />}>
            Close
          </Button>
          <UnparentedCardList
            open={openUparented}
            deckId={id}
            handleClose={() => setOpenUnparented(false)} />
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Box sx={{ display: 'flex', marginTop: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', marginTop: 2, paddingBottom: 2 }}>
          {cards.length > 0 ? (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={cards.map(card => card.id)}
                strategy={rectSortingStrategy}
              >
                <div style={gridStyle}>
                  {cards.map((card) => (
                    <SortableCardItem
                      key={card.id}
                      card={card}
                      handleEdit={handleEdit}
                      handleRemove={handleRemove}
                    />
                  ))}
                </div>
              </SortableContext>
              <DragOverlay>
                {activeId ? (
                  <CardItem
                    card={cards.find(card => card.id === activeId)}
                    handleEdit={() => { }}
                    handleRemove={() => { }}
                  />
                ) : null}
              </DragOverlay>
            </DndContext>
          ) : (
            <Alert sx={{ width: '100%' }} severity="info">This deck has no associated cards.</Alert>
          )}
        </Box>
      )}
    </Container>
  );
}