import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Link } from 'react-router-dom';
import { TableRow, TableCell, IconButton, Tooltip, Typography } from '@mui/material';
import { Edit, Reorder, CheckCircle, Cancel, Videocam, Audiotrack } from '@mui/icons-material';

const SortableCategoryItem = ({ category, highlightIndex, index }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: category.category_id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    backgroundColor: highlightIndex === index ? '#f0f0f0' : 'white',
  };

  return (
    <TableRow
      ref={setNodeRef}
      {...attributes}
      sx={{ ...style }}
      key={category.category_id}
    >
      <TableCell {...listeners} sx={{ cursor: 'grab' }}>
        <Reorder />
      </TableCell>
      <TableCell>
        <Tooltip title={category.category_is_quick_tap === 1 ? 'Quick Tap' : 'Audio'}>
          <IconButton>
            {category.category_is_quick_tap === 1 ? (
              <Videocam />
            ) : (
              <Audiotrack />
            )}
          </IconButton>
        </Tooltip>
        <Typography component="span">
          {category.category_title}
        </Typography>
      </TableCell>
      <TableCell>{category.category_num_subs}</TableCell>
      <TableCell>{category.category_num_sessions}</TableCell>
      <TableCell align="right">
        <Tooltip title={category.category_is_active === 1 ? 'Active' : 'Not Active'}>
          <IconButton>
            {category.category_is_active === 1 ? (
              <CheckCircle sx={{ color: 'green' }} />
            ) : (
              <Cancel sx={{ color: 'red' }} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton component={Link} to={`/category/edit/${category.category_id}`}>
            <Edit />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default SortableCategoryItem;