import {
  FETCH_AUTHORS,
  FETCH_AUTHORS_SESSIONS,
  FETCH_AUTHORS_PROGRAMS,
  UPLOAD_AUTHORS_IMAGE,
  CLEAR_AUTHORS
} from "../constants/ActionTypes";

const INITIAL_STATE = {
  authors: null,
  artist_sessions: null,
  artist_programs: null,
  uploaded_artist_image: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_AUTHORS:
      return { ...state, authors: action.payload };
    case FETCH_AUTHORS_SESSIONS:
      return { ...state, artist_sessions: action.payload };
    case FETCH_AUTHORS_PROGRAMS:
      return { ...state, artist_programs: action.payload };
    case UPLOAD_AUTHORS_IMAGE:
      return { ...state, uploaded_artist_image: action.payload };
    case CLEAR_AUTHORS:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}
