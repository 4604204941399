import Swal from "sweetalert2";
import { api, errorHandler, queryString } from "./index";
import { STATIC_ERROR } from "../constants/ActionTypes";

export function forgotPasswordBatch(email) {
  return function (dispatch) {
    api.post(`/forgot-password-batch.php`,
      queryString.stringify({
        email: email,
      })
    )
      .then((response) => {
        const { email, date, link } = response.data;
        Swal.fire({
          icon: "success",
          title: "Password Link Generated!",
          html: `Email : ${email} <br> Link : ${link} <br> Expires On : ${date}`,
          heightAuto: true,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, STATIC_ERROR);
      });
  };
}

export function unlockStripe(sub_id) {
  return function (dispatch) {
    api.post(`/update-existing-stripe-user.php`,
      queryString.stringify({
        sub_id: sub_id,
      })
    )
      .then((response) => {
        const {
          user_email,
          user_is_premium,
          user_subscription_source,
          expiration_date,
        } = response.data;
        Swal.fire({
          icon: "success",
          title: "User Updated",
          html: `Email : ${user_email} <br> Premium : ${user_is_premium} <br> Source : ${user_subscription_source}  <br> Expires On : ${expiration_date}`,
          heightAuto: true,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, STATIC_ERROR);
      });
  };
}

export function unlockGoogle(transaction_token, type, email) {
  return function (dispatch) {
    if (!!transaction_token && !!type && !!email) {
      api.post(`/update-user-subscription-android.php`,
        queryString.stringify({
          transaction_token: transaction_token,
          type: type,
          email: email,
        })
      )
        .then((response) => {
          const {
            user_email,
            user_is_premium,
            user_subscription_source,
            expiration_date,
          } = response.data;
          Swal.fire({
            icon: "success",
            title: "User Updated",
            html: `Email : ${user_email} <br> Premium : ${user_is_premium} <br> Source : ${user_subscription_source}  <br> Expires On : ${expiration_date}`,
            heightAuto: true,
          });
        })
        .catch((error) => {
          errorHandler(dispatch, error.response, STATIC_ERROR);
        });
    } else {
      dispatch(handleMissingError);
    }
  };
}

export function getUserStatus(email) {
  return function (dispatch) {
    api.post(`/get-user-premium-status.php`,
      queryString.stringify({
        email: email,
      })
    )
      .then((response) => {
        const {
          user_email,
          user_is_premium,
          user_subscription_source,
          expiration_date,
        } = response.data;

        console.log(user_is_premium);
        let html =
          user_is_premium == 1
            ? `Email : ${user_email} <br> Premium : ${user_is_premium} <br> Source : ${user_subscription_source}  <br> Expires On : ${expiration_date}`
            : `Email : ${user_email} <br> Premium : ${user_is_premium} <br>`;
        if (user_is_premium) {
        }
        Swal.fire({
          icon: "success",
          title: "User Subscription Status",
          html,
          heightAuto: true,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, STATIC_ERROR);
      });
  };
}

export function deleteUser(email) {
  return function (dispatch) {
    api.post(`/delete-user.php`,
      queryString.stringify({
        email: email,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "User Deleted",
          text: response.data,
          heightAuto: true,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, STATIC_ERROR);
      });
  };
}

function handleMissingError() {
  Swal.fire({
    icon: "error",
    title: "Field Missing",
    text: "input filed is missing",
    heightAuto: true,
  });
}
