import React, { Component } from 'react';

export default class Error401 extends Component {

  render() {
    return (
      <div className="container notFound" style={{ textAlign: 'center' }}>
        <h1>401 Error</h1>
        <p>You are not authorized to see this page</p>
      </div>
    );
  }
}