import { Cancel, Save } from '@mui/icons-material';
import { AppBar, Autocomplete, Box, Button, Chip, Container, Grid, Paper, TextField, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { uploadAudio, uploadImage } from '../../../actions/mediaActions';
import { fetchAuthors } from '../../../actions/authorsActions';
import { addEditDailyReflections, closeDailyReflectionForm, fetchOneDailyReflection } from '../../../actions/dailyReflectionActions';
import LoadingBackdrop from '../../Common/LoadingBackdrop';
import moment from 'moment';
import { addObjectTag, removeObjectTag } from '../../../actions/tagActions';
import ImageContainerPreview from '../../Common/ImageContainerPreview';
import AudioContainerPreview from '../../Common/AudioContainerPreview';
import FieldSetGroup from '../../Elements/FieldSetGroup';

export default function DailyReflectionForm() {
    const dispatch = useDispatch();
    const { id } = useParams();

    const DAILY_INSPIRATION_OBJECT_TAG = "DailyInspiration";

    const authorsStore = useSelector(store => store.authors.authors);
    const mediaAudio = useSelector(store => store.media.audio);
    const mediaImage = useSelector(store => store.media.image);
    const loading = useSelector(state => state.daily_reflections.loading);
    const selectedDailyReflection = useSelector(state => state.daily_reflections.selectedDailyReflection);

    const [authors, setAuthors] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [dailyReflection, setDailyReflection] = useState({
        date: '',
        title: '',
        author_id: '',
        deeplink_url: '',
        deeplink_image: null,
        deeplink_image_url: '',
        audio_url: '',
        audio_length: 0
    });
    const [tag, setTag] = useState('');
    const [tags, setTags] = useState([]);

    useEffect(() => {
        loadAuthors();
    }, [authorsStore]);

    useEffect(() => {
        fetchAndSetDailyReflection();
    }, [selectedDailyReflection]);

    useEffect(() => {
        handleContentLoaded();
    }, [authors, dailyReflection])

    const fetchAndSetDailyReflection = () => {
        if (!id) return;
        if (selectedDailyReflection) {
            setDailyReflection({
                ...selectedDailyReflection,
                date: moment(selectedDailyReflection.date_epoch * 1000)
            });
            loadTags(selectedDailyReflection.tags);
            return;
        }
        dispatch(fetchOneDailyReflection(id));
    }

    const loadTags = tags => {
        if (tags && tags.length > 0) {
            setTags(tags.map(tag => tag.tag));
        }
    }

    const loadAuthors = () => {
        if (authorsStore) {
            setAuthors(authorsStore);
            return;
        }

        dispatch(fetchAuthors());
    }

    const handleContentLoaded = () => {
        if (authors.length > 0 && dailyReflection?.daily_reflection_id > 0) {
            const author = getAuthor();
            if (author) {
                setSelectedAuthor(author);
            }
        }
    };

    const getAuthor = () => {
        return authors.find(author => author.author_id == dailyReflection.author_id);
    }

    const handleChange = e => {
        setDailyReflection({
            ...dailyReflection,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeDate = date => {
        setDailyReflection({
            ...dailyReflection,
            date
        })
    }

    const handleChangeAuthor = (_, author) => {
        setDailyReflection({
            ...dailyReflection,
            author_id: author?.author_id || ''
        })
        setSelectedAuthor(author);
    }

    const handleImageUpload = e => {
        const file = e.target.files[0];

        setDailyReflection({
            ...dailyReflection,
            deeplink_image: file
        })

        dispatch(uploadImage(file));
    }

    const handleAudioUpload = e => {
        const file = e.target.files[0];
        dispatch(uploadAudio(file));
    }

    const handleSave = () => {
        const data = {
            ...dailyReflection,
            date_epoch: dailyReflection.date ? dailyReflection.date.unix() : null
        }

        dispatch(addEditDailyReflections(data));
    }

    const updateDuration = (e) => {
        const duration = Math.floor(e.target.duration) || 0;
        setDailyReflection({
            ...dailyReflection,
            audio_length: duration
        });
    };

    useEffect(() => {
        handleImageUploaded();
    }, [mediaImage]);

    useEffect(() => {
        handleAudioUploaded();
    }, [mediaAudio]);

    const handleImageUploaded = () => {
        if (mediaImage) {
            setDailyReflection({
                ...dailyReflection,
                deeplink_image_url: mediaImage
            })
        }
    }

    const handleAudioUploaded = () => {
        if (mediaAudio) {
            setDailyReflection({
                ...dailyReflection,
                audio_url: mediaAudio
            })
        }
    }

    const handleAddTag = async (e) => {
        if (e.key === 'Enter' && tag.trim() !== '') {
            const tagValue = tag.trim();
            if (tags.includes(tagValue)) {
                setTag('');
                return;
            };
            await saveTag(tagValue);
            setTags([...tags, tagValue]);
            setTag('');
        }
    }

    const handleDeleteTag = async (indexToDelete, tagToDelete) => {
        await deleteTag(tagToDelete)
        setTags(tags.filter((tag, index) => index !== indexToDelete));
    }

    const saveTag = async (tag) => {
        dispatch(addObjectTag({
            object_id: dailyReflection.daily_reflection_id,
            tag,
            object_type: DAILY_INSPIRATION_OBJECT_TAG,
        }));
    }

    const deleteTag = async (tag) => {
        dispatch(removeObjectTag({
            object_id: dailyReflection.daily_reflection_id,
            tag,
            object_type: DAILY_INSPIRATION_OBJECT_TAG,
        }));
    }

    const handleCancel = () => {
        dispatch(closeDailyReflectionForm());
    }

    const handleRemoveImage = () => {
        setDailyReflection({
            ...dailyReflection,
            deeplink_image: null,
            deeplink_image_url: null
        })
    }

    const handleRemoveAudio = () => {
        setDailyReflection({
            ...dailyReflection,
            audio_url: null
        })
    }

    return (
        <Container sx={{ py: 2 }}>
            <LoadingBackdrop open={loading} />
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {id ? 'Edit' : 'Create'} Daily Inspiration {id ? '' : ''}
                    </Typography>
                    <Button color="success" onClick={handleSave} startIcon={<Save />} variant="contained" sx={{ marginRight: 2 }}>
                        Save
                    </Button>
                    <Button color="error" onClick={handleCancel} startIcon={<Cancel />} variant="contained">
                        Cancel
                    </Button>
                </Toolbar>
            </AppBar>
            <Box gap={2}>
                <Paper sx={{ padding: 2 }}>
                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                        <Grid item xs={2} sx={{ marginBottom: 2 }} >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    name='date'
                                    label="Scheduled For"
                                    value={dailyReflection.date !== '' ? dayjs(dailyReflection.date) : null}
                                    onChange={handleChangeDate}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={7} sx={{ marginBottom: 2 }} >
                            <TextField
                                fullWidth
                                label="Title"
                                variant="outlined"
                                name="title"
                                value={dailyReflection.title}
                                onChange={handleChange}
                                autoFocus
                                required
                            />
                        </Grid>
                        <Grid item xs={3} sx={{ marginBottom: 2 }} >
                            <Autocomplete
                                name="author_id"
                                disablePortal
                                options={authors}
                                getOptionLabel={(option) => option.author_name || ''}
                                getOptionKey={(option) => option.author_id}
                                renderInput={(params) => <TextField {...params} label="Author" />}
                                loading={true}
                                loadingText="Loading Authors..."
                                clearText="Clear"
                                onChange={handleChangeAuthor}
                                noOptionsText="No authors..."
                                value={selectedAuthor}
                                isOptionEqualToValue={(option, value) => {
                                    return option.author_id == value?.author_id;
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: 2 }} >
                            <FieldSetGroup title="Media">
                                <Grid container spacing={2} item xs={12}>
                                    <Grid item xs={6} container spacing={4} >
                                        <Grid item xs={12}>
                                            <ImageContainerPreview
                                                title="Deeplink Image"
                                                image={dailyReflection.deeplink_image ? URL.createObjectURL(dailyReflection.deeplink_image) : dailyReflection.deeplink_image_url}
                                                name="file"
                                                handleRemove={handleRemoveImage}
                                                handleImageUpload={handleImageUpload}
                                                showCopyUrlButton={true}
                                                sx={{ padding: 1, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Deeplink URL"
                                                variant="outlined"
                                                name="deeplink_url"
                                                value={dailyReflection.deeplink_url}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <AudioContainerPreview
                                            title="Audio"
                                            audio={dailyReflection.audio_url}
                                            name="audio_url"
                                            handleRemove={handleRemoveAudio}
                                            handleAudioUpload={handleAudioUpload}
                                            onLoadedData={updateDuration}
                                            showCopyUrlButton={true}
                                        />
                                    </Grid>
                                </Grid>
                            </FieldSetGroup>
                        </Grid>
                        {
                            dailyReflection && dailyReflection.daily_reflection_id > 0 ? (
                                <Grid item xs={12} sx={{ marginBottom: 2 }} >
                                    <FieldSetGroup title="Tags">
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    label="Tags"
                                                    variant="outlined"
                                                    name="tag"
                                                    value={tag}
                                                    onChange={e => setTag(e.target.value)}
                                                    placeholder='Press Enter to add'
                                                    onKeyUp={handleAddTag}
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                {
                                                    tags.map((tag, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={tag}
                                                            onDelete={() => handleDeleteTag(index, tag)}
                                                            sx={{ marginRight: 1, marginBottom: 1 }}
                                                        />
                                                    ))
                                                }
                                            </Grid>
                                        </Grid>
                                    </FieldSetGroup>
                                </Grid>
                            ) : null
                        }
                    </Grid>
                </Paper>
            </Box>
        </Container>
    )
}
