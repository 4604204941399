import {
  API_ERROR,
  UPDATE_CANCELLATION_REASON,
  FETCH_CANCELLATION_REASONS,
  DELETE_CANCELLATION_REASON,
  FETCH_USER_CANCELLATION_REPORT,
} from "../constants/ActionTypes";

const INITIAL_STATE = {
  cancellation_reasons: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CANCELLATION_REASONS:
      return { ...state, cancellation_reasons: action.payload };
    default:
      return state;
  }
}
