import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Grid, Card, CardActions, CardContent, CardHeader, CircularProgress, Box, Tooltip, Chip, IconButton } from '@mui/material';
import { Edit, Delete, CardGiftcard, Add, DragIndicator } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCardDeck, fetchAllDecks, sortCardDeck, selectCardDeck } from '../../../actions/cardDeckActions';
import Swal from 'sweetalert2';
import { DndContext, DragOverlay, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import LoadingBackdrop from '../../Common/LoadingBackdrop';

const DeckItem = ({ deck, handleDelete, dispatch, dragHandleProps }) => {
  const cardCountLabel = deck.active_card_count === 0
    ? "No cards"
    : deck.active_card_count === 1
      ? deck.active_card_count + " card"
      : deck.active_card_count + " cards";

  const cardTotalCountLabel = deck.card_count === 0
    ? "No cards"
    : deck.card_count === 1
      ? deck.card_count + " total cards"
      : deck.card_count + " total cards";

  const buildSubheader = deck => {
    let subheader = 'No Category';

    if (deck.category) {
      subheader = deck.category.category_title;
    }

    if (deck.subcategory) {
      subheader += ' / ' + deck.subcategory.subcategory_title;
    }

    return subheader;
  };

  return (
    <Card sx={{ position: 'relative' }}>
      <CardHeader
        title={deck.name}
        subheader={buildSubheader(deck)}
        action={
          <Box>
            <Tooltip title={cardTotalCountLabel} placement="bottom">

              <Chip
                size='small'
                label={cardCountLabel}
                color={'info'}
                sx={{ marginRight: 1 }}
              />
            </Tooltip>
            <Chip
              size='small'
              label={deck.is_active ? 'Active' : 'Inactive'}
              color={deck.is_active ? 'success' : 'warning'}
            />
            <IconButton {...dragHandleProps}>
              <DragIndicator />
            </IconButton>
          </Box>
        }
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {deck.description}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button size="small" color="secondary" startIcon={<CardGiftcard />} component={Link} to={`/card-deck/${deck.id}/cards`} onClick={(e) => {
          e.preventDefault();
          dispatch(selectCardDeck(deck, `/card-deck/${deck.id}/cards`));
        }}>
          Cards
        </Button>
        <Button size="small" color="primary" startIcon={<Edit />} component={Link} to={`/card-deck/${deck.id}`} onClick={(e) => {
          e.preventDefault();
          dispatch(selectCardDeck(deck, `/card-deck/${deck.id}`));
        }}>
          Edit
        </Button>
        <Button size="small" color="error" startIcon={<Delete />} onClick={() => handleDelete(deck.id)}>
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

const SortableItem = ({ deck, dispatch, handleDelete }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: deck.id });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  return (
    <Grid item xs={12}>
      <div ref={setNodeRef} style={style}>
        <DeckItem
          deck={deck}
          handleDelete={handleDelete}
          dispatch={dispatch}
          dragHandleProps={{ ...attributes, ...listeners }}
        />
      </div>
    </Grid>
  );
};

function CardDecksMainPage() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.card_decks.allLoading);
  const deleteLoading = useSelector(state => state.card_decks.loading);
  const decks = useSelector((state) => state.card_decks.decks);
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor)
  );

  useEffect(() => {
    dispatch(fetchAllDecks());
  }, [dispatch]);

  const handleDelete = deckId => {
    Swal.fire({
      title: `Are you sure?`,
      text: 'You will not be able to recover this deck!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel'
    }).then((result) => {
      if (result.value) {
        dispatch(deleteCardDeck(deckId));
      }
    });
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = decks.findIndex(deck => deck.id === active.id);
      const newIndex = decks.findIndex(deck => deck.id === over.id);
      const updatedDecks = arrayMove(decks, oldIndex, newIndex);
      dispatch(sortCardDeck(updatedDecks));
    }

    setActiveId(null);
  };

  return (
    <Container>
      <LoadingBackdrop open={deleteLoading} />
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Card Decks
          </Typography>
          <Button color="inherit" component={Link} to={`/card-decks/create`} startIcon={<Add />}>
            Add New Deck
          </Button>
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={decks} strategy={verticalListSortingStrategy}>
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              {decks.map(deck => (
                <SortableItem key={deck.id} deck={deck} handleDelete={handleDelete} dispatch={dispatch} />
              ))}
            </Grid>
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <Grid item xs={12}>
                <DeckItem
                  deck={decks.find(deck => deck.id === activeId)}
                  handleDelete={handleDelete}
                  dragHandleProps={{}} // Empty object for overlay
                />
              </Grid>
            ) : null}
          </DragOverlay>
        </DndContext>
      )}
    </Container>
  );
}

export default CardDecksMainPage;