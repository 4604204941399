import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import "react-select/dist/react-select.css";
import Swal from "sweetalert2";

import { fetchAllBGM } from "../../actions/backgroundMusicActions";
import { addObjectTag, removeObjectTag } from "../../actions/tagActions";
import { fetchAuthors } from "./../../actions/authorsActions";
import {
  clearMedia,
  uploadImage,
  uploadQuickTapVideo,
} from "./../../actions/mediaActions";
import {
  addEditQuickTapSession,
  deleteQuickTapSession,
  fetchOneQuickTapSession,
  clearQuickTapSessions
} from "./../../actions/quickTapsActions";
import { fetchAllCategories } from "./../../actions/categoryActions";
import { fetchAllSubCategories } from "./../../actions/subcategoryActions";
import { fetchTappingPoints } from "./../../actions/tappingPointsActions";
import HeaderBar from "./../HeaderBar/HeaderBar";
import MainContentContainer from "./../MainContentContainer/MainContentContainer";
import "../AddEditSession/AddEditSession.css";
import BackgroundImageTimings from "../AddEditSession/BackgroundImageTimings/BackgroundImageTimings";
import VideoJS from "../VideoJS/VideoJS";

class AddEditQuickTapSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quick_tap_title: "",
      quick_tap_is_new: 0,
      quick_tap_is_featured: 0,
      quick_tap_is_premium: 0,
      quick_tap_is_active: 1,
      quick_tap_is_dark_mode: 0,
      quick_tap_video_playback_id: "",
      quick_tap_deep_link_ios: "",
      quick_tap_deep_link_android: "",
      quick_tap_deep_link_image: "",
      quick_tap_created_at: "",
      quick_tap_updated_at: "",
      quick_tap_bg_music_url: "",
      quick_tap_image: "",
      quick_tap_duration: "",
      quick_tap_duration_in_sec: 0,
      quick_tap_author_id: "",

      video_url_preview: "",
      video_uploading: false,

      bg_music_url_preview: "",
      bg_music_url_uploading: false,

      image_preview: "",
      image_uploading: false,

      deep_link_image_preview: "",
      deep_link_image_uploading: false,

      session_bgm_id: null,
      bg_timestamps: [],
      isSetToEdit: false,
      isSetToDuplicate: false,
      session_tag: "",
      tags: [],
      tagsToRemove: [],
      associatedParent: "0",
      category_id: 0,
      subcategory_id: 0,
    };

    // Prevents two new timestamps created after one is finished
    this.shouldAddNextTimestamp = true;
  }

  componentDidMount() {
    this.props.fetchAllCategories(1);
    this.props.fetchAllSubCategories(0, 1);
    this.props.fetchAuthors();
    this.props.fetchTappingPoints();
    this.props.clearMedia();
    this.props.clearQuickTapSessions();
    this.props.fetchAllBGM();
    if (this.props.isEdit) {
      this.props.fetchOneQuickTapSession(this.props.selectedId);
    }
    if (this.props.isDuplicate) {
      this.props.fetchOneQuickTapSession(this.props.duplicateSessionId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.isEdit &&
      nextProps.all_quick_tap_sessions &&
      !this.state.isSetToEdit && nextProps.background_musics
    ) {
      this.setSessionForEditing(nextProps);
    }

    if (
      this.props.isDuplicate &&
      nextProps.all_quick_tap_sessions &&
      !this.state.isSetToDuplicate
    ) {
      this.setSessionForDuplicate(nextProps);
    }

    if (nextProps.quick_tap_image) {
      if (this.state.image_uploading) {
        this.setState({
          quick_tap_image: nextProps.quick_tap_image,
          image_uploading: false,
        });
        this.props.clearMedia();
      }
      if (this.state.deep_link_image_uploading) {
        this.setState({
          quick_tap_deep_link_image: nextProps.quick_tap_image,
          deep_link_image_uploading: false,
        });
        this.props.clearMedia();
      }
    }

    if (nextProps.video && !this.props.video) {
      if (this.state.video_uploading) {
        const video = nextProps.video;
        this.setState({
          video_uploading: false,
          quick_tap_video_playback_id: video.playback_id,
          quick_tap_duration: video.duration,
          quick_tap_duration_in_sec: video.duration_in_sec,
        });
        this.props.clearMedia();
      }
    }
  }

  componentWillUnmount() {
    if (this.state.image_preview) {
      window.URL.revokeObjectURL(this.state.image_preview);
    }
    if (this.state.deep_link_image_preview) {
      window.URL.revokeObjectURL(this.state.deep_link_image_preview);
    }
    if (this.state.bg_music_url_preview) {
      window.URL.revokeObjectURL(this.state.bg_music_url_preview);
    }
    this.props.clearMedia();
  }

  loadQuickTapSession = (currentSession) => {
    let associatedParent = "0";
    if (currentSession.category && currentSession.category?.category_id !== 0) {
      associatedParent = currentSession.category?.category_id + "CAT";
    } else if (currentSession.subcategory && currentSession.subcategory?.subcategory_id !== 0) {
      associatedParent = currentSession.subcategory?.subcategory_id + "SUB";
    }

    let sessionState = currentSession ? {
      quick_tap_id: currentSession.id,
      quick_tap_title: currentSession.title,
      quick_tap_is_new: currentSession.is_new,
      quick_tap_is_featured: currentSession.is_featured,
      quick_tap_is_premium: currentSession.is_premium,
      quick_tap_is_active: currentSession.is_active,
      quick_tap_is_dark_mode: currentSession.is_dark_mode,
      quick_tap_video_playback_id: currentSession.video_playback_id,
      quick_tap_deep_link_ios: currentSession.deep_link_ios,
      quick_tap_deep_link_android: currentSession.deep_link_android,
      quick_tap_deep_link_image: currentSession.deep_link_image,
      quick_tap_created_at: currentSession.created_at,
      quick_tap_updated_at: currentSession.updated_at,
      quick_tap_bg_music_url: currentSession.bg_music_url,
      quick_tap_image: currentSession.image,
      quick_tap_duration: currentSession.duration,
      quick_tap_duration_in_sec: currentSession.duration_in_sec,
      quick_tap_author_id: currentSession.author_id,
      bg_timestamps: currentSession.bg_timestamps,
      tags: currentSession.tags,
      associatedParent: associatedParent
    } : {};
    return sessionState;
  }

  setSessionForEditing = ({ all_quick_tap_sessions, background_musics }) => {
    let currentSession = all_quick_tap_sessions.find(session => session.id == this.props.selectedId);
    if (!currentSession) {
      Swal.fire({ icon: "error", text: "Error finding selected session to edit" });
      return;
    }
    let sessionState = this.loadQuickTapSession(currentSession);
    const selectedBackgroundMusic = background_musics.find(bg_music => bg_music.bgm_url == sessionState.quick_tap_bg_music_url);
    this.setState({
      ...sessionState,
      isSetToEdit: true,
      session_bgm_id: selectedBackgroundMusic ? selectedBackgroundMusic.bgm_id : 0
    });
  };

  setSessionForDuplicate = ({ all_quick_tap_sessions, background_musics }) => {
    let currentSession = all_quick_tap_sessions.find(session => session.id == this.props.duplicateSessionId);
    console.log(currentSession);
    if (!currentSession) {
      Swal.fire({ icon: "error", text: "Error finding selected session to duplicate" });
      return;
    }
    let sessionState = this.loadQuickTapSession(currentSession);
    const selectedBackgroundMusic = background_musics.find(bg_music => bg_music.bgm_url == sessionState.quick_tap_bg_music_url);
    this.setState({
      ...sessionState,
      isSetToDuplicate: true,
      session_bgm_id: selectedBackgroundMusic ? selectedBackgroundMusic.bgm_id : 0
    });
  };

  handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  clickParent = (e) => {
    e.target.parentNode.click();
  };

  handleSelectChange = (e) => {
    if (!e) {
      return false;
    }

    const { value, prop, quick_tap_bg_music_url } = e;

    if (quick_tap_bg_music_url) {
      this.setState({ quick_tap_bg_music_url: quick_tap_bg_music_url });
    }

    this.setState((prevState) => {
      const state = { ...prevState };
      state[prop] = value;
      return state;
    });
  };

  renderAuthors = () => {
    return this.props.authors.map((obj) => {
      return {
        label: obj.author_name,
        value: obj.author_id,
        prop: "quick_tap_author_id",
      };
    });
  };

  handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.image_preview) {
      window.URL.revokeObjectURL(this.state.image_preview);
    }
    this.setState({
      image_preview: window.URL.createObjectURL(file),
      image_uploading: true,
    });
    this.props.uploadImage(file);
  };

  handleDeepLinkImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.deep_link_image_preview) {
      window.URL.revokeObjectURL(this.state.deep_link_image_preview);
    }
    this.setState({
      deep_link_image_preview: window.URL.createObjectURL(file),
      deep_link_image_uploading: true,
    });
    this.props.uploadImage(file);
  };

  handleCheckBox = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    this.setState({
      [name]: value === true ? 1 : 0,
    });
  };

  handleVideoUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.video_url_preview) {
      window.URL.revokeObjectURL(this.state.video_url_preview);
    }
    this.setState({
      video_url_preview: window.URL.createObjectURL(file),
      video_uploading: true,
    });
    this.props.uploadQuickTapVideo(file);
  };

  handleBGMediaUpload = (e) => {
    e.preventDefault();
    return Swal.fire({
      icon: "error",
      title: "BGM Upload Error",
      text: "Please upload directly from Background Music in Background Music Section",
    });
  };

  renderParentOptions = () => {
    const categories = this.props.all_categories.map((obj) => ({
      label: obj.category_title + " - [Quick Tap Category]",
      value: obj.category_id + "CAT",
      prop: "associatedParent",
    }));

    const subcategories = this.props.all_subcategories.map((obj) => {
      let label = obj.subcategory_title;
      if (obj.category) {
        label += ` (${obj.category.category_title})`;
      }
      label += " - [Quick Tap Subcategory]";
      return {
        label: label,
        value: obj.subcategory_id + "SUB",
        prop: "associatedParent",
      };
    });

    const none = { label: "None", value: "0", prop: "associatedParent" };

    return [none, ...categories, ...subcategories];
  };

  renderBGMOptions = () => {
    const background_musics = this.props.background_musics.map((obj) => ({
      label: obj.bgm_title,
      value: obj.bgm_id,
      prop: "session_bgm_id",
      quick_tap_bg_music_url: obj.bgm_url,
    }));

    const none = {
      label: "None",
      value: "0",
      prop: "session_bgm_id",
      quick_tap_bg_music_url: "",
    };

    return [none, ...background_musics];
  };

  addTimestamp = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState((prevState) => {
      const state = { ...prevState };
      state.bg_timestamps.push({
        tapping_point_id: "",
        timestamp: 0,
      });
      this.shouldAddNextTimestamp = true;
      return state;
    });
  };

  handleTimestampChange = (newValue, stateProp, indexToChange, objProp) => {
    this.setState(
      (prevState) => {
        const state = { ...prevState };
        if (indexToChange === undefined) {
          state[stateProp] = newValue;
        } else {
          state[stateProp][indexToChange][objProp] = newValue;
        }
        return state;
      },
      () => {
        if (
          this.shouldAddNextTimestamp &&
          stateProp === "bg_timestamps" &&
          indexToChange === this.state[stateProp].length - 1 &&
          this.state[stateProp][indexToChange].tapping_point_id &&
          this.state[stateProp][indexToChange].timestamp
        ) {
          this.shouldAddNextTimestamp = false;
          this.addTimestamp();
        }
      }
    );
  };

  removeTimestamp = (index) => {
    this.setState((prevState) => {
      const state = { ...prevState };
      state.bg_timestamps.splice(index, 1);
      return state;
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Saving...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    if (this.state.author_id === "") {
      Swal.fire({
        icon: "error",
        text: "You must select an Author for this session.",
      });
      return false;
    }

    let { bg_timestamps: timestamps_json, ...quick_tap_session_json } =
      this.state;

    timestamps_json = timestamps_json.filter(
      (obj) => obj.tapping_point_id !== ""
    );
    const times = timestamps_json.map((obj) => obj.timestamp);
    const uniqueTimes = [...new Set([...times])];
    if (times.length !== uniqueTimes.length) {
      Swal.fire({
        icon: "error",
        text: "Cannot have two tapping points at same time.",
      });
      return false;
    }

    let category_id = 0;
    let subcategory_id = 0;

    if (this.state.associatedParent.indexOf("CAT") > -1) {
      category_id = parseInt(this.state.associatedParent, 10);
    } else if (this.state.associatedParent.indexOf("SUB") > -1) {
      subcategory_id = parseInt(this.state.associatedParent, 10);
    }

    if (this.props.isDuplicate) {
      quick_tap_session_json.quick_tap_id = 0;
      quick_tap_session_json.session_is_duplicate = 1;
    }
    quick_tap_session_json.quick_tap_updated_at = null;
    quick_tap_session_json.quick_tap_created_at = null;
    const options = {
      quick_tap_session_json: JSON.stringify(quick_tap_session_json),
      timestamps_json: JSON.stringify(timestamps_json),
      category_id,
      subcategory_id,
    };
    this.props.addEditQuickTapSession(options);
    //remove or add tags
    this.state.tags.forEach((tag) => {
      tag.newTag && tag.newTag === true && this.addTags(tag.tag);
    });
    this.state.tagsToRemove.length > 0 &&
      this.state.tagsToRemove.forEach((tags) => {
        this.deleteTags(tags.tag);
      });
  };

  addTags = (tag) => {
    const { addObjectTag } = this.props;
    const options = {
      object_id: this.state.quick_tap_id,
      tag: tag,
      object_type: "QuickTap",
    };
    this.setState({
      session_tag: "",
    });
    addObjectTag(options);
  };

  deleteTags = (tag) => {
    const options = {
      object_id: this.state.quick_tap_id,
      tag: tag,
      object_type: "QuickTap",
    };
    this.props.removeObjectTag(options);
    const newTags = this.state.tags.filter((stateTag) => stateTag.tag !== tag);
    this.setState({
      tags: newTags,
      tagsToRemove: [],
    });
  };

  //Due to BE add temp states for tags to add and remove
  addToStateTag = (tag) => {
    const options = {
      tag: tag,
      newTag: true,
    };
    this.setState({
      session_tag: "",
      tags: [...this.state.tags, options],
    });
  };

  removeFromStateTag = (tag) => {
    const tagToRemove = this.state.tags.filter(
      (stateTag) => stateTag.tag === tag
    )[0];
    const newTags = this.state.tags.filter((stateTag) => stateTag.tag !== tag);
    this.setState({
      tags: newTags,
      tagsToRemove: [...this.state.tagsToRemove, tagToRemove],
    });
  };

  handleDelete = (e) => {
    e.preventDefault();
    if (this.state.quick_tap_is_active === 1) {
      return Swal.fire({
        icon: "error",
        text: "Can't archive selected session, please update session to inactive status to archive session",
      });
    }
    Swal.fire({
      icon: "warning",
      text: "Are you sure you want to archive this session?",
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Archive Session",
    }).then((result) => {
      if (result.value) {
        this.props.deleteQuickTapSession(this.state.quick_tap_id);
      }
    });
  };

  render() {
    const { isEdit, all_subcategories, all_categories, authors } = this.props;

    if (!all_categories || !authors || !all_subcategories) {
      return <h3>Loading...</h3>;
    }
    return (
      <form
        className="AddEditSession"
        onSubmit={this.handleSubmit}
        autoComplete="off"
      >
        <HeaderBar
          header={
            isEdit
              ? "Edit Quick Tap Session"
              : this.props.isDuplicate
                ? "Create Duplicate Quick Tap Session"
                : "Create New Quick Tap Session"
          }
        >
          {this.props.isEdit ? (
            <Link
              to={`/quick-taps/duplicate/${this.state.quick_tap_id}`}
              className="utility-btn-transparent utility-lr-margin-small"
            >
              {" "}
              Duplicate{" "}
            </Link>
          ) : null}
          <Link
            to="/quick-taps"
            className="utility-btn-transparent utility-lr-margin-small"
          >
            Cancel
          </Link>
          <button className="utility-btn-primary">
            {isEdit ? "Update" : "Create"}
          </button>
        </HeaderBar>
        <MainContentContainer subContent={true}>
          <div className="utility-flex-row">
            <div>
              <div className="utility-flex-row">
                <label className="utility-inline-label">
                  <p>Quick Tap Session Name</p>
                  <input
                    type="text"
                    className="utility-text-input"
                    name="quick_tap_title"
                    value={this.state.quick_tap_title}
                    onChange={this.handleFieldChange}
                    required
                  />
                </label>
                <label className="utility-inline-label">
                  <p>Status</p>
                  <Select
                    name="form-field-name"
                    value={this.state.quick_tap_is_active}
                    onChange={this.handleSelectChange}
                    style={{ width: "170px" }}
                    clearable={undefined}
                    searchable={undefined}
                    options={[
                      { value: 1, label: "Active", prop: "quick_tap_is_active" },
                      {
                        value: 0,
                        label: "Not Active",
                        prop: "quick_tap_is_active",
                      },
                    ]}
                  />
                </label>
                <label className="utility-inline-label">
                  <p>Is New</p>
                  <input
                    name="quick_tap_is_new"
                    type="checkbox"
                    value={this.state.quick_tap_is_new === 0 ? false : true}
                    onChange={this.handleCheckBox}
                    checked={this.state.quick_tap_is_new === 0 ? false : true}
                    style={{ width: "80px" }}
                    clearable={undefined}
                    searchable={undefined}
                  />
                </label>
                <label className="utility-inline-label">
                  <p>Featured</p>
                  <input
                    name="quick_tap_is_featured"
                    type="checkbox"
                    value={this.state.quick_tap_is_featured === 0 ? false : true}
                    onChange={this.handleCheckBox}
                    checked={this.state.quick_tap_is_featured === 0 ? false : true}
                    style={{ width: "80px" }}
                    clearable={undefined}
                    searchable={undefined}
                  />
                </label>

                <label className="utility-inline-label">
                  <p>Premium</p>
                  <input
                    name="quick_tap_is_premium"
                    type="checkbox"
                    value={this.state.quick_tap_is_premium === 0 ? false : true}
                    onChange={this.handleCheckBox}
                    checked={this.state.quick_tap_is_premium === 0 ? false : true}
                    style={{ width: "80px" }}
                    clearable={undefined}
                    searchable={undefined}
                  />
                </label>
              </div>
            </div>
            <div className="utility-inline-label">
              <p>Image</p>
              <div className="utility-flex-row">
                {(this.state.image_preview || this.state.quick_tap_image) && (
                  <img
                    src={this.state.image_preview || this.state.quick_tap_image}
                    alt="Profile"
                    style={{
                      maxHeight: "124px",
                      maxWidth: "150px",
                      marginRight: "20px",
                    }}
                  />
                )}
                <label>
                  <input
                    type="file"
                    onChange={this.handleImageUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Image
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div className="utility-flex-row">
            <label className="utility-inline-label">
              <p>Author</p>
              <Select
                name="quick_tap_author_id"
                value={this.state.quick_tap_author_id}
                onChange={this.handleSelectChange}
                style={{ width: "259px" }}
                clearable={false}
                searchable={true}
                options={this.renderAuthors()}
              />
            </label>
            <div className="utility-flex-row" />
            <label className="utility-inline-label">
              <p>Deep Link URL (Android)</p>
              <input
                type="text"
                className="utility-text-input"
                name="quick_tap_deep_link_android"
                value={this.state.quick_tap_deep_link_android}
                onChange={this.handleFieldChange}
              // required
              // style={{ width: 700 }}
              />
            </label>
            <label className="utility-inline-label">
              <p>Deep Link URL (iOS)</p>
              <input
                type="text"
                className="utility-text-input"
                name="quick_tap_deep_link_ios"
                value={this.state.quick_tap_deep_link_ios}
                onChange={this.handleFieldChange}
              // required
              // style={{ width: 700 }}
              />
            </label>
            <div className="utility-inline-label">
              <p>Deep Link Image</p>
              <div className="utility-flex-row">
                {(this.state.deep_link_image_preview ||
                  this.state.quick_tap_deep_link_image) && (
                    <img
                      src={
                        this.state.deep_link_image_preview ||
                        this.state.quick_tap_deep_link_image
                      }
                      alt="Profile"
                      style={{
                        maxHeight: "124px",
                        maxWidth: "150px",
                        marginRight: "20px",
                      }}
                    />
                  )}
                <label>
                  <input
                    type="file"
                    onChange={this.handleDeepLinkImageUpload}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <p
                    className="utility-btn-primary"
                    style={{ color: "#fff" }}
                    tabIndex="0"
                    onKeyPress={this.clickParent}
                  >
                    Upload Image
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div
            style={{ margin: "60px 0px 30px", alignItems: "center" }}
            className="utility-inline-label"
          >
            <p>Parent Category or Subcategory</p>
            <Select
              value={this.state.associatedParent}
              onChange={this.handleSelectChange}
              style={{ width: "850px" }}
              clearable={false}
              searchable={true}
              options={this.renderParentOptions()}
            />
          </div>
          {isEdit && (
            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <p>Tags</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "5px",
                    flexWrap: "wrap",
                  }}
                >
                  {this.state.tags &&
                    this.state.tags.map((tag) => {
                      return (
                        <div
                          key={tag}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "2px 2px",
                          }}
                        >
                          <p>•</p>
                          <p
                            style={{
                              display: "inline-block",
                              borderRadius: "3px",
                              fontSize: "18px",
                            }}
                          >
                            {tag.tag}
                          </p>
                          <p
                            className="utility-btn-danger"
                            onClick={() => this.removeFromStateTag(tag.tag)}
                          >
                            X
                          </p>
                        </div>
                      );
                    })}
                </div>
              </label>
            </div>
          )}
          {isEdit && (
            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <input
                  type="text"
                  className="utility-text-input"
                  name="session_tag"
                  value={this.state.session_tag}
                  onChange={this.handleFieldChange}
                />
                <p
                  className="utility-btn-primary"
                  style={{ color: "#fff" }}
                  onClick={() => this.addToStateTag(this.state.session_tag)}
                >
                  Upload tag
                </p>
              </label>
            </div>
          )}
          <div className="utility-flex-row">
            <label style={{ marginRight: "20px", width: "300px" }}>
              <p
                style={{
                  fontSize: "14px",
                  margin: "30px 0 -11px",
                }}
              >
                MP4 Video File
              </p>
              <input
                type="file"
                onChange={this.handleVideoUpload}
                style={{ display: "none" }}
                accept=".mp4"
              />
              <p
                className="utility-btn-primary"
                style={{ color: "#fff" }}
                tabIndex="0"
                onKeyPress={this.clickParent}
              >
                Upload Video
              </p>
            </label>
          </div>

          <div className="utility-flex-row">
            <VideoJS playbackID={this.state.quick_tap_video_playback_id} />
          </div>

          <div className="utility-flex-row">
            <label style={{ marginRight: "20px", width: "300px" }}>
              <p
                style={{
                  fontSize: "14px",
                  margin: "30px 0 -11px",
                }}
              >
                Background Music
              </p>
            </label>
          </div>

          <div className="utility-flex-row">
            <div
              style={{ margin: "30px 0", alignItems: "center" }}
              className="utility-inline-label"
            >
              <p>Background Music Option</p>
              <Select
                name="session_bgm_id"
                value={this.state.session_bgm_id}
                onChange={this.handleSelectChange}
                style={{ width: "500px" }}
                clearable={false}
                searchable={true}
                options={this.renderBGMOptions()}
              />
            </div>
          </div>

          <div className="utility-flex-row">
            <audio
              id="quick_tap_bg_music_url"
              src={this.state.bg_music_url_preview || this.state.quick_tap_bg_music_url}
              controls
              style={{ width: "300px" }}
            />
          </div>

          <div className="AddEditSession-background-images">
            <h4>Tapping Points Timings</h4>
            <p>Timestamp</p>
            <BackgroundImageTimings
              items={this.state.bg_timestamps}
              removeTimestamp={this.removeTimestamp}
              stateProp="bg_timestamps"
              handleTimestampChange={this.handleTimestampChange}
              tappingPoints={this.props.tapping_points}
            />
            <button
              className="utility-btn-primary"
              onClick={this.addTimestamp}
              style={{ margin: "20px 0" }}
            >
              Add Timestamp
            </button>
          </div>

          {isEdit && (
            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <button
                className="utility-btn-danger"
                onClick={this.handleDelete}
              >
                Archive Session
              </button>
            </div>
          )}
        </MainContentContainer>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    all_categories: state.categories.all_categories,
    all_subcategories: state.subcategories.subcategories
      ? Object.values(state.subcategories.subcategories)
      : [],
    all_quick_tap_sessions: state.quick_taps.all_quick_tap_sessions,
    authors: state.authors.authors,
    tapping_points: state.tapping_points.tapping_points,
    quick_tap_image: state.media.image,
    video: state.media.video,
    currentTags: state.sessions.current_tags,
    background_musics: state.background_musics.background_musics,
  };
}

const mapDispatchToProps = {
  fetchAllCategories,
  fetchAllSubCategories,
  fetchTappingPoints,
  fetchOneQuickTapSession,
  fetchAuthors,
  addEditQuickTapSession,
  deleteQuickTapSession,
  clearMedia,
  uploadImage,
  uploadQuickTapVideo,
  addObjectTag,
  removeObjectTag,
  clearQuickTapSessions,
  fetchAllBGM,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditQuickTapSession);
