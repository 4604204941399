import LoginPage from '../components/Pages/LoginPage/LoginPage';
import LogoutPage from '../components/Pages/LogoutPage/LogoutPage';

// Import Auth User pages

import CategoryPage from '../components/Pages/CategoryPage/CategoryPage';
import CategoryAddEditPage from '../components/Pages/CategoryAddEditPage/CategoryAddEditPage';

import SubCategoryPage from '../components/Pages/SubCategoryPage/SubCategoryPage';
import SubCategoryAddEditPage from '../components/Pages/SubCategoryAddEditPage/SubCategoryAddEditPage';

import AudioBookPage from '../components/Pages/AudioBookPage/AudioBookPage';
import AudioBookAddEditPage from '../components/Pages/AudioBookAddEditPage/AudioBookAddEditPage';

import SeriesPage from '../components/Pages/SeriesPage/SeriesPage';
import SeriesAddEditPage from '../components/Pages/SeriesAddEditPage/SeriesAddEditPage';

import SessionsPage from '../components/Pages/SessionsPage/SessionsPage';
import SessionsAddEditPage from '../components/Pages/SessionsAddEditPage/SessionsAddEditPage';
import SessionDuplicatePage from '../components/Pages/SessionDuplicatePage/SessionDuplicatePage';

import AuthorsPage from '../components/Pages/AuthorsPage/AuthorsPage';
import AuthorsAddEditPage from '../components/Pages/AuthorsAddEditPage/AuthorsAddEditPage';

import TappingPointsPage from '../components/Pages/TappingPointsPage/TappingPointsPage';
import TappingPointsAddEditPage from '../components/Pages/TappingPointsAddEditPage/TappingPointsAddEditPage';

import QuotesPage from '../components/Pages/QuotesPage/QuotesPage';
import QuotesAddEditPage from '../components/Pages/QuotesAddEditPage/QuotesAddEditPage';

import FeedbacksPage from '../components/Pages/FeedbacksPage/FeedbacksPage';
import RequestsPage from '../components/Pages/RequestsPage/RequestsPage';

import PromoBannersPage from '../components/Pages/PromoBannersPage/PromoBannersPage';
import PromoBannerAddEditPage from '../components/Pages/PromoBannerAddEditPage/PromoBannerAddEditPage';

import OnboardingDataPage from '../components/Pages/OnboardingDataPage/OnboardingDataPage';

import ChallengePage from '../components/Pages/ChallengePage/ChallengePage';
import ChallengeAddEditPage from '../components/Pages/ChallengeAddEditPage/ChallengeAddEditPage';

import SearchPage from '../components/Search/SearchPage';
import DailyReflectionPage from '../components/Pages/DailyReflectionPage/DailyReflectionPage';

import PromoDataPage from '../components/Pages/PromoDataPage/PromoDataPage';
import CustomerSupportPage from '../components/Pages/CustomerSupportPage/CustomerSupportPage';
import IntensityReportPage from '../components/Pages/IntensityReportPage/IntensityReportPage';

import BackgroundMusicPage from '../components/Pages/BackgroundMusicPage/BackgroundMusicPage';
import BackgroundAddEditPage from '../components/Pages/BackgroundMusicPage/BackgroundAddEditPage';

import UserCancellationPage from '../components/Pages/UserCancellationPage/UserCancellationPage';
import CancellationReasonPage from '../components/Pages/CancellationReasonPage/CancellationReasonPage';

import QuickTapsPage from '../components/Pages/QuickTapsPage/QuickTapsPage';
import QuickTapsAddEditPage from '../components/Pages/QuickTapsAddEditPage/QuickTapsAddEditPage';
import QuickTapsDuplicatePage from '../components/Pages/QuickTapsDuplicatePage/QuickTapsDuplicatePage';
import QuickTapsFeaturedPage from '../components/Pages/QuickTapsFeaturedPage/QuickTapsFeaturedPage';
import { ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_CUSTOMER_SUPPORT } from './AdminRoles';
import UserStreakPage from '../components/UserStreak/UserStreakPage';
import CardDecksMainPage from '../components/Pages/CardDeck/CardDecksMainPage';
import CardDeckList from '../components/Pages/CardDeck/Card/CardsList';
import AddEditCardDeck from '../components/Pages/CardDeck/AddEditCardDeck';
import AddEditCard from '../components/Pages/CardDeck/Card/AddEditCard';
import MediaResource from '../components/Pages/MediaResources/MediaResource';
import MediaResourceForm from '../components/Pages/MediaResources/MediaResourceForm';
import AdminsPage from '../components/Pages/AdminPage/AdminsPage';
import AdminFormPage from '../components/Pages/AdminPage/AdminFormPage';
import AccountPage from '../components/Pages/Account/AccountPage';
import DailyReflectionForm from '../components/Pages/DailyReflectionPage/DailyReflectionForm';

const RouteConfig = [
  // Main routes
  { order: -1, path: '/', component: LoginPage, exact: true, requiresAuth: false },
  { order: -1, path: '/login', component: LoginPage, exact: true, requiresAuth: false },
  { order: 27, path: '/logout', label: 'Log Out', component: LogoutPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_CUSTOMER_SUPPORT], exact: true, requiresAuth: false },
  // Auth routes
  { order: 3, path: '/audiobooks', label: 'Audiobooks', component: AudioBookPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/audiobooks/add', component: AudioBookAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/audiobooks/edit/:id', component: AudioBookAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 12, path: '/authors', label: 'Authors', component: AuthorsPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/authors/add', component: AuthorsAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/authors/edit/:id', component: AuthorsAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 6, path: '/background-musics', label: 'Background Music', component: BackgroundMusicPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/background-musics/add', component: BackgroundAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/background-musics/edit/:id', component: BackgroundAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 22, path: '/banners', label: 'Banners', component: PromoBannersPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/banners/add', component: PromoBannerAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/banners/edit/:id', component: PromoBannerAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 17, path: '/cancellation-reasons', label: 'Cancelation Reasons', component: CancellationReasonPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 1, path: '/category', label: 'Categories', component: CategoryPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/category/add', component: CategoryAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/category/edit/:id', component: CategoryAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 5, path: '/challenges', label: 'Challenges', component: ChallengePage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/challenges/add', component: ChallengeAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/challenges/edit/:id', component: ChallengeAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 24, path: '/customer-support', label: 'Customer Support Tools', component: CustomerSupportPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_CUSTOMER_SUPPORT], requiresAuth: true, exact: true },
  { order: 10, path: '/daily-inspirations', label: 'Daily Inspirations', component: DailyReflectionPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 10, path: '/daily-inspirations/create', component: DailyReflectionForm, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 10, path: '/daily-inspirations/:id/edit', component: DailyReflectionForm, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 16, path: '/feedbacks', label: 'User Feedback', component: FeedbacksPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_CUSTOMER_SUPPORT], requiresAuth: true, exact: true },
  { order: 23, path: '/intensity-report', label: 'Intensity Report', component: IntensityReportPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 21, path: '/onboarding', label: 'Onboarding', component: OnboardingDataPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 20, path: '/promo', label: 'Promos', component: PromoDataPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 8, path: '/quick-taps', label: 'Quick Taps', component: QuickTapsPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/quick-taps/add', component: QuickTapsAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/quick-taps/duplicate/:id', component: QuickTapsDuplicatePage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/quick-taps/edit/:id', component: QuickTapsAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/quick-taps/featured', component: QuickTapsFeaturedPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 11, path: '/quotes', label: 'Quotes', component: QuotesPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/quotes/add', component: QuotesAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/quotes/edit/:id', component: QuotesAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 19, path: '/requests', label: 'User Requests', component: RequestsPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_CUSTOMER_SUPPORT], requiresAuth: true, exact: true },
  { order: 9, path: '/searches', label: 'Search', component: SearchPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 4, path: '/series', label: 'Series', component: SeriesPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/series/add', component: SeriesAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/series/edit/:id', component: SeriesAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 7, path: '/sessions', label: 'Sessions', component: SessionsPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/sessions/add', component: SessionsAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/sessions/duplicate/:id', component: SessionDuplicatePage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/sessions/edit/:id', component: SessionsAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 2, path: '/subcategories', label: 'Subcategories', component: SubCategoryPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/subcategories/add', component: SubCategoryAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/subcategories/add', component: SubCategoryAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/subcategories/edit/:id', component: SubCategoryAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 13, path: '/tapping-points', label: 'Tapping Points', component: TappingPointsPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/tapping-points/add', component: TappingPointsAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 0, path: '/tapping-points/edit/:id', component: TappingPointsAddEditPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 18, path: '/user-cancellations', label: 'Cancelations Report', component: UserCancellationPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 20, path: '/user-streak', label: 'User Streak', component: UserStreakPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_CUSTOMER_SUPPORT], requiresAuth: true, exact: true },
  { order: 10, path: '/card-decks', label: 'Card Decks', component: CardDecksMainPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 10, path: '/card-decks/create', component: AddEditCardDeck, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 10, path: '/card-deck/:id', component: AddEditCardDeck, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 10, path: '/card-deck/:id/cards', component: CardDeckList, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 10, path: '/card-deck/:deckId/cards/create', component: AddEditCard, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 10, path: '/card-deck/:deckId/cards/:id/edit', component: AddEditCard, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 24, path: '/media-resources', label: 'Media Resources', component: MediaResource, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 24, path: '/media-resources/create', component: MediaResourceForm, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 25, path: '/admins', label: 'Admin users', component: AdminsPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 25, path: '/admins/create', component: AdminFormPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 25, path: '/admins/:id/edit', component: AdminFormPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN], requiresAuth: true, exact: true },
  { order: 26, path: '/my-account', label: 'My Account', component: AccountPage, allowRoles: [ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_CUSTOMER_SUPPORT], requiresAuth: true, exact: true },

];

export default RouteConfig;
