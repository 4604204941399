import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";

import {
  forgotPasswordBatch,
  unlockStripe,
  unlockGoogle,
  getUserStatus,
  deleteUser,
} from "../../../actions/customerSupportAction";

function MyVerticallyCenteredModal(props) {
  const context = props.delete_email.length
    ? `Are you SURE you want to DELETE selected user ? ${props.delete_email}`
    : "PLEASE ENTER EMAIL ADDRESS";

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Body>{context}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          No
        </Button>
        <Button variant="primary" onClick={props.handleUserDelete}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const initialState = {
  forgot_email: "",
  user_email: "",
  stripe_sub_id: "",
  google_sku_id: "",
  google_transaction_id: "",
  google_email: "",
  delete_email: "",
  show: false,
};

class CustomerSupportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgot_email: "",
      user_email: "",
      stripe_sub_id: "",
      google_sku_id: "",
      google_transaction_id: "",
      google_email: "",
      delete_email: "",
      show: false,
    };
  }

  reset = () => {
    this.setState(initialState);
  };
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  handleUserDelete = () => {
    this.handleClose();
    if (this.state.delete_email.length) {
      this.props.deleteUser(this.state.delete_email);
    }
    this.reset();
  };

  handleforgotPasswordSubmit = (e) => {
    e.preventDefault();
    this.props.forgotPasswordBatch(this.state.forgot_email);
    this.reset();
  };

  handleUserStatusSubmit = (e) => {
    e.preventDefault();
    this.props.getUserStatus(this.state.user_email);
    this.reset();
  };
  handleStripeUnlock = (e) => {
    e.preventDefault();
    this.props.unlockStripe(this.state.stripe_sub_id);
    this.reset();
  };

  handleFieldChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { google_sku_id, google_transaction_id, google_email } = this.state;
    this.props.unlockGoogle(google_transaction_id, google_sku_id, google_email);
    this.reset();
  };

  render() {
    return (
      <div className="utility-page-layout">
        <div className=".forgot-password-flex-column">
          <h1>Get User Premium Status </h1>

          <label className="utility-page-layout">
            <div className="forgot-password-inline">
              <p>Enter Email Address</p>
              <input
                className="utility-text-input"
                name="user_email"
                value={this.state.user_email}
                style={{ width: "300px" }}
                onChange={this.handleFieldChange}
              />
              <button
                className="utility-btn-primary"
                onClick={this.handleUserStatusSubmit}
              >
                Submit
              </button>
            </div>
          </label>

          <h1>Get Password Email Link </h1>

          <label className="utility-page-layout">
            <div className="forgot-password-inline">
              <p>Enter Email Address</p>
              <input
                className="utility-text-input"
                name="forgot_email"
                value={this.state.forgot_email}
                style={{ width: "300px" }}
                onChange={this.handleFieldChange}
              />
              <button
                className="utility-btn-primary"
                onClick={this.handleforgotPasswordSubmit}
              >
                Submit
              </button>
            </div>
          </label>

          <h1>Unlock Stripe User Issue </h1>

          <label className="utility-page-layout">
            <div className="forgot-password-inline">
              <p>Enter Stripe Subscription Id</p>
              <input
                className="utility-text-input"
                name="stripe_sub_id"
                value={this.state.stripe_sub_id}
                style={{ width: "300px" }}
                onChange={this.handleFieldChange}
              />
              <button
                className="utility-btn-primary"
                onClick={this.handleStripeUnlock}
              >
                Submit
              </button>
            </div>
          </label>

          <h1>Unlock Android User Issue </h1>

          <form onSubmit={this.handleSubmit}>
            <label className="utility-page-layout">
              <div className="forgot-password-inline">
                <p>1. Enter Product SKU ID</p>
                <input
                  className="utility-text-input"
                  name="google_sku_id"
                  value={this.state.google_sku_id}
                  style={{ width: "300px" }}
                  onChange={this.handleFieldChange}
                />
              </div>
            </label>
            <label className="utility-page-layout">
              <div className="forgot-password-inline">
                <p>2. Enter Google Transaction ID</p>
                <input
                  className="utility-text-input"
                  name="google_transaction_id"
                  value={this.state.google_transaction_id}
                  style={{ width: "300px" }}
                  onChange={this.handleFieldChange}
                />
              </div>
            </label>
            <label className="utility-page-layout">
              <div className="forgot-password-inline">
                <p> 3. Enter Email Address</p>
                <input
                  className="utility-text-input"
                  name="google_email"
                  value={this.state.google_email}
                  style={{ width: "300px" }}
                  onChange={this.handleFieldChange}
                />
              </div>
            </label>
            <button className="utility-btn-primary android-support-button">
              Submit
            </button>
          </form>

          <h1>Delete User </h1>

          <label className="utility-page-layout">
            <div className="forgot-password-inline">
              <p>Enter Email Address</p>
              <input
                className="utility-text-input"
                name="delete_email"
                value={this.state.delete_email}
                style={{ width: "300px" }}
                onChange={this.handleFieldChange}
              />
              <button className="utility-btn-primary" onClick={this.handleShow}>
                Submit
              </button>
            </div>
          </label>
          <MyVerticallyCenteredModal
            delete_email={this.state.delete_email}
            show={this.state.show}
            handleClose={() => this.handleClose()}
            handleUserDelete={() => this.handleUserDelete()}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  forgotPasswordBatch,
  unlockStripe,
  unlockGoogle,
  deleteUser,
  getUserStatus,
};

export default connect(null, mapDispatchToProps)(CustomerSupportPage);
