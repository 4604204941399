import { AppBar, Box, Button, CardMedia, Container, Grid, TextField, Toolbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Cancel, CloudUpload, Save } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import VisuallyHiddenInput from '../../Common/VisuallyHiddenInput';
import { useDispatch, useSelector } from 'react-redux';
import { saveMediaResource } from '../../../actions/mediaResourceActions';
import { Card } from 'react-bootstrap';
import LoadingBackdrop from '../../Common/LoadingBackdrop';

export default function MediaResourceForm() {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.mediaResources.loading);

    const [mediaResource, setMediaResource] = useState({
        name: '',
        description: '',
        file: null
    })

    const handleChange = e => {
        setMediaResource({
            ...mediaResource,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeFile = e => {
        const file = e.target.files[0];
        setMediaResource({
            ...mediaResource,
            file
        })
    }

    const handleSave = () => {
        dispatch(saveMediaResource(mediaResource));
    }

    return (
        <Container sx={{ py: 2 }}>
            <LoadingBackdrop open={loading} />
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Create Media Resource
                    </Typography>
                    <Button color="success" onClick={handleSave} startIcon={<Save />} variant="contained" sx={{ marginRight: 2 }}>
                        Save
                    </Button>
                    <Button color="error" component={Link} to={`/media-resources`} startIcon={<Cancel />} variant="contained">
                        Cancel
                    </Button>
                </Toolbar>
            </AppBar>
            <Box gap={2}>
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                    <Grid spacing={2} item xs={8}>
                        <Grid item xs={12} sx={{ marginBottom: 2 }} >
                            <TextField
                                fullWidth
                                label="Name"
                                variant="outlined"
                                name="name"
                                value={mediaResource.name}
                                onChange={handleChange}
                                autoFocus
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Description"
                                variant="outlined"
                                name="description"
                                multiline
                                minRows={3}
                                value={mediaResource.description}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            fullWidth
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUpload />}
                        >
                            Select File
                            <VisuallyHiddenInput type="file" name='file' onChange={handleChangeFile} />
                        </Button>
                        {
                            mediaResource.file ? (
                                <Card>
                                    <CardMedia
                                        component="img"
                                        image={URL.createObjectURL(mediaResource.file)}
                                        alt=""
                                    />
                                </Card>
                            ) : null
                        }
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}
