import React from "react";
import { Container, Grid, Box, Paper, Typography } from "@mui/material";
import MainContentContainer from "../MainContentContainer/MainContentContainer";
import SuggestedSearches from "./SuggestedSearches";
import TopSearch from "./TopSearch";
import SearchDeeplinks from "./SearchDeeplinks";

const SearchPage = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* Main content section */}
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Search Configuration
        </Typography>
        {/* First row with TopSearch and SearchDeeplinks */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <TopSearch />
          </Grid>
          <Grid item xs={12} md={6}>
            <SearchDeeplinks />
          </Grid>
        </Grid>
      </Paper>

      {/* Suggested Searches Section */}
      <Paper elevation={3} sx={{ p: 3 }}>
        <SuggestedSearches />
      </Paper>
    </Container>
  );
};

export default SearchPage;
