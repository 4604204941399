import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import SessionHelper from "./components/Helper/SessionHelper";

// Import all layouts
import MainLayout from "./layouts/mainLayout";
import AuthLayout from "./layouts/authLayout";

// Import Routes Configuration
import RouteConfig from "./constants/RouteConfig";
// Import static pages

import NotFound from "./components/Static/NotFound";
import UnAuthorized from "./components/Static/UnAuthorized";

const MainRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <MainLayout>
          <Component {...matchProps} />
        </MainLayout>
      )}
    />
  );
};

const AuthRoute = ({ component: Component, allowRoles = [], ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (SessionHelper.isLoggedIn()) {
          if (!SessionHelper.hasRole(allowRoles)) {
            return (
              <MainRoute component={UnAuthorized} />
            );
          }
          return (
            <AuthLayout>
              <Component {...matchProps} />
            </AuthLayout>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: matchProps.location },
              }}
            />);
        }
      }}
    />
  );
};

const Routes = () => (
  <Switch>
    {RouteConfig.map((route, index) => {
      const { path, component, exact, allowRoles, requiresAuth } = route;
      if (requiresAuth) {
        return (
          <AuthRoute
            key={index}
            path={path}
            component={component}
            exact={exact}
            allowRoles={allowRoles}
          />
        );
      } else {
        return (
          <MainRoute
            key={index}
            path={path}
            component={component}
            exact={exact}
          />
        );
      }

    })}
    {<MainRoute component={NotFound} />}
  </Switch>
);
export default Routes;
