import { api, errorHandler, queryString } from './index';
import { push } from 'react-router-redux';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import {
  API_ERROR,
  FETCH_ALL_SUBCATEGORIES,
  FETCH_ALL_SUBCATEGORIES_START,
  FETCH_ONE_SUBCATEGORY,
} from '../constants/ActionTypes';

export function fetchAllSubCategories(type = 0, is_quick_tap = null) {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_SUBCATEGORIES_START
    });

    api.post(`/fetch-all-subcategories.php`,
      queryString.stringify({
        is_audiobook: type,
        is_quick_tap: is_quick_tap
      }))
      .then((response) => {
        const objects = response.data.objects.reduce((acc, el) => {
          acc[el.subcategory_id] = el
          return acc;
        }, {})

        dispatch({
          type: FETCH_ALL_SUBCATEGORIES,
          payload: objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR)
      });
  }
}

export function fetchOneSubcategory(subcategory_id) {
  return function (dispatch) {
    api.post(`/fetch-cms-subcategory.php`,
      queryString.stringify({
        subcategory_id: subcategory_id
      }))
      .then((response) => {
        dispatch({
          type: FETCH_ONE_SUBCATEGORY,
          payload: { [response.data.subcategory_id]: response.data },
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR)
      });
  }
}

export function clearAllSubCategories() {
  return function (dispatch) {
    dispatch({
      type: FETCH_ALL_SUBCATEGORIES,
      payload: null,
    });
  }
}

export function addEditSubCategory(options) {
  return function (dispatch) {
    api.post(`/add-n-edit-subcategory.php`,
      queryString.stringify({
        subcategory_json: options.subcategory_json,
        category_id: options.category_id,
        quotes_json: options.quotes_json,
        session_ids_json: options.session_ids_json,
      }))
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Saved!',
          timer: 1000,
        }).then(() => {
          dispatch(push('/subcategories'));
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR)
      });
  }
}

export function addEditAudioBook(options) {
  return function (dispatch) {
    api.post(`/add-n-edit-subcategory.php`,
      queryString.stringify({
        subcategory_json: options.subcategory_json,
        category_id: options.category_id,
        quotes_json: options.quotes_json,
        session_ids_json: options.session_ids_json,
      }))
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Saved!',
          timer: 1000,
        }).then(() => {
          dispatch(push('/audiobooks'));
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR)
      });
  }
}



export function deleteSubCategory(subcategory_id) {
  return function (dispatch) {
    api.post(`/delete-subcategory.php`,
      queryString.stringify({
        subcategory_id: subcategory_id,
      }))
      .then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Subcategory Deleted!',
          timer: 1000,
        }).then(() => {
          dispatch(push('/subcategories'));
        })
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR)
      });
  }
}

export function nonExistentSubCategory() {
  return function (dispatch) {
    Swal.fire({
      icon: "error",
      text: "Subcategory does not exist!",
      showConfirmButton: true,
      showCancelButton: false,
      confirmButtonText: "Return"
    }).then(() => {
      dispatch(push("/subcategories"));
    });
  };
}
