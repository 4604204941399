import { api, errorHandler, queryString } from "./index";
import { API_ERROR, FETCH_USER_STREAK, RESTORE_USER_STREAK, FETCH_USER_STREAK_ERROR } from "../constants/ActionTypes";

export function fetchUserStreak(options) {
  return function (dispatch) {
    api.post(`/v2/admin/fetch-user-streak.php`,
      queryString.stringify({
        days: options.days,
        user_email: options.user_email
      })
    )
      .then((response) => {
        dispatch({
          type: FETCH_USER_STREAK,
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_USER_STREAK_ERROR,
          payload: []
        });
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function restoreUserStreak(options) {
  return function (dispatch) {
    api.post(`/restore-user-streak.php`,
      queryString.stringify({
        days: options.days,
        email: options.user_email
      })
    )
      .then((response) => {
        dispatch({
          type: RESTORE_USER_STREAK,
          payload: response.data
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

