import { FETCH_ALL_BACKGROUND_MUSICS } from "../constants/ActionTypes";

const INITIAL_STATE = { background_musics: [] };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_BACKGROUND_MUSICS:
      return { ...state, background_musics: action.payload };
    default:
      return state;
  }
}
