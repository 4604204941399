import { Grid, TextField, FormGroup, Switch, FormControlLabel, Autocomplete, CircularProgress, Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ImageContainerPreview from '../../../Common/ImageContainerPreview'
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage, clearMedia } from '../../../../actions/mediaActions';
import LinkedContentButton from './LinkedContentButton';
import FieldSetGroup from '../../../Elements/FieldSetGroup'

export default function CardFormFields({ card, handleChange }) {
    const dispatch = useDispatch();
    const mediaResources = useSelector(state => state.mediaResources.mediaResources);
    const mediaResourceLoading = useSelector(state => state.mediaResources.loading);
    const [selectedMedia, setSelectedMedia] = useState('');

    const mediaStore = useSelector((state) => {
        return state.media
    });

    useEffect(() => {
        if (mediaStore.image) {
            card[mediaStore.target] = mediaStore.image;
            dispatch(clearMedia());
            handleChange({ target: { name: mediaStore.target, value: mediaStore.image } });
        }
    }, [mediaStore]);

    useEffect(() => {
        handleMediaResourceSelection(card.linked_content_button_icon_url);
    }, [card]);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        dispatch(uploadImage(file, e.target.name));
    };

    const removeImage = (e) => {
        handleChange({ target: { name: e.target.name, value: "" } });
    }

    const handleSwitchChange = (e) => {
        handleChange({ target: { name: e.target.name, value: e.target.checked ? 1 : 0 } });
    }

    const handleLinkedContentIconChange = (_,v) => {
        handleChange({ target: { name: 'linked_content_button_icon_url', value: v?.resource_url } });
        handleMediaResourceSelection(v?.resource_url);
    };

    const handleMediaResourceSelection = url => {
        setSelectedMedia(mediaResources.find((resource) => resource.resource_url === url));
    }

    return (
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    label="Title"
                    variant="outlined"
                    name="title"
                    value={card.title || ""}
                    onChange={handleChange}
                    autoFocus
                />
            </Grid>
            <Grid container item xs={4} justifyContent="flex-end" alignItems="center">
                <FormGroup>
                    <FormControlLabel
                        control={<Switch aria-label="is active" checked={Boolean(card.is_active)} onChange={handleSwitchChange} name="is_active" />}
                        label="Is Active?"
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <FieldSetGroup title="Card Images">
                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={3}>
                            <ImageContainerPreview
                                title="Front Image"
                                image={card.front_image_url || ""}
                                name="front_image_url"
                                handleRemove={removeImage}
                                handleImageUpload={handleImageUpload}
                                sx={{ padding: 1, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <ImageContainerPreview
                                title="Back Image"
                                image={card.back_image_url || ""}
                                name="back_image_url"
                                handleRemove={removeImage}
                                handleImageUpload={handleImageUpload}
                                sx={{ padding: 1, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
                            />
                        </Grid>
                    </Grid>
                </FieldSetGroup>
            </Grid>
            <Grid item xs={12}>
                <FieldSetGroup title="Sharing">
                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={3}>
                            <ImageContainerPreview
                                title="Share Image"
                                image={card.share_image_url || ""}
                                handleRemove={removeImage}
                                handleImageUpload={handleImageUpload}
                                name="share_image_url"
                                showCopyUrlButton={true}
                                sx={{ padding: 5, background: '#FFFCF5', height: 250, objectFit: 'contain' }}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                <TextField
                                    fullWidth
                                    label="Share Deeplink URL"
                                    variant="outlined"
                                    name="share_deeplink_url"
                                    value={card.share_deeplink_url || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </FieldSetGroup>
            </Grid>
            <Grid item xs={12} >
                <FieldSetGroup title="Linked Content">
                    <Grid container spacing={2} item xs={12} >
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Link (Web)"
                                variant="outlined"
                                name="target_web_deeplink_url"
                                value={card.target_web_deeplink_url || ""}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Link (Android)"
                                variant="outlined"
                                name="target_android_deeplink_url"
                                value={card.target_android_deeplink_url || ""}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                label="Link (iOS)"
                                variant="outlined"
                                name="target_ios_deeplink_url"
                                value={card.target_ios_deeplink_url || ""}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Button Text"
                                variant="outlined"
                                name="linked_content_button_text"
                                value={card.linked_content_button_text || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                id='icon-select'
                                disablePortal
                                options={mediaResources}
                                getOptionLabel={(option) => option ? `${option.name}` : ''}
                                getOptionKey={(option) => option.id}
                                value={selectedMedia || null}
                                loading={mediaResourceLoading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select a media resource"
                                        variant="outlined"
                                        placeholder="Select a media resource"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                            <React.Fragment>
                                                {mediaResourceLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                            ),
                                        }}
                                        />
                                )}
                                onChange={handleLinkedContentIconChange}
                                noOptionsText="No media resources found"
                                loadingText="Loading media resources..."
                                clearText="Clear"
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        key={option.id}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '8px',
                                            textAlign: 'left',
                                        }}
                                        >
                                        <Box
                                            component="img"
                                            src={option.resource_url}
                                            alt={option.name}
                                            sx={{
                                                width: 40,
                                                height: 40,
                                                objectFit: 'cover',
                                                borderRadius: '4px',
                                                marginRight: '12px',
                                            }}
                                        />

                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <Typography variant="subtitle1" component="div">
                                                {option.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {option.description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                                isOptionEqualToValue={(option, value) => option.resource_url === value.resource_url}
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <LinkedContentButton sx={{ background: '#FFFCF5' }} card={card} title="Linked Content Button Preview" />
                        </Grid>
                    </Grid>
                </FieldSetGroup>
            </Grid>
        </Grid>
    )
}
