import { FETCH_ALL_ONBOARDING_DATA } from "../constants/ActionTypes";

const INITIAL_STATE = { onboarding_data: null };

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_ONBOARDING_DATA:
      return { ...state, onboarding_data: action.payload };
    default:
      return state;
  }
}
