import React from "react";
import { createRoot } from "react-dom/client";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import Routes from "./routes";
import store, { history } from "./store/configureStore";
import ScrollMemory from "react-router-scroll-memory";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import "./style.css";
import { useAppTheme } from './theme';

function ThemedApp() {
  const theme = useAppTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <div>
          <ScrollMemory />
          <Routes />
        </div>
      </Router>
    </ThemeProvider>
  );
}

const root = createRoot(
  document.getElementById("root")
);


root.render(
  <Provider store={store}>
    <ThemedApp />
  </Provider>
);