import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderBar from "./../HeaderBar/HeaderBar";
import MainContentContainer from "./../MainContentContainer/MainContentContainer";
import {
  fetchAllPromoBanners,
  addEditPromoBanner,
  deletePromoBanner,
} from "./../../actions/promoBannerActions";
import { uploadImage, clearMedia } from "./../../actions/mediaActions";
import Select from "react-select";
import "react-select/dist/react-select.css";
import Swal from "sweetalert2";

// import "./AddEditPromoBanner.css";

class AddEditPromoBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promo_banner_url: "",
      promo_banner_title: "",
      promo_banner_text: "",
      promo_banner_is_active: 0,
      promo_banner_icon: "",
      isSetToEdit: false,
      bannerImageUploading: false,
      bannerPreview: null,
      iconImageUploading: false,
      iconPreview: null,
      is_winback: 0,
    };
  }

  componentDidMount() {
    this.props.fetchAllPromoBanners();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isEdit && nextProps.banners && !this.state.isSetToEdit) {
      let currentItem = null;
      for (let x = 0; x < nextProps.banners.length; x++) {
        if (
          nextProps.banners[x].promo_banner_id === Number(this.props.selectedId)
        ) {
          currentItem = nextProps.banners[x];
          break;
        }
      }
      if (currentItem === null) {
        Swal.fire({
          icon: "warning",
          text: "Error finding selected Promo Banner to edit",
        });
      }
      this.setState({
        ...currentItem,
        bannerPreview: null,
        iconPreview: null,
        isSetToEdit: true,
      });
    }

    if (nextProps.image && !this.props.image) {
      if (this.state.bannerImageUploading) {
        const image = nextProps.image;
        this.setState({
          bannerImageUploading: false,
          promo_banner_url: image,
        });
        this.props.clearMedia();
      }

      if (this.state.iconImageUploading) {
        const image = nextProps.image;
        this.setState({
          iconImageUploading: false,
          promo_banner_icon: image,
        });
        this.props.clearMedia();
      }
    }
  }

  componentWillUnmount() {
    if (this.state.bannerPreview) {
      window.URL.revokeObjectURL(this.state.bannerPreview);
    }
    if (this.state.iconPreview) {
      window.URL.revokeObjectURL(this.state.iconPreview);
    }
    this.props.clearMedia();
  }

  handleFieldChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  handleBannerImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.bannerPreview) {
      window.URL.revokeObjectURL(this.state.bannerPreview);
    }
    this.setState({
      bannerPreview: window.URL.createObjectURL(file),
      bannerImageUploading: true,
    });
    this.props.uploadImage(file);
  };

  handleCheckBox = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    console.log("name", name);
    this.setState({
      [name]: value === true ? 1 : 0,
    });
  };

  handleIconImageUpload = (e) => {
    if (!e) {
      return false;
    }
    const file = e.target.files[0];
    if (!file) {
      return false;
    }
    if (this.state.iconPreview) {
      window.URL.revokeObjectURL(this.state.iconPreview);
    }
    this.setState({
      iconPreview: window.URL.createObjectURL(file),
      iconImageUploading: true,
    });
    this.props.uploadImage(file);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Saving...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    if (this.state.promo_banner_url === "") {
      Swal.fire({
        icon: "error",
        text: "You must upload a Promo Banner image.",
      });
      return false;
    }

    let { isSetToEdit, bannerPreview, iconPreview, ...data } = this.state;

    // if (this.props.banner) {
    //   data.promo_banner_url = this.props.banner;
    // }

    // if (this.props.icon) {
    //   data.promo_banner_icon = this.props.icon;
    // }

    data = JSON.stringify(data);
    this.props.addEditPromoBanner(data);
  };

  handleDelete = (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "warning",
      text: "Are you sure you want to delete this Banner?",
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Delete Item",
    }).then((result) => {
      if (result.value) {
        this.props.deletePromoBanner(this.props.selectedId);
      }
    });
  };

  render() {
    const { isEdit } = this.props;

    if (!this.props.banners) {
      return <h1>Loading...</h1>;
    }
    console.log("edit promo banner state", this.state);
    return (
      <div>
        <form
          className="AddEditArtist"
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <HeaderBar header={isEdit ? "Edit Promo Banner" : "Add Promo Banner"}>
            {isEdit && (
              <Link
                to="/banners"
                className="utility-btn-transparent utility-lr-margin-small"
              >
                Cancel
              </Link>
            )}
            <button className="utility-btn-primary">
              {isEdit ? "Update" : "Create"}
            </button>
          </HeaderBar>
          <MainContentContainer subContent={true}>
            <div className="utility-inline-label">
              <p>Banner Image</p>
              <div className="utility-flex-row">
                {(this.state.bannerPreview || this.state.promo_banner_url) && (
                  <img
                    src={
                      this.state.bannerPreview || this.state.promo_banner_url
                    }
                    alt=""
                    style={{ maxHeight: "200px", marginRight: "50px" }}
                  />
                )}
                <label className="AddEditArtist-img-upload">
                  <input
                    type="file"
                    onChange={this.handleBannerImageUpload}
                    accept="image/*"
                  />
                  <p className="utility-btn-primary" style={{ color: "#fff" }}>
                    Upload Image
                  </p>
                </label>
              </div>
            </div>
            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <p>Is Active</p>
                <Select
                  name="form-field-name"
                  value={this.state.promo_banner_is_active}
                  onChange={(e) =>
                    this.setState({ promo_banner_is_active: e.value })
                  }
                  style={{ width: "150px" }}
                  clearable={false}
                  searchable={false}
                  options={[
                    { value: 1, label: "Active" },
                    { value: 0, label: "Not Active" },
                  ]}
                />
              </label>
            </div>
            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <p>Is Winback</p>
                <input
                  name="is_winback"
                  type="checkbox"
                  value={this.state.is_winback === 0 ? false : true}
                  onChange={this.handleCheckBox}
                  checked={this.state.is_winback === 0 ? false : true}
                  style={{ width: "150px" }}
                  clearable={false}
                  searchable={false}
                />
              </label>
            </div>
            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <p>Banner Title</p>
                <input
                  className="utility-text-input"
                  name="promo_banner_title"
                  value={this.state.promo_banner_title}
                  onChange={this.handleFieldChange}
                  style={{ width: "580px" }}
                />
              </label>
            </div>

            <div className="utility-flex-row">
              <label className="utility-inline-label">
                <p>Banner Description</p>
                <textarea
                  style={{ width: "580px" }}
                  className="utility-textarea"
                  name="promo_banner_text"
                  value={this.state.promo_banner_text}
                  onChange={this.handleFieldChange}
                />
              </label>
            </div>
            <div className="utility-inline-label">
              <p>Icon Image</p>
              <div className="utility-flex-row">
                {(this.state.iconPreview || this.state.promo_banner_icon) && (
                  <img
                    src={this.state.iconPreview || this.state.promo_banner_icon}
                    alt=""
                    style={{
                      maxWidth: "100px",
                      marginRight: "50px",
                      background: "#888",
                      padding: "10px 5px 10px 5px",
                    }}
                  />
                )}
                <label className="AddEditArtist-img-upload">
                  <input
                    type="file"
                    onChange={this.handleIconImageUpload}
                    accept="image/*"
                  />
                  <p className="utility-btn-primary" style={{ color: "#fff" }}>
                    Upload Image
                  </p>
                </label>
              </div>
            </div>

            {isEdit && (
              <div style={{ marginTop: "20px" }}>
                <button
                  className="utility-btn-danger"
                  onClick={this.handleDelete}
                >
                  Delete Banner
                </button>
              </div>
            )}
          </MainContentContainer>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    banners: state.banners.banners,
    image: state.media.image,
  };
}

const mapDispatchToProps = {
  fetchAllPromoBanners,
  addEditPromoBanner,
  deletePromoBanner,
  uploadImage,
  clearMedia,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPromoBanner);
