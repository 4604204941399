import { AppBar, Button, Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import LoadingBackdrop from '../../Common/LoadingBackdrop'
import { Add, Delete, Edit, Password } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom';
import { deleteAdmin, fetchAllAdmins, resetPasswordAdmin } from '../../../actions/adminsActions'
import SessionHelper from '../../Helper/SessionHelper'
import Swal from 'sweetalert2'

export default function AdminsPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector(state => state.admins.loading);
    const admins = useSelector(state => state.admins.admins);
    const user = SessionHelper.currentUser();

    useEffect(() => {
        fetchAdmins();
    }, []);

    const fetchAdmins = () => {
        dispatch(fetchAllAdmins());
    }

    const handleEdit = (admin) => {
        history.push(`/admins/${admin.admin_id}/edit`);
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: `Are you sure?`,
            text: 'You will not be able to recover this Admin user!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel'
        }).then((result) => {
            if (result.value) {
                dispatch(deleteAdmin(id));
            }
        });
    }

    const handleReset = (id) => {
        Swal.fire({
            title: `Are you sure?`,
            text: 'You will not be able to revert this operation!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Reset Password!',
            cancelButtonText: 'No, cancel'
        }).then((result) => {
            if (result.value) {
                dispatch(resetPasswordAdmin(id));
            }
        });
    }

    return (
        <Container sx={{ py: 2 }}>
            <LoadingBackdrop open={loading} />
            <AppBar position="sticky">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Admin users
                    </Typography>
                    <Button color="inherit" component={Link} to={`/admins/create`} startIcon={<Add />}>
                        Add New
                    </Button>
                </Toolbar>
            </AppBar>

            <TableContainer component={Paper} sx={{ marginTop: 3 }}>
                <Table aria-label="admins table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableCell><strong>Name</strong></TableCell>
                            <TableCell><strong>Email</strong></TableCell>
                            <TableCell><strong>Role</strong></TableCell>
                            <TableCell align="right"><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            admins.length > 0 ? (
                                admins
                                    .filter(admin => admin.admin_id !== user.admin_id)
                                    .map(admin => (
                                        <TableRow
                                            key={admin.admin_id}
                                        >
                                            <TableCell>
                                                <Typography variant="subtitle1">{admin.admin_name}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle1">{admin.admin_email}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle1">
                                                    {SessionHelper.roleString(admin.admin_role)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="Reset Password" placement="top">
                                                    <IconButton color="warning" onClick={() => handleReset(admin.admin_id)}>
                                                        <Password />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit" placement="top">
                                                    <IconButton color="info" onClick={() => handleEdit(admin)}>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete" placement="top">
                                                    <IconButton color="error" onClick={() => handleDelete(admin.admin_id)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                            ) : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}
