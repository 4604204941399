import { api, errorHandler, queryString } from "./index";
import {
  API_ERROR,
  FETCH_ALL_TOP_SEARCH,
  DELETE_TOP_SEARCH,
} from "../constants/ActionTypes";
import Swal from "sweetalert2";

export function fetchTopSearches() {
  return function (dispatch) {
    api.post(`/fetch-top-searches.php`)
      .then((response) => {
        dispatch({
          type: FETCH_ALL_TOP_SEARCH,
          payload: response.data.objects,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function deleteTopSearch(top_search_id) {
  return function (dispatch) {
    api.post(`/delete-one-top-search.php`,
      queryString.stringify({
        top_search_id: top_search_id,
      })
    )
      .then((response) => {
        dispatch({
          type: DELETE_TOP_SEARCH,
          payload: top_search_id,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function addOrEditTopSearch(top_search_data) {
  return function (dispatch) {
    api.post(`/add-n-edit-top-searches.php`,
      queryString.stringify({
        top_search_data: top_search_data,
      })
    )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Top Search Updated!",
          timer: 1000,
        }).then(() => {
          window.location.reload();
        });
      })

      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
