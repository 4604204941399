import React, { useEffect, useRef } from "react";
import { Box, CssBaseline, Drawer, List, ListItemButton, ListItemText, Toolbar } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import RouteConfig from '../constants/RouteConfig';
import SessionHelper from '../components/Helper/SessionHelper';
import ModalContainer from "../components/Modal/ModalContainer";
import UserInfo from "../components/User/UserInfo";
import AuthNav from "../components/Nav/AuthNav/AuthNav";

function AuthLayout({ children }) {
  const drawerWidth = 300;
  const location = useLocation();
  const scrollRef = useRef(null);

  const navRoutes = RouteConfig
    .filter(route => route.label && SessionHelper.hasRole(route.allowRoles))
    .sort((a, b) => a.order - b.order);

  // Use effect to handle scroll restoration
  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("menuScrollPosition");
    if (savedScrollPosition && scrollRef.current) {
      scrollRef.current.scrollTop = parseInt(savedScrollPosition, 10);
    }
  }, [location]);

  // Save scroll position before unmounting
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        sessionStorage.setItem("menuScrollPosition", scrollRef.current.scrollTop);
      }
    };

    const currentScrollRef = scrollRef.current;
    if (currentScrollRef) {
      currentScrollRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentScrollRef) {
        currentScrollRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const MenuItemList = () => (
    <List>
      {navRoutes.map((route, index) => (
        <ListItemButton
          key={index}
          component={Link}
          to={route.path}
          selected={location.pathname.includes(route.path)}
        >
          <ListItemText primary={route.label} />
        </ListItemButton>
      ))}
    </List>
  );

  const MainContent = () => (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      {children}
    </Box>
  );

  const NewNav = () => (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            transition: 'transform 0.3s ease-in-out',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <img src="/img/top-ts-logo.jpg" alt="Company Logo" style={{ width: '100%', height: 'auto' }} />
        </Box>
        <UserInfo user={SessionHelper.currentUser()} />
        <Box
          ref={scrollRef}
          sx={{ flexGrow: 1, overflowY: 'auto' }}
        >
          <MenuItemList />
        </Box>
      </Drawer>
      <MainContent />
      <ModalContainer />
    </Box>
  );

  const OldNav = () => (
    <div className="AuthLayout">
      <AuthNav navRoutes={navRoutes} />
      <main>
        <MainContent />
        <ModalContainer />
      </main>
    </div>
  );

  return <OldNav />;
}

export default AuthLayout;