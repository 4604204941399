import { push } from "react-router-redux";
import { api, errorHandler, queryString } from "./index";
import {
  API_ERROR,
  FETCH_ALL_PROMO_DATA,
  FETCH_ALL_GENERAL_DATA
} from "../constants/ActionTypes";
import Swal from "sweetalert2";

export function fetchPromoData() {
  return function (dispatch) {
    api.post(`/fetch-all-promo-data.php`)
      .then(response => {
        dispatch({
          type: FETCH_ALL_PROMO_DATA,
          payload: response.data
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updatePromoData(promo_data_json) {
  return function (dispatch) {
    api.post(`/update-promo-data.php`,
      queryString.stringify({
        promo_data_json: promo_data_json,
      })
    )
      .then(response => {
        Swal.fire({
          icon: "success",
          title: "Saved!",
          timer: 1000
        }).then(() => {
          dispatch(push("/promo"));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updateOnboardingPromoData(promo_data_json) {
  return function (dispatch) {
    api.post(`/update-promo-data.php`,
      queryString.stringify({
        promo_data_json: promo_data_json,
      })
    )
      .then(response => {
        Swal.fire({
          icon: "success",
          title: "Saved!",
          timer: 1000
        }).then(() => {
          dispatch(push("/onboarding"));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function fetchGeneralInfo() {
  return function (dispatch) {
    api.post(`/fetch-general-info.php`)
      .then(response => {
        dispatch({
          type: FETCH_ALL_GENERAL_DATA,
          payload: response.data
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

// TODO Possible removal
export function updateGeneralInfo({
  default_text_image_url,
  default_text_page_url,
  default_text_page_ios_url
}) {
  return async function (dispatch) {
    try {
      await api.post(`/update-general-info.php`,
        queryString.stringify({
          general_info_key: "default_text_page_url",
          general_info_value: default_text_page_url,
        })
      );
      await api.post(`/update-general-info.php`,
        queryString.stringify({
          general_info_key: "default_text_page_ios_url",
          general_info_value: default_text_page_ios_url,
        })
      );
    } catch (error) {
      return errorHandler(dispatch, error.response, API_ERROR);
    }
    Swal.fire({
      icon: "success",
      title: "Saved!",
      timer: 1000
    }).then(() => {
      dispatch(push("/promo"));
    });
  };
}
