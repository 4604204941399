import { api, errorHandler } from "./index";
import {
  API_ERROR,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_TARGET,
  UPLOAD_AUDIO,
  UPLOAD_VIDEO,
  UPLOAD_MUX_VIDEO,
  CLEAR_MEDIA,
} from "../constants/ActionTypes";
import Swal from "sweetalert2";
import * as UpChunk from "@mux/upchunk";

//= ===============================
// Media actions
//= ===============================

export function uploadImage(file, target = "") {
  return function (dispatch) {
    Swal.fire({
      title: "Loading...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    var form = new FormData();
    form.append("file", file);

    api.post(`/upload-image.php`, form)
      .then((response) => {
        Swal.fire({
          title: "Image Uploaded",
          icon: "success",
          timer: 1000,
        });
        if (target !== "") {
          dispatch({
            type: UPLOAD_IMAGE_TARGET,
            payload: {
              image_url: response.data.image_url,
              target: target
            }
          });
        } else {
          dispatch({
            type: UPLOAD_IMAGE,
            payload: response.data.image_url,
          });
        }
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function uploadCategoryImage(category_id, file) {
  return function (dispatch) {
    Swal.fire({
      title: "Loading...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });
    var form = new FormData();
    form.append("category_id", category_id);
    form.append("file", file);

    api.post(`/upload-image.php`, form)
      .then((response) => {
        Swal.fire({
          title: "Image Uploaded",
          icon: "success",
          timer: 1000,
        });
        dispatch({
          type: UPLOAD_IMAGE,
          payload: response.data.image_url,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
export function uploadAudio(file) {
  return function (dispatch) {
    Swal.fire({
      title: "Loading...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    var form = new FormData();
    form.append("file", file);

    api.post(`/upload-audio.php`, form)
      .then((response) => {
        console.log("audio upload response: ", response);
        Swal.fire({
          title: "Audio Uploaded",
          icon: "success",
          timer: 1000,
        });
        dispatch({
          type: UPLOAD_AUDIO,
          payload: response.data.audio_file_url,
        });
      })
      .catch((error) => {
        console.log("audio upload error: ", error);
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function uploadVideo(file) {
  return function (dispatch) {
    Swal.fire({
      title: "Loading...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    var form = new FormData();
    form.append("file", file);

    api.post(`/upload-video.php`, form)
      .then((response) => {
        console.log("video upload response: ", response);
        Swal.fire({
          title: "Video Uploaded",
          icon: "success",
          timer: 1000,
        });
        dispatch({
          type: UPLOAD_VIDEO,
          payload: response.data.video_url,
        });
      })
      .catch((error) => {
        console.log("audio upload error: ", error);
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function uploadQuickTapVideo(file) {
  return async function (dispatch) {
    Swal.fire({
      title: "Loading...",
      animation: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    const form = new FormData();

    try {
      const response = await api.post(`/get-quick-tap-upload-url.php`, form);
      const { url, id: upload_id } = response.data;

      const upload = UpChunk.createUpload({
        endpoint: url,
        file,
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });

      // Subscribe to events
      upload.on("error", (error) => {
        console.error("uploading video error", error.detail);
      });

      upload.on("progress", (progress) => {
        console.log("uploading video progress", progress.detail);
      });

      upload.on("success", async () => {
        form.append("upload_id", upload_id);
        const response = await api.post(`/get-quick-tap-asset-data.php`, form);
        const { playback_id, duration } = response.data;
        Swal.fire({
          title: "Video Uploaded",
          icon: "success",
          timer: 1000,
        });
        dispatch({
          type: UPLOAD_MUX_VIDEO,
          payload: {
            playback_id,
            duration,
          },
        });
      });
    } catch (error) {
      errorHandler(dispatch, error.response, API_ERROR);
    }
  };
}

export function clearMedia() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_MEDIA,
    });
  };
}
