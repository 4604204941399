import { api, errorHandler, queryString } from "./index";
import { push } from "react-router-redux";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { API_ERROR, FETCH_ALL_ONBOARDING_DATA } from "../constants/ActionTypes";

//= ===============================
// Client actions
//= ===============================

export function fetchOnboardingData() {
  return function (dispatch) {
    api.post(`/fetch-all-onboarding-data.php`)
      .then(response => {
        console.log(response);
        dispatch({
          type: FETCH_ALL_ONBOARDING_DATA,
          payload: response.data
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}

export function updateOnboardingData(onboarding_data_json) {
  return function (dispatch) {
    api.post(`/update-all-onboarding-data.php`,
      queryString.stringify({
        onboarding_data_json: onboarding_data_json,
      })
    )
      .then(response => {
        Swal.fire({
          icon: "success",
          title: "Saved!",
          timer: 1000
        }).then(() => {
          dispatch(push("/onboarding"));
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, API_ERROR);
      });
  };
}
