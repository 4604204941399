import { Save } from '@mui/icons-material'
import { Alert, AppBar, Box, Button, Container, Grid, TextField, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SessionHelper from '../../Helper/SessionHelper';
import LoadingBackdrop from '../../Common/LoadingBackdrop';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../../actions/adminsActions';
import Swal from 'sweetalert2';
import AvatarWithOverlay from '../../Common/AvatarWithOverlay';

export default function AccountPage() {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.admins.loading);
    const [admin, setAdmin] = useState(null);

    useEffect(() => {
        fetchAndSetAdmin();
    }, []);

    const handleChange = e => {
        setAdmin({
            ...admin,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeFile = e => {
        const file = e.target.files[0];

        if (!file) return;

        setAdmin({
            ...admin,
            admin_image: URL.createObjectURL(file),
            profile_image: file
        })
    }

    const fetchAndSetAdmin = () => {
        const currentUser = SessionHelper.currentUser();
        setAdmin(currentUser);
    }

    const handleSave = e => {
        e.preventDefault();

        const passwordChanged = admin && admin.admin_password && admin.admin_password.trim() !== '';

        if (passwordChanged && (admin.admin_password !== admin.admin_password_confirmation)) {
            Swal.fire({
                title: 'Password Mismatch',
                text: 'Password and password confirmation do not match',
                icon: 'error'
            })
            return;
        }

        dispatch(updateProfile(admin));
    }

    return (
        <Container sx={{ py: 2 }}>
            <form autoComplete="off" onSubmit={handleSave}>
                <LoadingBackdrop open={!admin || loading} />
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            My Account
                        </Typography>
                        <Button color="inherit" startIcon={<Save />} type="submit">
                            Save
                        </Button>
                    </Toolbar>
                </AppBar>

                <input type="text" style={{ display: 'none' }} autoComplete="user-fake-field" />

                <Box
                    gap={2}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center"
                    }}
                >

                    <Grid
                        container
                        direction="column"
                        spacing={2}
                        sx={{
                            marginTop: 2
                        }}
                        item
                        xs={6}
                    >
                        <Grid item xs={12}>
                            <AvatarWithOverlay
                                src={admin?.admin_image}
                                alt={admin?.admin_name}
                                size={150}
                                handleChangeFile={handleChangeFile}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Name"
                                variant="outlined"
                                name="admin_name"
                                value={admin?.admin_name ?? ''}
                                onChange={handleChange}
                                autoFocus
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="email"
                                fullWidth
                                label="Email"
                                variant="outlined"
                                name="admin_email"
                                value={admin?.admin_email ?? ''}
                                onChange={handleChange}
                                required
                                autoComplete='off'
                                inputProps={{
                                    autoComplete: 'user-profile-email'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Alert severity="info">
                                Fill in the password field only if you want to change your current password. Otherwise, leave it blank.
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="password"
                                fullWidth
                                label="Password"
                                variant="outlined"
                                name="admin_password"
                                value={admin?.admin_password ?? ''}
                                onChange={handleChange}
                                autoComplete='new-password'
                                inputProps={{
                                    autoComplete: 'new-password'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="password"
                                fullWidth
                                label="Password Confirmation"
                                variant="outlined"
                                name="admin_password_confirmation"
                                value={admin?.admin_password_confirmation ?? ''}
                                onChange={handleChange}
                                autoComplete='off'
                                inputProps={{
                                    autoComplete: 'user-profile-new-password-confirmation'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Container>
    )
}
