import {
  FETCH_ALL_CHALLENGES,
  FETCH_ONE_CHALLENGE,
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  challenges: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_CHALLENGES:
      return { ...state, challenges: action.payload };
    case FETCH_ONE_CHALLENGE:
      console.log("FETCH_ONE_CHALLENGE",action.payload);
      return { ...state, challenges: {...state.challenges, ...action.payload} };
    default:
      return state;
  }
}
