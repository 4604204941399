import React from "react";
import { Link } from "react-router-dom";
import CategoriesTable from "./../../CategoriesTable/CategoriesTable";

function CategoryPage() {
  return (
    <CategoriesTable />
  );
}

export default CategoryPage;