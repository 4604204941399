import { CLOSE_DAILY_REFLECTION_FORM, DELETE_DAILY_REFLECTION, DELETE_DAILY_REFLECTION_ERROR, DELETE_DAILY_REFLECTION_SUCCESS, FETCH_ALL_DAILY_REFLECTIONS, FETCH_ALL_DAILY_REFLECTIONS_ERROR, FETCH_ALL_DAILY_REFLECTIONS_SUCCESS, FETCH_ONE_DAILY_REFLECTION, FETCH_ONE_DAILY_REFLECTION_ERROR, FETCH_ONE_DAILY_REFLECTION_SUCCESS, SAVE_DAILY_REFLECTION, SAVE_DAILY_REFLECTION_ERROR, SAVE_DAILY_REFLECTION_SUCCESS } from "../constants/ActionTypes";

const INITIAL_STATE = {
  loading: false,
  daily_reflections: null,
  selectedDailyReflection: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ALL_DAILY_REFLECTIONS:
    case SAVE_DAILY_REFLECTION:
    case DELETE_DAILY_REFLECTION:
    case FETCH_ONE_DAILY_REFLECTION:
      return {
        ...state,
        loading: true
      }
    case CLOSE_DAILY_REFLECTION_FORM:
      return {
        ...state,
        selectedDailyReflection: null
      }
    case FETCH_ALL_DAILY_REFLECTIONS_SUCCESS:
      return { 
        ...state, 
        daily_reflections: action.payload,
        loading: false,
        selectedDailyReflection: null
      };
    case SAVE_DAILY_REFLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        daily_reflections: null,
        selectedDailyReflection: null
      }
    case DELETE_DAILY_REFLECTION_SUCCESS:
      return {
        ...state,
        daily_reflections: state.daily_reflections.filter(dr => dr.daily_reflection_id !== action.payload),
        loading: false
      }
    case FETCH_ONE_DAILY_REFLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedDailyReflection: action.payload
      }
    case FETCH_ALL_DAILY_REFLECTIONS_ERROR:
    case SAVE_DAILY_REFLECTION_ERROR:
    case DELETE_DAILY_REFLECTION_ERROR:
      case FETCH_ONE_DAILY_REFLECTION_ERROR:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}
