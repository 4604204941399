import { Avatar, Box, IconButton, Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { useRef } from 'react';

function AvatarWithOverlay({ src, alt, size, handleChangeFile }) {
    const fileInputRef = useRef(null);

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-block',
                width: size,
                height: size,
                '&:hover .overlay': {
                    opacity: 1,
                },
            }}
        >
            <Avatar
                src={src}
                alt={alt}
                sx={{
                    width: size,
                    height: size,
                }}
            />

            <Box
                className="overlay"
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    opacity: 0,
                    transition: 'opacity 0.3s ease',
                }}
            >
                <Tooltip
                    title="Change profile image"
                >
                    <IconButton
                        onClick={handleClick}
                        sx={{
                            color: '#fff',
                        }}
                    >
                        <EditIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                </Tooltip>
            </Box>

            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleChangeFile}
            />
        </Box>
    );
}

export default AvatarWithOverlay;
