import React from "react";
import { useSortable } from '@dnd-kit/sortable';
import { MdReorder, MdDelete } from 'react-icons/md';
import { Box, IconButton, FormControl, InputLabel, MenuItem, Select, Grid } from "@mui/material";
import { CSS } from '@dnd-kit/utilities';

const DragHandle = (props) => (
  <Box {...props.listeners} {...props.attributes} sx={{ cursor: 'grab' }}>
    <MdReorder />
  </Box>
);

const SuggestedSearchSortableItem = ({ data, data_options, handleChange, handleDelete, el_index }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: data.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    alignItems: "center",
    padding: "10px 0",
  };

  const renderObjectOptions = () => {
    const objects = ["AUTHOR", "SESSION", "SERIES", "AUDIOBOOK", "CHALLENGE", "QUICK_TAP_SESSION", "CARD_DECK"];
    return objects.map((el) => (
      <MenuItem key={el} value={el}>
        {el}
      </MenuItem>
    ));
  };

  const renderSearchOptions = () => {
    const searchOptions = ["REDIRECT", "SEARCH"];
    return searchOptions.map((el) => (
      <MenuItem key={el} value={el}>
        {el}
      </MenuItem>
    ));
  };

  const renderDataOption = () => {
    if (!data_options) return [];
    switch (data.object_type) {
      case "AUTHOR":
        return data_options.authors?.map((el) => (
          <MenuItem key={el.author_id} value={el.author_id}>
            {el.author_name}
          </MenuItem>
        ));
      case "SESSION":
        return data_options.sessions?.map((el) => (
          <MenuItem key={el.session_id} value={el.session_id}>
            {el.session_name}
          </MenuItem>
        ));
      case "SERIES":
        return data_options.series?.map((el) => (
          <MenuItem key={el.series_id} value={el.series_id}>
            {el.series_title}
          </MenuItem>
        ));
      case "AUDIOBOOK":
        return data_options.audiobook?.map((el) => (
          <MenuItem key={el.subcategory_id} value={el.subcategory_id}>
            {el.subcategory_title}
          </MenuItem>
        ));
      case "CHALLENGE":
        return data_options.challenges?.map((el) => (
          <MenuItem key={el.challenge_id} value={el.challenge_id}>
            {el.challenge_title}
          </MenuItem>
        ));
      case "QUICK_TAP_SESSION":
        return data_options.quick_taps?.map((el) => (
          <MenuItem key={el.quick_tap_id} value={el.quick_tap_id}>
            {el.quick_tap_title}
          </MenuItem>
        ));
      case "CARD_DECK":
        return data_options.decks?.map((el) => (
          <MenuItem key={el.id} value={el.id}>
            {el.name}
          </MenuItem>
        ));
      default:
        return [];
    }
  };

  return (
    <Grid
      ref={setNodeRef}
      container
      spacing={2}
      alignItems="center"
      sx={style}
    >
      {/* Drag Handle */}
      <Grid item xs={1}>
        <DragHandle listeners={listeners} attributes={attributes} />
      </Grid>

      {/* Object Type Select */}
      <Grid item xs={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Object Type</InputLabel>
          <Select
            value={data.object_type || ""}
            onChange={(e) => handleChange({ value: e.target.value, prop: "object_type" }, el_index)}
            label="Object Type"
          >
            {renderObjectOptions()}
          </Select>
        </FormControl>
      </Grid>

      {/* Object Name/Title Select */}
      <Grid item xs={4}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Object Name/Title</InputLabel>
          <Select
            value={data.object_id || ""}
            onChange={(e) => handleChange({ value: e.target.value, prop: "object_id" }, el_index)}
            label="Object Name/Title"
          >
            {renderDataOption()}
          </Select>
        </FormControl>
      </Grid>

      {/* Search Type Select */}
      <Grid item xs={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Search Type</InputLabel>
          <Select
            value={data.search_behavior || ""}
            onChange={(e) => handleChange({ value: e.target.value, prop: "search_behavior" }, el_index)}
            label="Search Type"
          >
            {renderSearchOptions()}
          </Select>
        </FormControl>
      </Grid>

      {/* Delete Button */}
      <Grid item xs={1}>
        <IconButton color="error" onClick={() => handleDelete(data.id, el_index)}>
          <MdDelete />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default SuggestedSearchSortableItem;
