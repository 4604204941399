import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HeaderBar from "../HeaderBar/HeaderBar";
import MainContentContainer from "../MainContentContainer/MainContentContainer";
import {
  fetchPromoData,
  fetchGeneralInfo,
  updatePromoData,
  updateGeneralInfo,
} from "../../actions/promoDataActions";
import { uploadImage, clearMedia } from "./../../actions/mediaActions";

class PromoData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itunes_promo_end_date: "",
      android_promo_end_date: "",
      android_lifetime_original_price: "",
      android_lifetime_IAP_start_date: "",
      promo_name: "",
      default_text_page_url: "",
      default_text_page_ios_url: "",
      default_text_image_url: "",
      default_text_image_url_preview: "",
      default_text_image_url_uploading: false,
      confetti_screen: "",
      lifetime_iap_discount: "",
    };
  }

  componentDidMount() {
    this.props.fetchPromoData();
    this.props.fetchGeneralInfo();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log("nextProps: ", nextProps);
    if (nextProps.promo_data) {
      this.setState({
        itunes_promo_end_date: nextProps.promo_data.itunes_promo_end_date,
        android_promo_end_date: nextProps.promo_data.android_promo_end_date,
        android_lifetime_original_price:
          nextProps.promo_data.android_lifetime_original_price,
        android_lifetime_IAP_start_date:
          nextProps.promo_data.android_lifetime_IAP_start_date,
        promo_name: nextProps.promo_data.promo_name,
        lifetime_iap_discount: nextProps.promo_data.lifetime_iap_discount,
        confetti_screen: nextProps.confetti_screen,

        // default_text_image_url: nextProps.promo_data.default_text_image_url,
        // default_text_page_url: nextProps.promo_data.default_text_page_url
      });
    }
    nextProps.default_text_image_url &&
      this.setState({
        default_text_image_url: nextProps.default_text_image_url,
      });
    nextProps.default_text_page_url &&
      this.setState({
        default_text_page_url: nextProps.default_text_page_url,
      });
    nextProps.default_text_page_ios_url &&
      this.setState({
        default_text_page_ios_url: nextProps.default_text_page_ios_url,
      });
    if (nextProps.image) {
      if (this.state.default_text_image_url_uploading) {
        this.setState({
          default_text_image_url: nextProps.image,
          default_text_image_url_uploading: false,
        });
        this.props.clearMedia();
      }
    }
  }

  componentWillUnmount = () => {
    if (this.state.default_text_image_url_preview) {
      window.URL.revokeObjectURL(this.state.default_text_image_url_preview);
    }
    this.props.clearMedia();
  };

  handleDeepLinkImageUpload = (e) => {
    const file = e.target.files[0];
    if (this.state.default_text_image_url_preview) {
      window.URL.revokeObjectURL(this.state.default_text_image_url_preview);
    }
    this.setState({
      default_text_image_url_preview: window.URL.createObjectURL(file),
      default_text_image_url_uploading: true,
    });
    this.props.uploadImage(file);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let {
      default_text_image_url,
      default_text_page_url,
      default_text_page_ios_url,
      ...data
    } = this.state;
    console.log("default_text_image_url: ", default_text_image_url);
    data = JSON.stringify(data);
    this.props.updatePromoData(data);
    this.props.updateGeneralInfo({
      default_text_image_url,
      default_text_page_url,
      default_text_page_ios_url,
    });
  };

  handleFieldChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const state = { ...prevState };
      state[name] = value;
      return state;
    });
  };

  render() {
    if (!this.props.promo_data) {
      return <h1>Loading...</h1>;
    }
    console.log("state: ", this.state);

    return (
      <div>
        <form
          className="AddEditQuote"
          onSubmit={this.handleSubmit}
          autoComplete="off"
        >
          <HeaderBar header="Update Subscription Promo Data">
            <button className="utility-btn-primary">Update</button>
          </HeaderBar>
          <MainContentContainer subContent={true}>
            <div className="promo-flex-row">
              <div className="promo-column">
                <div className="utility-flex-row">
                  <label className="utility-inline-label">
                    <p>Promo Name</p>
                    <input
                      className="utility-text-input"
                      name="promo_name"
                      value={this.state.promo_name}
                      style={{ width: "300px" }}
                      onChange={this.handleFieldChange}
                    />
                  </label>
                </div>
                <div className="utility-flex-row">
                  <label className="utility-inline-label">
                    <p>iOS Promo End Date</p>
                    <input
                      className="utility-text-input"
                      name="itunes_promo_end_date"
                      value={this.state.itunes_promo_end_date}
                      style={{ width: "300px" }}
                      onChange={this.handleFieldChange}
                    />
                  </label>
                </div>
                <div className="utility-flex-row">
                  <label className="utility-inline-label">
                    <p>Android Promo End Date</p>
                    <input
                      className="utility-text-input"
                      name="android_promo_end_date"
                      value={this.state.android_promo_end_date}
                      style={{ width: "300px" }}
                      onChange={this.handleFieldChange}
                    />
                  </label>
                </div>
                <div className="utility-flex-row">
                  <label className="utility-inline-label">
                    <p>Android Lifetime Original Price</p>
                    <input
                      className="utility-text-input"
                      name="android_lifetime_original_price"
                      value={this.state.android_lifetime_original_price}
                      style={{ width: "300px" }}
                      onChange={this.handleFieldChange}
                    />
                  </label>
                </div>
                <div className="utility-flex-row">
                  <label className="utility-inline-label">
                    <p>Android Lifetime IAP Start Data</p>
                    <input
                      className="utility-text-input"
                      name="android_lifetime_IAP_start_date"
                      value={this.state.android_lifetime_IAP_start_date}
                      style={{ width: "300px" }}
                      onChange={this.handleFieldChange}
                    />
                  </label>
                </div>
              </div>
              <div className="promo-column">
                <div className="utility-flex-row">
                  <label className="utility-inline-label">
                    <p>Default Deep Link URL (Android)</p>
                    <input
                      className="utility-text-input"
                      name="default_text_page_url"
                      value={this.state.default_text_page_url}
                      style={{ width: "300px" }}
                      onChange={this.handleFieldChange}
                    />
                  </label>
                </div>
                <div className="utility-flex-row">
                  <label className="utility-inline-label">
                    <p>Default Deep Link URL (iOS)</p>
                    <input
                      className="utility-text-input"
                      name="default_text_page_ios_url"
                      value={this.state.default_text_page_ios_url}
                      style={{ width: "300px" }}
                      onChange={this.handleFieldChange}
                    />
                  </label>
                </div>
                <div className="utility-flex-row">
                  <label className="utility-inline-label">
                    <p>Confetti Screen Link</p>
                    <input
                      className="utility-text-input"
                      name="confetti_screen"
                      value={this.state.confetti_screen}
                      style={{ width: "300px" }}
                      onChange={this.handleFieldChange}
                    />
                  </label>
                </div>
                <div className="utility-flex-row">
                  <label className="utility-inline-label">
                    <p>Lifetime IAP Discount</p>
                    <input
                      className="utility-text-input"
                      name="lifetime_iap_discount"
                      value={this.state.lifetime_iap_discount}
                      style={{ width: "300px" }}
                      onChange={this.handleFieldChange}
                    />
                  </label>
                </div>
                <div className="utility-flex-row" />
                <div className="utility-inline-label">
                  <p>Default Deep Link Image</p>
                  <div className="utility-flex-row">
                    {(this.state.default_text_image_url_preview ||
                      this.state.default_text_image_url) && (
                        <img
                          src={
                            this.state.default_text_image_url_preview ||
                            this.state.default_text_image_url
                          }
                          alt="Profile"
                          style={{
                            maxHeight: "124px",
                            maxWidth: "150px",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    <label>
                      <input
                        type="file"
                        onChange={this.handleDeepLinkImageUpload}
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <p
                        className="utility-btn-primary"
                        style={{ color: "#fff" }}
                        tabIndex="0"
                        onKeyPress={this.clickParent}
                      >
                        Upload Image
                      </p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </MainContentContainer>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("promo state: ", state);
  return {
    promo_data: state.promo_data.promo_data,
    default_text_image_url:
      state.general_data.general_data &&
      state.general_data.general_data.default_text_image_url,
    default_text_page_url:
      state.general_data.general_data &&
      state.general_data.general_data.default_text_page_url,
    default_text_page_ios_url:
      state.general_data.general_data &&
      state.general_data.general_data.default_text_page_ios_url,
    image: state.media.image,
  };
}

const mapDispatchToProps = {
  fetchPromoData,
  updatePromoData,
  uploadImage,
  clearMedia,
  fetchGeneralInfo,
  updateGeneralInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoData);
